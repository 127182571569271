import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getGmb = createAsyncThunk(
  "reputation/gmb",
  async (data, { rejectWithValue }) => {
    const { company_id, location_id, code } = data;
    try {
      const response = await getApi(
        `gmb/oauth?company_id=${company_id}&location_id=${location_id}&code=${code}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGmbSlice = createSlice({
  name: "getGmb",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGmb.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGmb.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getGmb.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getGmbSlice.reducer;
