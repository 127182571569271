import datePicker from "assets/images/datePicker.svg";
import deleteIcon from "assets/images/deleteIcon.svg";
import plusIcon from "assets/images/plusIcon.svg";
import classNames from "classnames";
import DirectMailStep2 from "components/campaigns/directMail/DirectMailStep2";
import CustomCostInput from "components/common/fields/CustomCostInput";
import SpinnerButton from "components/common/fields/SpinnerButton";
import CustomerFetchingLoader from "components/leadList/CustomerFetchingLoader";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import {
  convertDate,
  getCampaignImage,
  getCompanyStatus,
  getCurrentLocation,
  getDateFormatLocal,
  getUserDataByParam,
  isValidArray,
  isValidObject,
} from "module/util";
import {
  directMailForm1,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addCampaign } from "redux/campaign/addCampaignSlice";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { removeCustomerFromLead } from "redux/campaign/removeCustomerFromLeadSlice";
import { updateCampaignDetail } from "redux/campaign/updateCampaignDetailSlice";
import { getLeadLists } from "redux/leadList/getLeadListsSlice";

const DirectMailStep1 = (props) => {
  const { setSearchParams, setSelectStep, type, selectedStep } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();
  const initFormValue = formRef?.current?.values;
  const defaultDateFormat = getDateFormatLocal();
  const isCompanyActive = getCompanyStatus() === "active";

  const [initForm, setInitForm] = useState({});

  const isEdit = !!id;
  const { id: currentLocationId, location: currentLocationName } =
    getCurrentLocation();

  const [
    {
      leadListData = [],
      addCampaignLoading = false,
      updateCampaignDetailLoading = false,
      getCampaignDetailData = {},
      removeCustomerFromLeadData = {},
      validationMsgObj = {},
    },
  ] = useSelector((state) => [
    {
      leadListData: state?.getLeadLists?.data,
      addCampaignLoading: state?.addCampaign?.loading,
      updateCampaignDetailLoading: state?.updateCampaignDetail?.loading,
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
      removeCustomerFromLeadData:
        state?.removeCustomerFromLead?.data?.dataObj ?? {},
      validationMsgObj: state?.getMessages?.data ?? {},
    },
  ]);

  const initOffers = isEdit
    ? getCampaignDetailData?.offers
    : [{ offer_name: "", discount_cost: "" }];

  const isSendToCustomer =
    getCampaignDetailData?.send_to &&
    getCampaignDetailData?.send_to === "customers"
      ? true
      : false;

  const handleCountsOnLeadSelect = async (value, setFieldValue) => {
    let dataObj = {};

    await dispatch(removeCustomerFromLead(value)).then((data) => {
      setFieldValue("list_id", value?.lead_list_id);
      setFieldValue("sendTo", value?.send_to);

      setFieldValue("removeCustomer", false);
      if (data?.payload?.dataObj) {
        dataObj = data?.payload?.dataObj;

        setFieldValue("leadCount", dataObj?.potential_leads ?? 0);
        setFieldValue("customerCount", dataObj?.customer_count ?? 0);
        setFieldValue("totalCount", dataObj?.list_size ?? 0);
      }
    });
    return dataObj;
  };

  const initialValues = {
    name: isEdit ? getCampaignDetailData?.name : initFormValue?.name,
    isEdit: isEdit,
    campaign_cost: isEdit
      ? getCampaignDetailData?.campaign_cost
      : initFormValue?.campaign_cost,
    list_id:
      isEdit && !isSendToCustomer
        ? getCampaignDetailData?.list_id
        : initFormValue?.list_id,
    sendTo: isEdit
      ? getCampaignDetailData?.send_to ?? "leads"
      : initFormValue?.sendTo ?? "leads",
    start_date: isEdit
      ? convertDate(getCampaignDetailData?.start_date)
      : new Date(),
    end_date: isEdit
      ? convertDate(getCampaignDetailData?.end_date)
      : initFormValue?.end_date ?? new Date(),
    expires: isEdit
      ? getCampaignDetailData?.expires
      : initFormValue?.expires ?? "yes",
    crossAccept: isEdit
      ? getCampaignDetailData?.cross_accept
      : initFormValue?.crossAccept ?? "no",
    mailer_upload: isEdit ? "" : initFormValue?.mailer_upload,
    offers: initOffers,
    status: isEdit ? getCampaignDetailData?.status : "",
    location: currentLocationName,
    removeCustomer: isEdit
      ? getCampaignDetailData?.remove_customer === "yes"
        ? true
        : false
      : false,
    leadCount:
      isEdit && !isSendToCustomer
        ? removeCustomerFromLeadData?.potential_leads ?? 0
        : 0,
    customerCount: isEdit ? removeCustomerFromLeadData?.customer_count ?? 0 : 0,
    totalCount:
      isEdit && !isSendToCustomer
        ? removeCustomerFromLeadData?.list_size ?? 0
        : 0,
    type: isEdit
      ? getCampaignDetailData?.direct_mail_type
      : initFormValue?.type,
  };

  const handleSubmit = (value) => {
    const { id: currentLocationId } = getCurrentLocation();
    const currentCompanyId = getUserDataByParam("COMPANY_ID");
    const expiration = value?.expires === "yes" ? true : false;

    const formData = {
      name: value?.name ?? "",
      campaign_cost: value?.campaign_cost ?? "",
      list_id: value?.list_id ?? "",
      send_to: value?.sendTo ?? "",
      start_date: value?.start_date ?? "",
      end_date: expiration ? value?.end_date : "" ?? "",
      expires: value?.expires ?? "",
      cross_accept: value?.crossAccept ?? "",
      mailer_upload: value?.mailer_upload,
      offers: value?.offers,
      remove_customer: value?.removeCustomer ? "yes" : "no",
      type: "direct_mail",
      user_id: currentCompanyId,
      location_id: currentLocationId,
      direct_mail_type: value?.type ?? "",
    };

    if (isEdit) {
      dispatch(updateCampaignDetail({ id: id, formData: formData })).then(
        (data) => {
          if (data?.payload?.code == 200) {
            setSelectStep(2);
            navigate(
              `/campaigns/edit/${data?.payload?.dataObj ?? 0}/${type}?step=2`
            );
          } else {
            handleServerValidation(data);
          }
        }
      );
    } else {
      dispatch(addCampaign({ formData: formData })).then((data) => {
        if (data?.payload.code === 200) {
          setInitForm({});
          setSelectStep(2);
          navigate(`/campaigns/edit/${data?.payload?.dataObj}/${type}?step=2`);
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  const sendToArray = [
    { name: "Leads", value: "leads" },
    { name: "Customers", value: "customers" },
    { name: "Both", value: "both" },
  ];
  const typeArray = [
    { name: "Plastic Gift Card", value: "plastic_gift_card" },
    { name: "Paper Mailer", value: "paper_mailer" },
    { name: "Door Hanger", value: "door_hanger" },
  ];

  useEffect(() => {
    if (isEdit) {
      dispatch(getCampaignDetail(id)).then((data) => {
        if (isValidObject(data?.payload)) {
          const formData = {
            remove_customer: data?.payload?.remove_customer,
            location_id: currentLocationId,
            ...(data?.payload?.send_to !== "customer" && {
              lead_list_id: data?.payload?.list_id,
            }),
            send_to: data?.payload?.send_to,
          };
          dispatch(removeCustomerFromLead(formData));
        }
      });
    } else {
      setInitForm(initialValues);
    }
  }, []);

  useEffect(() => {
    setInitForm(initialValues);
    dispatch(getLeadLists({ location_id: currentLocationId }));
  }, [currentLocationId]);

  return (
    <>
      {selectedStep == 1 && (
        <>
          <div className="flex justify-center gap-x-6">
            <Formik
              initialValues={!isEdit ? initForm : initialValues}
              validationSchema={() =>
                directMailForm1(validationMsgObj?.campaign ?? {})
              }
              onSubmit={handleSubmit}
              enableReinitialize={true}
              innerRef={formRef}
            >
              {({
                errors,
                touched,
                validateField,
                validateForm,
                handleChange,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => {
                return (
                  <form
                    className="w-full min-w-xl"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="w-full border border-gray-200 p-3 bg-white mt-4">
                      <div className="flex  flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3">
                          <label htmlFor="name" className="input-field-label">
                            Campaign Name
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              value={values?.name ?? ""}
                              onChange={handleChange}
                              placeholder="Enter Name"
                              className="input-field"
                            />
                          </div>
                          {touched?.name && showError(errors?.name)}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            htmlFor="campaign_cost"
                            className="input-field-label"
                          >
                            Ad Cost
                          </label>
                          <CustomCostInput
                            values={values?.campaign_cost ?? ""}
                            setFieldValue={setFieldValue}
                            name={"campaign_cost"}
                            title="Ad Cost"
                            onBlur={handleBlur}
                          />
                          {touched?.campaign_cost &&
                            showError(errors?.campaign_cost)}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/4 px-3">
                          <label
                            htmlFor="expires"
                            className="input-field-label"
                          >
                            Code Expires ?
                          </label>
                          <div className="mt-2 flex items-center">
                            <label>
                              <Field
                                type="radio"
                                name="expires"
                                className="remove-focus-ring"
                                value="yes"
                                onChange={(e) =>
                                  setFieldValue("expires", e.target.value)
                                }
                              />{" "}
                              Yes
                            </label>
                            <label className="ml-2">
                              <Field
                                type="radio"
                                name="expires"
                                className="remove-focus-ring"
                                value="no"
                                onChange={(e) =>
                                  setFieldValue("expires", e.target.value)
                                }
                              />{" "}
                              No
                            </label>
                          </div>
                          {touched?.expires && showError(errors?.expires)}
                        </div>
                        <div className="w-full md:w-1/4 px-3">
                          <label
                            htmlFor="crossAccept"
                            className="input-field-label"
                          >
                            Accept in other locations ?
                          </label>
                          <div className="mt-2 flex items-center">
                            <label>
                              <Field
                                type="radio"
                                name="crossAccept"
                                value="yes"
                                className="remove-focus-ring"
                                onChange={(e) =>
                                  setFieldValue("crossAccept", e.target.value)
                                }
                              />{" "}
                              Yes
                            </label>
                            <label className="ml-2">
                              <Field
                                type="radio"
                                name="crossAccept"
                                value="no"
                                className="remove-focus-ring"
                                onChange={(e) =>
                                  setFieldValue("crossAccept", e.target.value)
                                }
                              />{" "}
                              No
                            </label>
                          </div>
                          {touched?.crossAccept &&
                            showError(errors?.crossAccept)}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            htmlFor="start_date"
                            className="input-field-label"
                          >
                            Target Start date
                          </label>
                          <div className="mt-2">
                            <div className="relative w-full">
                              <ReactDatePicker
                                className="input-field"
                                placeholderText="Select Start Date"
                                selected={values?.start_date ?? ""}
                                dateFormat={defaultDateFormat}
                                onChange={(date) => {
                                  setFieldValue("start_date", date);
                                }}
                                minDate={new Date()}
                              />
                              <img
                                src={datePicker}
                                className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                                alt="Date picker"
                              />
                            </div>
                          </div>
                          {touched?.start_date && showError(errors?.start_date)}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            htmlFor="location"
                            className="input-field-label"
                          >
                            Location
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="location"
                              id="location"
                              value={values?.location ?? ""}
                              onChange={handleChange}
                              autoComplete="location"
                              placeholder="Enter Location"
                              readOnly={true}
                              disabled={true}
                              className="input-field"
                            />
                          </div>
                          {touched?.location && showError(errors?.location)}
                        </div>
                        {values?.expires === "yes" && (
                          <div className="w-full md:w-1/2 px-3">
                            <label
                              htmlFor="end_date"
                              className="input-field-label"
                            >
                              End Date
                            </label>
                            <div className="mt-2">
                              <div className="relative w-full">
                                <ReactDatePicker
                                  className="input-field"
                                  selected={values?.end_date ?? ""}
                                  dateFormat={defaultDateFormat}
                                  onChange={(date) => {
                                    setFieldValue("end_date", date);
                                  }}
                                  minDate={new Date()}
                                  placeholderText="Select End Date"
                                />
                                <img
                                  src={datePicker}
                                  className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                                  alt="Date picker"
                                />
                              </div>
                            </div>
                            {touched?.end_date && showError(errors?.end_date)}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                          <label htmlFor="sendTo" className="input-field-label">
                            Send To
                          </label>
                          <div className="mt-2">
                            <Field
                              as="select"
                              className="input-field"
                              name="sendTo"
                              value={values?.sendTo ?? "leads"}
                              onChange={async (e) => {
                                if (e.target.value === "customers") {
                                  const formData = {
                                    remove_customer: "no",
                                    location_id: currentLocationId,
                                    send_to: e.target.value,
                                  };

                                  await handleCountsOnLeadSelect(
                                    formData,
                                    setFieldValue
                                  );
                                } else {
                                  handleChange(e);
                                  setFieldValue("list_id", "");
                                  setFieldValue("leadCount", 0);
                                  setFieldValue("customerCount", 0);
                                  setFieldValue("totalCount", 0);
                                  setFieldValue("removeCustomer", false);
                                }
                              }}
                            >
                              {isValidArray(sendToArray) &&
                                sendToArray?.map((item) => (
                                  <option
                                    key={item?.value}
                                    value={item?.value ?? ""}
                                    defaultValue={values?.value ?? ""}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                            </Field>
                            {values?.sendTo === "customers" && (
                              <>
                                <span className="font-light text-sm">
                                  Customers:{"  "}
                                </span>
                                <span className="font-semibold text-sm">
                                  {values?.customerCount ?? 0}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        {values?.sendTo !== "customers" && (
                          <div
                            className={classNames("w-full md:w-1/3 px-3", {
                              "opacity-50": values?.sendTo === "customers",
                            })}
                          >
                            <label
                              htmlFor="list_id"
                              className="input-field-label"
                            >
                              List
                            </label>
                            <div className="mt-2">
                              <Field
                                as="select"
                                className="input-field"
                                name="list_id"
                                disabled={values?.sendTo === "customers"}
                                onChange={async (e) => {
                                  const formData = {
                                    remove_customer: "no",
                                    location_id: currentLocationId,
                                    lead_list_id: e.target.value,
                                    send_to: values?.sendTo,
                                  };

                                  await handleCountsOnLeadSelect(
                                    formData,
                                    setFieldValue
                                  );
                                }}
                              >
                                <option key={1} value={""}>
                                  Select Leads
                                </option>
                                {isValidArray(leadListData?.data) &&
                                  leadListData?.data?.map((item) => (
                                    <option
                                      key={item?.lead_list_id}
                                      value={item?.lead_list_id ?? ""}
                                      defaultValue={values?.list_id ?? ""}
                                    >
                                      {item?.name}
                                    </option>
                                  ))}
                              </Field>
                            </div>
                            <p>
                              <span className="font-light text-sm">
                                Leads:{"  "}
                              </span>
                              <span className="font-semibold text-sm">
                                {values?.leadCount ?? 0}
                                {"  "}
                              </span>
                              <span className="font-light text-sm">
                                Customers:{"  "}
                              </span>
                              <span className="font-semibold text-sm">
                                {values?.customerCount ?? 0}{" "}
                              </span>
                              <span className="font-light text-sm">
                                Total:{"  "}
                              </span>
                              <span className="font-semibold text-sm">
                                {values?.totalCount ?? 0}
                              </span>
                            </p>
                            {touched?.list_id && showError(errors?.list_id)}
                          </div>
                        )}

                        {values?.sendTo === "leads" && (
                          <div className="w-full md:w-1/6 px-3">
                            <label
                              htmlFor="removeCustomer"
                              className="input-field-label"
                            >
                              Remove Customers ?
                            </label>
                            <label className="form-check-label my-1 hover:cursor-pointer">
                              <RemoveCustomerCheckBox
                                values={values}
                                setFieldValue={setFieldValue}
                                locationId={currentLocationId}
                              />
                            </label>
                          </div>
                        )}
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            htmlFor="mailer_upload"
                            className="input-field-label"
                          >
                            Mailer Upload
                          </label>
                          <div className="mt-2">
                            <input
                              type="file"
                              name="mailer_upload"
                              id="mailer_upload"
                              accept=".png, .jpg, .jpeg"
                              value={values?.mailer_upload?.file?.name}
                              onChange={(e) => {
                                let filesData = e.target.files[0];
                                setFieldValue("mailer_upload", filesData);
                              }}
                              onBlur={handleBlur}
                              autoComplete="mailer_upload"
                              placeholder="Enter mailer_upload"
                              className="relative m-0 block w-full min-w-0 flex-auto border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                            />
                            <span className="text-sm font-light mr-2">
                              (Upload: JPEG or PNG, 1000x1000, max 500k)
                            </span>
                            {touched?.mailer_upload &&
                              showError(errors?.mailer_upload)}
                            {!!values?.mailer_upload ||
                            (isValidObject(getCampaignDetailData) &&
                              getCampaignDetailData?.mailer_upload !== "" &&
                              isEdit) ? (
                              <img
                                alt="thumbnail"
                                src={
                                  typeof values?.mailer_upload === "string"
                                    ? getCampaignImage(
                                        values?.mailer_upload
                                          ? values?.mailer_upload
                                          : getCampaignDetailData?.mailer_upload
                                      )
                                    : URL.createObjectURL(values?.mailer_upload)
                                }
                                className="w-[150px] h-[100px] mt-2"
                              />
                            ) : (
                              <div className="w-[150px] h-[100px] bg-gray-200 border-2 border-gray-300 mt-2"></div>
                            )}
                          </div>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label htmlFor="type" className="input-field-label">
                            Type <span className="text-sm">(Recommended)</span>
                          </label>
                          <div className="mt-2">
                            <Field
                              as="select"
                              className="input-field"
                              name="type"
                              value={values?.type ?? ""}
                              onChange={(e) => {
                                setFieldValue("type", e.target.value);
                              }}
                            >
                              <option key={""} value={""}>
                                Please select type
                              </option>
                              {isValidArray(typeArray) &&
                                typeArray?.map((item) => (
                                  <option
                                    key={item?.value}
                                    value={item?.value ?? ""}
                                    defaultValue={values?.value ?? ""}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border border-gray-200 p-3 bg-white mt-4">
                      <FieldArray name="offers" value={values?.offers}>
                        {({ insert, remove, push }) => (
                          <div className="flex w-full  flex-wrap -mx-3 mb-6">
                            {values?.offers?.length > 0 &&
                              values.offers.map((offer, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex w-full  flex-wrap mb-3 items-center"
                                  >
                                    <div className="w-full md:w-1/2 px-3">
                                      <label
                                        htmlFor={`offers.${index}.offer_name`}
                                        className="input-field-label"
                                      >
                                        <span>Offer Name</span>
                                      </label>
                                      <div className="mt-2">
                                        <Field
                                          type="text"
                                          value={offer["offer_name"]}
                                          name={`offers.${index}.offer_name`}
                                          id={`offers.${index}.offer_name`}
                                          placeholder="Enter Offer Name"
                                          className="input-field"
                                        />
                                      </div>
                                      <ErrorMessage
                                        name={`offers.${index}.offer_name`}
                                        component="div"
                                        className="field-error text-red-500"
                                      />
                                    </div>
                                    <div className="w-full md:w-1/3 px-3">
                                      <label
                                        htmlFor={`offers.${index}.discount_cost`}
                                        className="input-field-label"
                                      >
                                        Offer Cost
                                      </label>
                                      <CustomCostInput
                                        values={offer["discount_cost"]}
                                        setFieldValue={setFieldValue}
                                        name={`offers.${index}.discount_cost`}
                                        title="Offer Cost"
                                        onBlur={handleBlur}
                                      />
                                      <ErrorMessage
                                        name={`offers.${index}.discount_cost`}
                                        component="div"
                                        className="field-error text-red-500"
                                      />
                                    </div>
                                    {values.offers.length < 2 && (
                                      <button
                                        type="button"
                                        className="text-md font-light flex gap-x-2 items-center ml-3 mt-6"
                                        onClick={() =>
                                          push({
                                            offer_name: "",
                                            discount_cost: "",
                                          })
                                        }
                                      >
                                        <img
                                          alt="Add offer"
                                          src={plusIcon}
                                          className="w-5 h-5"
                                        />
                                        Add Offer
                                      </button>
                                    )}
                                    {index > 0 && (
                                      <button
                                        type="button"
                                        className="secondary float-right flex items-center gap-x-2 text-md font-light mt-6"
                                        onClick={() => remove(index)}
                                      >
                                        <img
                                          alt="remove offer"
                                          src={deleteIcon}
                                          className="h-5 w-5"
                                        />
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <div className="flex flex-wrap justify-between mb-6 mt-4">
                      <button
                        onClick={() => {
                          navigate(`/campaigns/list?type=${type}`);
                        }}
                        className="btn btn-light-gray ml-2"
                      >
                        Cancel
                      </button>
                      {isCompanyActive && (
                        <SpinnerButton
                          className="btn-pink"
                          title={"Build"}
                          action={handleSubmit}
                          type={"submit"}
                          // loading={
                          //   addCampaignLoading || updateCampaignDetailLoading
                          // }
                        />
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </>
      )}

      {selectedStep == 2 && (
        <div className="w-full border border-gray-200 p-3 bg-white mt-4">
          <DirectMailStep2
            setSearchParams={setSearchParams}
            setSelectStep={setSelectStep}
            type={type}
            selectedStep={selectedStep}
          />
        </div>
      )}
      {(addCampaignLoading || updateCampaignDetailLoading) && (
        <CustomerFetchingLoader
          isModalOpen={true}
          message="Please wait a while, Processing....."
        />
      )}
    </>
  );
};

export default DirectMailStep1;

const RemoveCustomerCheckBox = ({ values, setFieldValue, locationId }) => {
  const dispatch = useDispatch();

  const handleRemoveCustomer = async (value) => {
    let dataObj = {};

    await dispatch(removeCustomerFromLead(value)).then((data) => {
      setFieldValue(
        "removeCustomer",
        value?.remove_customer === "yes" ? true : false
      );
      if (data?.payload?.dataObj) {
        dataObj = data?.payload?.dataObj;

        setFieldValue("leadCount", dataObj?.potential_leads ?? 0);
        setFieldValue("customerCount", dataObj?.customer_count ?? 0);
        setFieldValue("totalCount", dataObj?.list_size ?? 0);
      }
    });
    return dataObj;
  };

  return (
    <input
      name="removeCustomer"
      type="checkbox"
      disabled={!!!values?.list_id}
      checked={values?.removeCustomer ?? false}
      className={classNames("form-check-input remove-focus-ring", {
        "opacity-40 hover:cursor-default": !!!values?.list_id,
        "hover:cursor-pointer": !!values?.list_id,
      })}
      onChange={async (e) => {
        const formData = {
          remove_customer: e.target.checked ? "yes" : "no",
          location_id: locationId,
          lead_list_id: values?.list_id,
          send_to: values?.sendTo,
        };
        const dataObj = await handleRemoveCustomer(formData);
      }}
    />
  );
};
