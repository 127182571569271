import classNames from "classnames";
import DashboardMatrix from "components/dashboard/CompanyDashboard/DashboardMatrix";
import DashboardMatrixTop from "components/dashboard/CompanyDashboard/DashboardMatrixTop";
import GoogleMapMain from "components/dashboard/CompanyDashboard/GoogleMapMain";
import AgeGraph from "components/dashboard/CompanyDashboard/Graphs/AgeGraph";
import ChildrenPresentGraph from "components/dashboard/CompanyDashboard/Graphs/ChildrenPresentGraph";
import GenderGraph from "components/dashboard/CompanyDashboard/Graphs/GenderGraph";
import HomeTypeGraph from "components/dashboard/CompanyDashboard/Graphs/HomeTypeGraph";
import IncomeGraph from "components/dashboard/CompanyDashboard/Graphs/IncomeGraph";
import LengthInResidenceGraph from "components/dashboard/CompanyDashboard/Graphs/LengthInResidenceGraph";
import MaritalStatusGraph from "components/dashboard/CompanyDashboard/Graphs/MaritalStatusGraph";
import OwnerRenterGraph from "components/dashboard/CompanyDashboard/Graphs/OwnerRenterGraph";
import TopCampaigns from "components/dashboard/CompanyDashboard/TopCampaigns";
import TopReviewLinks from "components/dashboard/CompanyDashboard/TopReviewLinks";
import {
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
} from "module/util";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { dashboard } from "redux/dashboard/dashboardSlice";

const DashboardForCompany = () => {
  const { lat_long, id: currentLocationId } = getCurrentLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyId = getUserDataByParam("COMPANY_ID");
  const isCompanyActive = getCompanyStatus() === "active";

  const dashboardData = useSelector((state) => state?.dashboard?.data ?? []);
  const dashboardDataLoading = useSelector(
    (state) => state?.dashboard?.loading ?? false
  );
  const selectedLocation = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const demoGraphicData = dashboardData?.demographics ?? {};
  const topCampaigns = dashboardData?.campaignMetrics ?? [];
  const topReviewLinks = dashboardData?.reputation ?? [];

  const locationCounts = getUserDataByParam("LOCATION_COUNT") ?? "";

  useEffect(() => {
    dispatch(dashboard({ companyId, currentLocationId }));
  }, [selectedLocation]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([{ name: "Dashboard", href: "/dashboard", current: false }])
    );
  }, []);

  const totalCustomersCount = dashboardData?.customersCount ?? 0;

  return (
    <div className="relative bg-white">
      {dashboardData?.customersCount == 0 &&
        !!locationCounts &&
        isCompanyActive && (
          <button
            className="btn-pink fixed top-[50%] left-[50%] z-[99]"
            onClick={() => {
              navigate("/contacts/imports", { state: "/dashboard" });
            }}
          >
            Import customers
          </button>
        )}
      <div
        className={classNames("p-2 ", {
          "pointer-events-none blur-[2px] grayscale": totalCustomersCount == 0,
        })}
      >
        <div className="mb-2">
          <p className="text-lg font-semibold text-gray-700">Dashboard</p>
          <span className="text-sm ml-1 text-gray-700">
            Reporting based on Last 30-days
          </span>
        </div>
        <DashboardMatrixTop
          dashboardData={dashboardData}
          dashboardDataLoading={dashboardDataLoading}
        />
        <div className="mt-8 z-8 w-full min-w-xl">
          <div className="mt-2 mb-2">
            <p className="text-lg font-semibold text-gray-700">
              Core Customers Demographics
            </p>
            <span className="text-sm ml-1 text-gray-700">
              Based on - {demoGraphicData?.subText ?? 0.0}% Customer /
              Demographics Match
            </span>
          </div>
          <div className="flex  flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">Gender</span>
              </div>
              <GenderGraph demoGraphicData={demoGraphicData} />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">Age</span>
              </div>
              <AgeGraph demoGraphicData={demoGraphicData} />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">
                  Marital Status
                </span>
              </div>
              <MaritalStatusGraph demoGraphicData={demoGraphicData} />
            </div>
          </div>
          <div className="flex  flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">
                  Home Type
                </span>
              </div>
              <HomeTypeGraph demoGraphicData={demoGraphicData} />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">
                  Own Vs Rent
                </span>
              </div>
              <OwnerRenterGraph demoGraphicData={demoGraphicData} />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">
                  Children Present
                </span>
              </div>
              <ChildrenPresentGraph demoGraphicData={demoGraphicData} />
            </div>
          </div>
          <div className="flex  flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/2 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">Income</span>
              </div>
              <IncomeGraph demoGraphicData={demoGraphicData} />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <div className="graph-title-background-color">
                <span className="text-gray-600 font-medium ml-1">
                  Length In Residence
                </span>
              </div>
              <LengthInResidenceGraph demoGraphicData={demoGraphicData} />
            </div>
          </div>
        </div>

        <div className="mt-4 z-8">
          <GoogleMapMain
            lat_long={lat_long}
            currentLocationId={currentLocationId}
            dashboardDataLoading={dashboardDataLoading}
          />
        </div>
        <div className="mt-4 z-8">
          <div className="mt-2 mb-2">
            <p className="text-lg font-semibold text-gray-700">
              Campaign Metrics
            </p>
          </div>
          <DashboardMatrix
            dashboardData={dashboardData}
            dashboardDataLoading={dashboardDataLoading}
          />
        </div>
        <div className="mt-8 mb-4 z-8 flex">
          <div className="w-full md:w-1/2 pr-6">
            <TopCampaigns topCampaigns={topCampaigns} />
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <TopReviewLinks topReviewLinks={topReviewLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardForCompany;
