import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";

const GuestWifiLoader = (props) => {
  const { isModalOpen, message = "Please wait a while, Processing..." } = props;
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex flex-col min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <svg
              width="80"
              height="80"
              className="animate-spin"
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M88.402 77.088a8 8 0 0 1-11.314 11.314L54.46 65.775a8 8 0 0 1 11.314-11.314Zm-11.314 90.51L54.46 190.225a8 8 0 0 0 11.314 11.314l22.627-22.627a8 8 0 0 0-11.314-11.314ZM72 128a8 8 0 0 0-8-8H32a8 8 0 0 0 0 16h32a8 8 0 0 0 8-8Zm56-104a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0V32a8 8 0 0 0-8-8Zm96 96h-32a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm-45.088 47.598a8 8 0 0 0-11.314 11.314l22.627 22.627a8 8 0 1 0 11.314-11.314ZM128 184a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0v-32a8 8 0 0 0-8-8Z" />
            </svg>
            <p className="mt-6 font-bold text-gray-900">{message}</p>
            <p className="mt-2 font-bold text-red-900">Please Do Not Go Away</p>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GuestWifiLoader;
