import { PatternFormat } from "react-number-format";

const CustomPhoneInput = (props) => {
  const {
    values = {},
    setFieldValue = () => {},
    name = "phone",
    isDisabled = false,
    isReadOnly = false,
    onBlur = () => {},
  } = props;

  return (
    <div className="mt-2">
      <PatternFormat
        name={name}
        value={values?.phone ?? ""}
        onValueChange={(values) => {
          const finalPhone = values?.value && values?.formattedValue;
          // : `${values?.value}`;
          setFieldValue(name, finalPhone);
        }}
        format="+1 (%%%) %%%-%%%%"
        patternChar="%"
        placeholder="Enter Phone"
        className="input-field"
        disabled={isDisabled}
        readOnly={isReadOnly}
        onBlur={onBlur}
      />
    </div>
  );
};

export default CustomPhoneInput;
