import UserTable from "components/locations/UserTable";

const UserTabs = (props) => {
  const { locationId } = props;

  return (
    <div>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <li className="mr-2">
          <span
            onClick={() => {}}
            aria-current="page"
            className="inline-block p-4 hover:cursor-pointer text-white-600 bg-gray-100 rounded-t-lg active bg-gray-600 text-white"
          >
            Users
          </span>
        </li>
      </ul>

      <div className="mb-6">
        <div
          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-users"
          role="tabpanel"
          aria-labelledby="tabs-users-tab"
          data-te-tab-active
        >
          <UserTable locationId={locationId} />
        </div>
      </div>
    </div>
  );
};

export default UserTabs;
