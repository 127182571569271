import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const addCustomerToLocation = createAsyncThunk(
  "customer/add-to-location",
  async (formData, { rejectWithValue }) => {
    const { customerId, locationId } = formData;
    try {
      const response = await postApi(
        `api/customers/${customerId}/${locationId}`,
        {}
      );
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addCustomerToLocationSlice = createSlice({
  name: "addCustomerToLocation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCustomerToLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCustomerToLocation.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(addCustomerToLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default addCustomerToLocationSlice.reducer;
