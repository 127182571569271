import classNames from "classnames";
import Skeleton from "components/common/fields/Skeleton";
import RatingField from "components/contacts/customer/RatingField";
import CustomDropDown from "components/reputation/CustomDropDown";
import CustomReview from "components/reputation/CustomReview";
import ProgressBar from "components/reputation/ProgressComponent";
import Quarantine from "components/reputation/Quarantine";
import {
  createListOfObject,
  getAuthToken,
  getCompanyStatus,
  getCurrentLocation,
  getUserData,
  isValidObject,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";
import { getStarTemplate } from "redux/reputation/getStarTemlpateSlice";

export default function Review({ currentTab, setTab, subTab, setSubTab }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterDropDown, setFilterDropDown] = useState([
    { name: "Sort By Newest", id: "review_created_at" },
    { name: "Sort By Star", id: "star" },
  ]);
  const companyId = getUserData() ? JSON.parse(getUserData()) : "";
  const currentLocation = getCurrentLocation();
  const [selectLoader, setSelectLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    name: "Sort By Newest",
    id: "review_created_at",
  });

  let data = {
    location_id: currentLocation?.id,
    company_id: companyId?.company_id,
    access_token: getAuthToken(),
  };

  const [
    {
      getStarTemplateData = [],
      getStarTemplateLoading = false,
      checkExpireData = false,
      checkExpireDataLoading = false,
    },
  ] = useSelector((state) => [
    {
      getStarTemplateData: state?.getStarTemplate?.data ?? [],
      getStarTemplateLoading: state?.getStarTemplate?.loading ?? false,
      checkExpireData: state?.checkExpireToken?.data?.dataObj ?? false,
      checkExpireDataLoading: state?.checkExpireToken?.loading ?? false,
    },
  ]);

  // Company status
  const companyStatus = getCompanyStatus() ?? "";
  // stars object
  const starRating = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
  };
  // filter data according to Quarantine status is true
  const quarantineData = getStarTemplateData?.dataObj?.all?.filter(
    (quarantine, index) => {
      return quarantine?.is_quarantine === "true";
    }
  );
  const responseTabsArray = [
    {
      name: "Needs Response",
      value: "need_response",
      href: "#",
      current: false,
      total: getStarTemplateData?.dataObj?.not_replied?.length,
    },
    {
      name: "All",
      value: "all",
      href: "#",
      current: false,
      total: getStarTemplateData?.dataObj?.all?.length,
    },
    {
      name: "Quarantine",
      value: "quarantine",
      href: "#",
      current: false,
      total: quarantineData?.length,
    },
  ];

  const reviewTemplate = {
    getStarTemplateData: getStarTemplateData?.dataObj,
    starRating: starRating,
    getStarTemplateLoading: getStarTemplateLoading,
    getMoreLoading: loading,
  };

  // sorting according to star 5-4-3-2-1
  let progressData = createListOfObject(
    getStarTemplateData?.dataObj?.all_star
  ).sort((a, b) => starRating[b?.id] - starRating[a?.id]);

  const sortData = {
    ...data,
    sortBy: selectedLocation?.id,
  };

  const getReviewsList = () => {
    dispatch(getStarTemplate(sortData)).then((data) => {
      if (data?.payload?.code === 200) {
        setSelectLoader(false);
      }
    });
  };

  useEffect(() => {
    dispatch(checkExpireToken(data));
    getReviewsList();
  }, [selectedLocation, currentLocation?.id]);

  return (
    <div className="relative">
      {companyStatus === "active" &&
        isValidObject(checkExpireData) &&
        !checkExpireData?.status &&
        !checkExpireDataLoading && (
          <button
            className="btn-pink fixed top-[50%] left-[50%] z-[99]"
            onClick={() => {
              navigate(
                "/user-settings?tab=integrations&setting=google&redirect=false"
              );
            }}
          >
            Connect Google
          </button>
        )}

      <div
        className={`${
          companyStatus === "Pending"
            ? "pointer-events-none"
            : !checkExpireData?.status &&
              "pointer-events-none blur-[4px] grayscale"
        }`}
      >
        {/* <div className="flex  justify-end items-center mt-2 gap-4">
        <SpinnerButton
          className={`btn-pink ${loading && "w-[10%]"}`}
          title={`Get More Reviews`}
          action={() => {
            setLoading(true);
            dispatch(getReviewTemplate(data)).then((res) => {
              setLoading(false);
            });
          }}
          type={"button"}
          loading={loading && getReviewTemplateLoading}
        />
        <button
          type="button"
          className="btn-pink"
          onClick={() => {
            navigate("/reputation?tab=get_review");
            setTab("get_review");
          }}
        >
          Share Google Link
        </button>
      </div> */}
        {/* OverAll Rating div */}
        <div
          className={
            !checkExpireData?.status && `brightness-90 blur-[3px]  grayscale`
          }
        >
          {(!getStarTemplateLoading && !loading) || selectLoader ? (
            <div
              className={`${
                (!getStarTemplateLoading && !loading) || selectLoader
                  ? "border-2"
                  : "border-0"
              } border-gray-400 mt-5 flex justify-start sm:gap-0 w-full md:w-[70%] xl:w-1/2  bg-white p-1 h-[215px]`}
            >
              <div className="flex-column justify-center  items-center pl-2 sm:pl-8 pt-2 w-[40%] ml-2 ">
                <p className="font-bold  font-md pl-1">Overall Rating</p>
                <p className="font-bold text-[4.5rem]  px-2 h-[90px]">
                  {getStarTemplateData?.dataObj?.average_rating ?? "0.0"}
                </p>
                <p className="text-sm pl-1">
                  {" "}
                  {`Based on ${
                    getStarTemplateData?.dataObj?.all?.length ?? ""
                  } reviews`}{" "}
                </p>
                {/* Generate stars  */}
                <span className="my-1 flex list-none ml-[-15px]">
                  <RatingField
                    rating={getStarTemplateData?.dataObj?.average_rating ?? 0}
                    size={2}
                  />
                </span>
              </div>
              {/* Progress bar  */}
              <div className="w-full flex flex-col justify-center">
                {progressData?.map((progressItem, index) => (
                  <div
                    key={index}
                    className={`flex items-center justify-start my-1`}
                  >
                    <div className="text-gray-400 text-sm  sm:w-[15%] flex justify-end items-center mr-2 mb-1 ">
                      {starRating[progressItem?.id]} Star
                    </div>
                    <ProgressBar stars={progressItem?.name} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="w-full md:w-[70%] xl:w-1/2 mt-5">
                <Skeleton height={15} className={`mb-0`} type="star" />
              </div>
            </div>
          )}

          {/* sub tabs  */}
          <div className="flex border-b border-collapse border-gray-300 mt-3">
            <div className=" flex-1  w-full">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {responseTabsArray?.map((tab) => (
                  <span
                    key={tab.name}
                    onClick={() => {
                      setSubTab(tab.value);
                      navigate(
                        `/reputation?tab=${currentTab}&sub_tab=${tab?.value}`
                      );
                    }}
                    className={classNames(
                      " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                      {
                        "border-pink-600 hover:border-pink-600 text-gray-800":
                          tab.value === subTab,
                        "border-transparent": tab.value !== subTab,
                      }
                    )}
                  >
                    {tab.name} {`(${tab?.total ?? 0})`}
                  </span>
                ))}

                <div className=" w-48 mb-2 sm:mb-0 ">
                  <CustomDropDown
                    filterDropDown={filterDropDown}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    setSelectLoader={setSelectLoader}
                  />
                </div>
              </nav>
            </div>
          </div>
          {/* response tab component */}
          {subTab === "need_response" && (
            <CustomReview
              getReviewsList={getReviewsList}
              type={"not_replied"}
              reviewTemplate={reviewTemplate}
              setSubTab={setSubTab}
            />
          )}
          {subTab === "all" && (
            <CustomReview
              getReviewsList={getReviewsList}
              type={"all"}
              reviewTemplate={reviewTemplate}
              setSubTab={setSubTab}
            />
          )}
          {subTab === "quarantine" && (
            <Quarantine
              getReviewsList={getReviewsList}
              reviewTemplate={reviewTemplate}
              quarantineData={quarantineData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
