import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "components/common/fields/SpinnerButton";
import TagsSelect from "components/contacts/tags/TagsSelect";
import { isValidArray } from "module/util";
import { Fragment, useEffect, useRef, useState } from "react";

const TagActionModal = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    currentLocationId = "",
    currentCompanyId = "",
    handleAction = () => {},
    tagsOptions = [],
    setTags = () => {},
    tags = [],
  } = props;

  const cancelButtonRef = useRef(null);

  const [addNewTags, setAddNewTags] = useState({ isAdding: false, tag: "" });

  useEffect(() => {
    setTags([]);
  }, []);

  const handleSubmit = () => {
    handleAction();
    setModalOpen(false);
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111]"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-[70%] items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-visible rounded-md bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-bold text-lg">
                      Add tags to contacts
                    </span>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className="mt-5">
                  <TagsSelect
                    currentCompanyId={currentCompanyId}
                    currentLocationId={currentLocationId}
                    value={tags ?? []}
                    tagsOptions={tagsOptions}
                    addTags={addNewTags}
                    setAddTags={setAddNewTags}
                    tags={tags}
                    setTags={setTags}
                  />
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between  gap-x-4">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <SpinnerButton
                    className="inline-flex w-full justify-center btn-pink"
                    type="button"
                    action={handleSubmit}
                    title="Save"
                    isDisable={!isValidArray(tags) || addNewTags?.isAdding}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TagActionModal;
