import ShowPageTitle from "components/common/ShowPageTitle";
import DashboardForCompany from "components/dashboard/CompanyDashboard/DashboardForCompany";
import { isCompanyUser } from "module/util";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Dashboard",
          href: "/dashboard",
          current: false,
        },
      ])
    );
  }, []);

  if (isCompanyUser()) {
    return <DashboardForCompany />;
  }
  return (
    <div>
      <ShowPageTitle title="dashboard" />
      <h1 className="text-3xl font-bold underline">Dashboard</h1>
    </div>
  );
};

export default Dashboard;
