import { addDecimalToNumber } from "module/util";
import ReactApexChart from "react-apexcharts";

const MaritalStatusGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.marital_status ?? {};

  const createArrayForSeries = () => {
    const seriesArray = [
      graphDetails?.["Inferred Married"]?.[0]?.percent ?? 0,
      graphDetails?.["Inferred Single"]?.[0]?.percent ?? 0,
      graphDetails?.["Married"]?.[0]?.percent ?? 0,
      graphDetails?.["Single"]?.[0]?.percent ?? 0,
    ];

    return seriesArray;
  };

  const config = {
    series: [{ name: "", data: createArrayForSeries() }],
    options: {
      chart: {
        height: 300,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#3b3b3c"],
        },
        formatter: function (val, opt) {
          return `${addDecimalToNumber(val)}%`;
        },
      },
      xaxis: {
        show: false,
        categories: [
          "Inferred Married",
          "Inferred Single",
          "Married",
          "Single",
        ],
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: [{ show: true }],
      tooltip: {
        enabled: true,
        y: {
          formatter: (
            seriesName,
            { series, dataPointIndex, seriesIndex, w }
          ) => {
            const finalValue = series[seriesIndex][dataPointIndex];
            return `${addDecimalToNumber(finalValue)}%`;
          },
        },
      },
      legend: {
        show: false,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default MaritalStatusGraph;
