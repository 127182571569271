import SpinnerButton from "components/common/fields/SpinnerButton";
import UserTabs from "components/locations/UserTabs";
import { Field, Formik } from "formik";
import {
  getUserDataByParam,
  isCompanyUser,
  isValidObject,
  showErrorMsg,
} from "module/util";
import { companyLocationForm, showError } from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addLocation } from "redux/location/addLocationSlice";
import { getLocationDetail } from "redux/location/getLocationDetailSlice";
import { updateLocationDetail } from "redux/location/updateLocationDetailSlice";

const LocationForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const isEdit = location.pathname.includes("/locations/edit") && id;

  const [
    {
      getLocationDetailData = [],
      addLocationLoading = false,
      updateLocationLoading = false,
    },
  ] = useSelector((state) => [
    {
      getLocationDetailData: state?.getLocationDetail?.data,
      addLocationLoading: state?.addLocation?.loading,
      updateLocationLoading: state?.updateLocationDetail?.loading,
    },
  ]);

  useEffect(() => {
    if (isCompanyUser() && isEdit) {
      dispatch(getLocationDetail(id));
    }
  }, []);

  const locationDetailData = isValidObject(
    getLocationDetailData?.companyLocation
  )
    ? getLocationDetailData?.companyLocation
    : {};

  const initialValues = {
    address1: isEdit ? locationDetailData?.address1 : "",
    address2: isEdit ? locationDetailData?.address2 : "",
    category: isEdit ? locationDetailData?.category : "",
    city: isEdit ? locationDetailData?.city : "",
    company_id: isEdit ? locationDetailData?.company_id : "",
    email: isEdit ? locationDetailData?.email : "",
    name: isEdit ? locationDetailData?.name : "",
    phone: isEdit ? locationDetailData?.phone : "",
    region: isEdit ? locationDetailData?.region : "",
    state: isEdit ? locationDetailData?.state : "",
    status: isEdit ? locationDetailData?.status : "",
    zip: isEdit ? locationDetailData?.zip : "",
    primaryContact: isEdit ? locationDetailData?.primaryContact : "",
    primaryPhone: isEdit ? locationDetailData?.primaryPhone : "",
    primaryEmail: isEdit ? locationDetailData?.primaryEmail : "",
  };

  const handleSubmit = (values) => {
    const companyId = getUserDataByParam("COMPANY_ID");

    const formData = {
      address1: values?.address1 ?? "",
      address2: values?.address2 ?? "",
      category: values?.category ?? "",
      city: values?.city ?? "",
      company_id: companyId ?? "",
      email: values?.email ?? "",
      name: values?.name ?? "",
      phone: values?.phone ?? "",
      region: values?.region ?? "",
      state: values?.state ?? "",
      status: "active",
      zip: values?.zip ?? "",
    };

    if (isEdit) {
      dispatch(updateLocationDetail({ id, formData }));
    } else {
      dispatch(addLocation(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          navigate("/locations/list");
        } else {
          showErrorMsg(data?.payload ?? "Something went wrong.");
        }
      });
    }
  };

  return (
    <div className="flex gap-x-2 location-form-main">
      <div className="flex-1">
        <Formik
          initialValues={initialValues}
          validationSchema={companyLocationForm}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({
            errors,
            touched,
            validateField,
            validateForm,
            handleChange,
            values,
            handleSubmit,
          }) => (
            <form
              className="w-full min-w-xl border border-gray-200 rounded-lg p-3 bg-white"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      value={values?.name ?? ""}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.name && showError(errors?.name)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Category
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="category"
                      id="category"
                      value={values?.category ?? ""}
                      onChange={handleChange}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.category && showError(errors?.category)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Address
                  </label>
                  <div className="mt-2">
                    <input
                      id="address"
                      name="address1"
                      type="text"
                      value={values?.address1 ?? ""}
                      onChange={handleChange}
                      autoComplete="address"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.address1 && showError(errors?.address1)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="Address2"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Address2
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address2"
                      id="Address2"
                      value={values?.address2 ?? ""}
                      onChange={handleChange}
                      autoComplete="Address2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.address2 && showError(errors?.address2)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    City
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="city"
                      id="city"
                      value={values?.city ?? ""}
                      onChange={handleChange}
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.city && showError(errors?.city)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="state"
                      id="state"
                      value={values?.state ?? ""}
                      onChange={handleChange}
                      autoComplete="address-level1"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.state && showError(errors?.state)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="zip"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="zip"
                      id="zip"
                      value={values?.zip ?? ""}
                      onChange={handleChange}
                      autoComplete="zip"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.zip && showError(errors?.zip)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={values?.phone ?? ""}
                      onChange={handleChange}
                      autoComplete="phone"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.phone && showError(errors?.phone)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={values?.email ?? ""}
                      onChange={handleChange}
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.email && showError(errors?.email)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Region
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      value={values?.region ?? ""}
                      onChange={handleChange}
                      autoComplete="region"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {touched?.region && showError(errors?.region)}
                </div>
              </div>

              <div className="flex flex-wrap justify-start mb-6">
                <SpinnerButton
                  className="btn btn-gray"
                  title={"Save"}
                  action={() => {}}
                  type={"submit"}
                  loading={addLocationLoading || updateLocationLoading}
                />

                <button
                  onClick={() => navigate("/locations/list")}
                  type="button"
                  className="btn btn-red ml-2"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="flex-2 border border-gray-200 rounded-lg bg-white p-2">
        <div className="user-list mt-8">
          {isEdit && <UserTabs locationId={id} />}
        </div>
      </div>
    </div>
  );
};

export default LocationForm;
