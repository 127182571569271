import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const googleRefreshKey = createAsyncThunk(
  "reputation/google-refresh-key",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getApi(
        `api/gmb/refresh_key?company_location_id=${data?.location_id}&company_id=${data?.company_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const googleRefreshKeySlice = createSlice({
  name: "googleRefreshKey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(googleRefreshKey.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(googleRefreshKey.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(googleRefreshKey.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default googleRefreshKeySlice.reducer;
