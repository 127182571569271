import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Formik } from "formik";
import {
  getDateFormatLocal,
  getHeatMapConfig,
  isValidArray,
  setDateFormatLocal,
  setHeatMapConfig,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { settingsForm, showError } from "module/validation";
import { useEffect, useRef } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { updateSettings } from "redux/settings/updateSettingsSlice";

const SettingsForm = () => {
  const defaultDateFormat = getDateFormatLocal();
  const heatMapConfig = getHeatMapConfig();
  const dispatch = useDispatch();
  const colorPickerRef = useRef(null);

  const [{ settingsLoading = false, settingsData = {} }] = useSelector(
    (state) => [
      {
        settingsLoading: state?.updateSettings?.loading ?? false,
        settingsData: state?.getSettingsDetails?.data?.dataObj ?? {},
      },
    ]
  );

  const initialValues = {
    dateFormat: defaultDateFormat,
    heatMapZoom: heatMapConfig.heatMapZoom,
    mapZoom: heatMapConfig.mapZoom,
    heatMapColor1: heatMapConfig.heatMapColor1,
    heatMapColor2: heatMapConfig.heatMapColor2,
    heatMapColor3: heatMapConfig.heatMapColor3,
    heatMapColor1Show: false,
    heatMapColor2Show: false,
    heatMapColor3Show: false,
    phone: settingsData?.telnyx_mobile_no?.settings_value ?? "",
    accessKey: settingsData?.telnyx_api_key?.settings_value ?? "",
  };

  const dateFormateArray = [
    { name: "MM/DD/YYYY", value: "MM/dd/yyyy" },
    { name: "DD/MM/YYYY", value: "dd/MM/yyyy" },
    { name: "YYYY/MM/DD", value: "yyyy/MM/dd" },
  ];

  const handleSubmit = (values) => {
    const formData = [
      {
        settings_name: "date_format",
        settings_value: values?.dateFormat ?? "",
      },
      {
        settings_name: "heatmap_zoom",
        settings_value: values?.heatMapZoom ?? 13,
      },
      {
        settings_name: "map_zoom",
        settings_value: values?.mapZoom ?? 17,
      },
      {
        settings_name: "heatmap_color",
        settings_value: {
          color_1: values?.heatMapColor1?.hex ?? heatMapConfig.heatMapColor1,
          color_2: values?.heatMapColor2?.hex ?? heatMapConfig.heatMapColor2,
          color_3: values?.heatMapColor3?.hex ?? heatMapConfig.heatMapColor3,
        },
      },
      {
        settings_name: "telnyx_mobile_no",
        settings_value: values?.phone ?? "",
      },
      {
        settings_name: "telnyx_api_key",
        settings_value: values?.accessKey ?? "",
      },
    ];

    dispatch(updateSettings(formData)).then((data) => {
      if (data?.payload.code === 200) {
        const dateFormat =
          data?.payload?.dataObj?.settings?.date_format ?? "MM/dd/yyyy";
        const heatmapColorObj =
          data?.payload?.dataObj?.settings?.heatmap_color ?? "";
        const heatmapZoomLevel =
          data?.payload?.dataObj?.settings?.heatmap_zoom ?? 13;
        const mapZoomLevel = data?.payload?.dataObj?.settings?.map_zoom ?? 17;
        const heatmapColors = heatmapColorObj
          ? JSON.parse(heatmapColorObj)
          : heatMapConfig;
        const heatmapConfigObj = {
          heatMapZoom: heatmapZoomLevel,
          mapZoom: mapZoomLevel,
          heatMapColor1: heatmapColors?.color_1 ?? heatMapConfig.heatMapColor1,
          heatMapColor2: heatmapColors?.color_2 ?? heatMapConfig.heatMapColor2,
          heatMapColor3: heatmapColors?.color_3 ?? heatMapConfig.heatMapColor3,
        };
        setDateFormatLocal(dateFormat);
        setHeatMapConfig(JSON.stringify(heatmapConfigObj));
        showSuccessMsg(data?.payload?.message ?? "Record saved successfully");
      } else {
        const errorMsg = data?.payload?.exception?.message ?? "";
        showErrorMsg(errorMsg);
      }
    });
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Settings",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <div className="mt-4 w-full min-w-xl border border-gray-200 p-4 bg-white">
      <div>
        <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
          <span className="text-lg font-semibold text-gray-700  ">
            General Settings
          </span>
        </div>
        <div className="w-full h-[1px] bg-gray-300 mt-1 mb-5"></div>
        <Formik
          initialValues={initialValues}
          validationSchema={settingsForm}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          // validateOnBlur={false}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => {
            return (
              <form
                className="w-full min-w-xl "
                onSubmit={handleSubmit}
                onClick={(e) => {
                  if (
                    colorPickerRef.current &&
                    !colorPickerRef.current.contains(event.target)
                  ) {
                    setFieldValue("heatMapColor1Show", false);
                    setFieldValue("heatMapColor2Show", false);
                    setFieldValue("heatMapColor3Show", false);
                  }
                }}
              >
                <div className="flex  flex-wrap -mx-3 mb-3">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="dateFormat" className="input-field-label">
                      Date Format
                    </label>
                    <div className="mt-2">
                      <Field
                        as="select"
                        className="input-field"
                        name="dateFormat"
                        value={values?.dateFormat ?? ""}
                        onChange={(e) => {
                          setFieldValue("dateFormat", e.target.value);
                        }}
                      >
                        <option key={1} value={""}>
                          Select Date Format
                        </option>
                        {isValidArray(dateFormateArray) &&
                          dateFormateArray.map((item) => (
                            <option
                              key={item?.value}
                              value={item?.value ?? ""}
                              defaultValue={values?.value ?? ""}
                            >
                              {item?.name}
                            </option>
                          ))}
                      </Field>
                      <span className="text-gray-600 text-xs">
                        (All dates will be show as a selected date format.)
                      </span>
                    </div>
                    {touched?.dateFormat && showError(errors?.dateFormat)}
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="heatMapZoom" className="input-field-label">
                      Google heat map zoom level
                    </label>
                    <div className="mt-2">
                      <Field
                        type="number"
                        max={20}
                        min={1}
                        className="input-field"
                        name="heatMapZoom"
                        value={values?.heatMapZoom ?? ""}
                        onChange={(e) => {
                          if (e.target.value <= 20 && e.target.value >= 1) {
                            setFieldValue("heatMapZoom", e.target.value);
                          }
                        }}
                      ></Field>
                    </div>
                    {touched?.heatMapZoom && showError(errors?.heatMapZoom)}
                  </div>
                </div>

                <label className="input-field-label font-medium">
                  Heat Map Colors
                </label>
                <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="flex w-full md:w-1/2 " ref={colorPickerRef}>
                    <div className="w-full px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="heatMapColor1"
                        className="w-20 text-[14.5px] text-gray-700"
                      >
                        Minimum
                      </label>
                      <div className="mt-2 relative">
                        <div
                          className="w-20 h-7 cursor-pointer hover:border-[1px] border-white"
                          title="Pick Color"
                          style={{
                            backgroundColor:
                              values?.heatMapColor1?.hex ??
                              values?.heatMapColor1,
                          }}
                          onClick={() => {
                            setFieldValue(
                              "heatMapColor1Show",
                              !values?.heatMapColor1Show
                            );
                            setFieldValue("heatMapColor2Show", false);
                            setFieldValue("heatMapColor3Show", false);
                          }}
                        ></div>
                        {values?.heatMapColor1Show && (
                          <ChromePicker
                            className="absolute"
                            color={values.heatMapColor1 || false}
                            value={values?.heatMapColor1}
                            onChange={(color) => {
                              setFieldValue("heatMapColor1", color);
                            }}
                          />
                        )}
                      </div>
                      {touched?.heatMapColor1 &&
                        showError(errors?.heatMapColor1)}
                    </div>
                    <div className="w-full px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="heatMapColor2"
                        className="w-20 text-[14.5px] text-gray-700"
                      >
                        Medium
                      </label>
                      <div className="mt-2 relative">
                        <div
                          className="w-20 h-7 cursor-pointer hover:border-[1px] border-white"
                          title="Pick Color"
                          style={{
                            backgroundColor:
                              values?.heatMapColor2?.hex ??
                              values?.heatMapColor2,
                          }}
                          onClick={() => {
                            setFieldValue(
                              "heatMapColor2Show",
                              !values?.heatMapColor2Show
                            );
                            setFieldValue("heatMapColor1Show", false);
                            setFieldValue("heatMapColor3Show", false);
                          }}
                        ></div>
                        {values?.heatMapColor2Show && (
                          <ChromePicker
                            className="absolute"
                            color={values.heatMapColor2 || false}
                            value={values?.heatMapColor2}
                            onChange={(color) => {
                              setFieldValue("heatMapColor2", color);
                            }}
                          />
                        )}
                      </div>
                      {touched?.heatMapColor2 &&
                        showError(errors?.heatMapColor2)}
                    </div>
                    <div className="w-full px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="heatMapColor3"
                        className="w-20 text-[14.5px] text-gray-700"
                      >
                        Maximum
                      </label>
                      <div className="mt-2 relative">
                        <div
                          className="w-20 h-7 cursor-pointer hover:border-[1px] border-white"
                          title="Pick Color"
                          style={{
                            backgroundColor:
                              values?.heatMapColor3?.hex ??
                              values?.heatMapColor3,
                          }}
                          onClick={() => {
                            setFieldValue(
                              "heatMapColor3Show",
                              !values?.heatMapColor3Show
                            );
                            setFieldValue("heatMapColor1Show", false);
                            setFieldValue("heatMapColor2Show", false);
                          }}
                        ></div>
                        {values?.heatMapColor3Show && (
                          <ChromePicker
                            className="absolute"
                            color={values.heatMapColor3 || false}
                            value={values?.heatMapColor3}
                            onChange={(color) => {
                              setFieldValue("heatMapColor3", color);
                            }}
                          />
                        )}
                      </div>
                      {touched?.heatMapColor3 &&
                        showError(errors?.heatMapColor3)}
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="mapZoom" className="input-field-label">
                      Google map zoom level
                    </label>
                    <div className="mt-2">
                      <Field
                        type="number"
                        max={20}
                        min={1}
                        className="input-field"
                        name="mapZoom"
                        value={values?.mapZoom ?? ""}
                        onChange={(e) => {
                          if (e.target.value <= 20 && e.target.value >= 1) {
                            setFieldValue("mapZoom", e.target.value);
                          }
                        }}
                      ></Field>
                    </div>
                    {touched?.mapZoom && showError(errors?.mapZoom)}
                  </div>
                </div>

                <div className="w-full flex gap-2 md:w-1/2 my-6 md:mb-0">
                  <span className="text-lg font-semibold text-gray-700  ">
                    Telnyx Settings
                  </span>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1 mb-5"></div>

                <div className="flex  flex-wrap -mx-3 mb-3">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="phone" className="input-field-label">
                      Sender Phone Number
                    </label>
                    <CustomPhoneInput
                      values={values}
                      name="phone"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                    />
                    {touched?.phone && showError(errors?.phone)}
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="accessKey" className="input-field-label">
                      API Key
                    </label>
                    <div className="mt-2">
                      <Field
                        type="accessKey"
                        name="accessKey"
                        id="accessKey"
                        value={values?.accessKey ?? ""}
                        onChange={handleChange}
                        autoComplete="accessKey"
                        placeholder="Enter Key"
                        className="input-field px-2"
                      />
                    </div>
                    {touched?.accessKey && showError(errors?.accessKey)}
                  </div>
                </div>

                {/* <div className="flex  flex-wrap -mx-3 mb-3">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="userName" className="input-field-label">
                      Name
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="userName"
                        id="userName"
                        value={values?.name ?? ""}
                        onChange={handleChange}
                        autoComplete="userName"
                        placeholder="Enter Name"
                        className="input-field"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="password" className="input-field-label">
                      Password
                    </label>
                    <div className="relative mt-2">
                      <Field
                        id="password"
                        name="password"
                        type={isPasswordShow ? "text" : "password"}
                        autoComplete="current-password"
                        value={values?.password ?? ""}
                        onChange={handleChange}
                        placeholder="Enter Password"
                        className="input-field pr-10"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <img
                          alt="eye"
                          src={isPasswordShow ? eyeIcon : eyeSlashIcon}
                          className="hover:cursor-pointer"
                          onClick={() => setPasswordShow(!isPasswordShow)}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="flex flex-wrap justify-start mt-10">
                  <SpinnerButton
                    className="btn-pink"
                    title={"Save"}
                    action={() => {}}
                    type={"submit"}
                    loading={settingsLoading}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SettingsForm;
