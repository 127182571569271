import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import SettingsForm from "components/settings/SettingsForm";
import SettingsList from "components/settings/SettingsList";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab = searchParams.get("tab");
  const totalTabs = ["setting", "list"];
  const [currentTab, setTab] = useState(
    totalTabs.includes(currentActiveTab) ? currentActiveTab : "setting"
  );

  const settingTabsArray = [
    { name: "Settings", value: "setting", href: "#", current: false },
    // { name: "List Of Matches", value: "list", href: "#", current: false },
  ];

  return (
    <>
      <ShowPageTitle title={"settings"} />
      <div className="flex border-b border-collapse border-gray-300">
        <div className=" flex-1  w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {settingTabsArray.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setTab(tab.value);
                  navigate(`/settings?tab=${tab.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === currentTab,
                    "border-transparent": tab.value !== currentTab,
                  }
                )}
                aria-current={tab.value === currentTab ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      {currentTab === "setting" && <SettingsForm />}
      {currentTab === "list" && <SettingsList />}
    </>
  );
};

export default Settings;
