import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import GuestWIFI from "components/companySettings/GuestWIFI";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Integrations from "components/companySettings/integrations/Integrations";
import Tags from "components/companySettings/tags/Tags";
import {
  createFirstLetterCapital,
  getCurrentLocation,
  getUserData,
  getUserDataByParam,
} from "module/util";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getLocationDetail } from "redux/location/getLocationDetailSlice";

const CompanySettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab = searchParams.get("tab");
  const integration = searchParams.get("setting");
  const totalTabs = ["guestWifi", "integrations", "tags"];
  const [currentTab, setTab] = useState(
    totalTabs.includes(currentActiveTab) ? currentActiveTab : "guestWifi"
  );
  const { id: currentLocationId, location: locationName } =
    getCurrentLocation();
  const { company_id: currentCompanyId } = getUserData()
    ? JSON.parse(getUserData())
    : "";

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const getLocationDetailData = useSelector(
    (state) => state?.getLocationDetail?.data ?? ""
  );

  const handleLocationDetail = () => {
    dispatch(getLocationDetail(currentLocationId));
  };

  useEffect(() => {
    handleLocationDetail();
  }, [currentLocationId]);
  const companyName = getUserDataByParam("COMPANY_NAME");

  const settingTabsArray = [
    { name: "Guest WIFI", value: "guestWifi", href: "#", current: false },
    { name: "Integrations", value: "integrations", href: "#", current: false },
    { name: "Tags", value: "tags", href: "#", current: false },
  ];

  const breadCrumbTitle = {
    guestWifi: "Guest WIFI",
    integrations: "Integrations",
    tags: "Tags",
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Settings",
          href: "",
          current: false,
        },
        {
          name: breadCrumbTitle[currentTab],
          href: "/user-settings?tab=integrations",
          current: integration ? true : false,
        },
        integration
          ? {
              name: `${createFirstLetterCapital(integration)} Integration`,
              href: "",
              current: false,
            }
          : {},
      ])
    );
  }, [currentTab, integration]);

  return (
    <>
      <ShowPageTitle title={"companySettings"} />
      <div className="flex border-b border-collapse border-gray-300">
        <div className=" flex-1  w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {settingTabsArray.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setTab(tab.value);
                  navigate(`/user-settings?tab=${tab.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === currentTab,
                    "border-transparent": tab.value !== currentTab,
                  }
                )}
                aria-current={tab.value === currentTab ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      {currentTab === "guestWifi" && (
        <GuestWIFI
          currentLocationId={currentLocationId}
          type={currentTab}
          locationName={locationName}
          companyName={companyName}
          currentCompanyId={currentCompanyId}
          handleLocationDetail={handleLocationDetail}
          getLocationDetailData={getLocationDetailData}
        />
      )}
      {currentTab === "integrations" && (
        <Integrations
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
          type={currentTab}
        />
      )}
      {currentTab === "tags" && (
        <Tags
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
          type={currentTab}
        />
      )}
    </>
  );
};

export default CompanySettings;
