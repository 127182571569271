import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import copy from "copy-to-clipboard";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getCurrentLocation,
  getUserDataByParam,
  isCompanyUser,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { companyList } from "redux/company/companyListSlice";
import { getLocationsByCompany } from "redux/leadList/getLocationsByCompanySlice";
import { generateUrl } from "redux/reputation/generateUrlSlice";
import * as yup from "yup";

const Reputation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [totalCompanyList, setTotalCompanyList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [url, setUrl] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [name, setName] = useState("");
  const companyId = getUserDataByParam("COMPANY_ID");
  const { id: currentLocationId } = getCurrentLocation();

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  useEffect(() => {
    const companyId = getUserDataByParam("COMPANY_ID");

    dispatch(
      setBreadCrumb([
        {
          name: "Reputation",
          href: "/reputation",
          current: true,
        },
        {
          name: "Get Reviews",
          href: "/reputation?tab=get_reviews",
          current: true,
        },
        {
          name: "Add Reputation",
          href: "",
          current: false,
        },
      ])
    );

    dispatch(
      companyList({ companyStatus: "active", with_location: true })
    ).then((data) => {
      if (data?.payload?.data) {
        setTotalCompanyList(data?.payload?.data);
        if (isCompanyUser()) {
          setTimeout(() => {
            setCompany(companyId);
            handleCompanyLocation(companyId);
          }, 100);
        }
      }
    });
  }, [locationId]);

  const handleCompanyLocation = (companyId) => {
    if (companyId) {
      dispatch(getLocationsByCompany(companyId)).then((data) => {
        if (isValidObject(data?.payload)) {
          setLocations(data?.payload);
          const { id: currentLocationId } = getCurrentLocation();
          if (isCompanyUser()) {
            setTimeout(() => {
              setLocation(currentLocationId);
            }, 100);
          }
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      setLocations([]);
    }
  };

  const handleCopy = () => {
    if (generatedUrl) {
      const isCopy = copy(generatedUrl);
      if (isCopy) {
        showSuccessMsg("URL Copied successfully.");
      }
    }
  };

  const handleGenerateUrl = () => {
    if (company && location && url && name) {
      const formData = {
        company_id: company,
        location_id: location,
        original_url: url,
        title: name,
      };
      dispatch(generateUrl(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          setGeneratedUrl(data?.payload?.dataObj.trackable_url);
          navigate("/reputation?tab=get_reviews");
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      if (!company) {
        showErrorMsg("Please select company");
      } else if (!location) {
        showErrorMsg("Please select location");
      } else if (!url) {
        showErrorMsg("Please enter url");
      } else if (!name) {
        showErrorMsg("Please enter name");
      }
    }
  };

  const initialValues = {
    company: companyId ?? "",
    location: currentLocationId ?? "",
    url: "",
    name: "",
  };

  const validationSchema = yup.object({
    company: yup.string().required("Please select company."),
    location: yup.string().required("Please select location."),
    url: yup.string().required("Please enter Url."),
    name: yup.string().required("Please write name."),
  });

  return (
    <div className="border border-gray-200 p-3 bg-white h-full mt-4">
      <ShowPageTitle title="reputation" />
      <Formik
        onSubmit={(values, { resetForm }) => {
          handleGenerateUrl();
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          values,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className="w-full" id="form1" name="form1">
            <div className="flex  w-[50%] flex-wrap -mx-3 mb-3">
              <div className="w-full  px-3 mb-3">
                <label htmlFor="company" className="input-field-label">
                  Company
                </label>
                <div className="mt-2">
                  <Field
                    as="select"
                    className="input-field"
                    id="company"
                    name="company"
                    onChange={(e) => {
                      setFieldValue("company", e.target.value);
                      setCompany(e.target.value);
                      handleCompanyLocation(e.target.value);
                      if (!e.target.value) {
                        setLocation("");
                      }
                    }}
                    value={values?.company ?? ""}
                    disabled={isCompanyUser()}
                  >
                    <option key={1} value={""}>
                      Select Company
                    </option>
                    {isValidArray(totalCompanyList) &&
                      totalCompanyList.map((item) => (
                        <option
                          key={item?.id}
                          value={item?.id ?? ""}
                          defaultValue={values?.company ?? ""}
                        >
                          {item?.company ?? item?.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    component={"div"}
                    className="text-red-500"
                    name={"company"}
                  />
                </div>
              </div>

              <div className="w-full  px-3 mb-3">
                <label htmlFor="location" className="input-field-label">
                  Location
                </label>
                <div className="mt-2">
                  <Field
                    as="select"
                    className="input-field"
                    id="location"
                    name="location"
                    onChange={(e) => {
                      setFieldValue("location", e.target.value);
                      setLocation(e.target.value);
                    }}
                    value={values?.location ?? ""}
                  >
                    <option key={1} value={""}>
                      Select Location
                    </option>
                    {isValidArray(locations) &&
                      locations.map((item) => (
                        <option
                          key={item?.id}
                          value={item?.id ?? ""}
                          defaultValue={values?.location ?? ""}
                        >
                          {item?.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    component={"div"}
                    className="text-red-500"
                    name={"location"}
                  />
                </div>
              </div>

              <div className="w-full  px-3 mb-3">
                <label htmlFor="url" className="input-field-label">
                  Target URL
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="url"
                    id="url"
                    value={url ?? ""}
                    onChange={(e) => {
                      setFieldValue("url", e.target.value);
                      setUrl(e.target.value);
                    }}
                    placeholder="Enter Target url"
                    className="input-field"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-red-500"
                    name={"url"}
                  />
                </div>
              </div>

              <div className="w-full  px-3 mb-3">
                <label htmlFor="name" className="input-field-label">
                  Name
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    value={name ?? ""}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value),
                        setName(e.target.value);
                    }}
                    placeholder="Enter Name"
                    className="input-field"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-red-500"
                    name={"name"}
                  />
                </div>
              </div>
              {generatedUrl && (
                <div className="w-full px-3 mb-3">
                  <label htmlFor="generatedUrl" className="input-field-label">
                    Generated URL
                  </label>
                  <div className="flex mt-2">
                    <Field
                      type="text"
                      name="generatedUrl"
                      id="generatedUrl"
                      readOnly
                      value={generatedUrl ?? ""}
                      onChange={(e) => {
                        setFieldValue("generateUrl", e.target.value),
                          setGeneratedUrl(e.target.value);
                      }}
                      placeholder="Enter generated url"
                      className="input-field"
                    />
                    <button className="btn btn-gray ml-2" onClick={handleCopy}>
                      Copy URL
                    </button>
                  </div>
                </div>
              )}
              <div className="w-full text-center  px-3 mb-3">
                <SpinnerButton
                  className="btn-pink"
                  title={"Generate URL"}
                  type={"submit"}
                  // action={() => handleGenerateUrl()}
                  loading={false}
                />
                <button
                  className="btn btn-gray ml-2"
                  onClick={() => navigate("/reputation?tab=get_reviews")}
                >
                  Back
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Reputation;
