import { addDecimalToNumber } from "module/util";
import ReactApexChart from "react-apexcharts";

const ChildrenPresentGraph = ({ getCampaignReportData = {} }) => {
  const graphDetails =
    getCampaignReportData?.childrenPresentRedemptionGraph ?? {};

  const createArrayForSeries = () => {
    const offerCount = graphDetails?.["No Data"]?.length ?? 0;

    const offerData1 = [
      graphDetails?.["No Data"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["Y"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["N"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["Unknown"]?.[0]?.redemption_amount ?? 0,
    ];

    const offerData2 = [
      graphDetails?.["No Data"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["Y"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["N"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["Unknown"]?.[1]?.redemption_amount ?? 0,
    ];

    const seriesArray = [
      {
        name: graphDetails?.["No Data"]?.[0]?.offer_name ?? "",
        type: "bar",
        data: offerData1,
      },
      ...(offerCount > 1
        ? [
            {
              name: graphDetails?.["No Data"]?.[1]?.offer_name ?? "",
              type: "bar",
              data: offerData2,
            },
          ]
        : []),
      // {
      //   name: graphDetails?.["No Data"]?.[0]?.offer_name ?? "",
      //   type: "line",
      //   data: offerData1,
      // },
      // ...(offerCount > 1
      //   ? [
      //       {
      //         name: graphDetails?.["No Data"]?.[1]?.offer_name ?? "",
      //         type: "line",
      //         data: offerData2,
      //       },
      //     ]
      //   : []),
    ];

    return seriesArray;
  };

  const config = {
    series: createArrayForSeries(),
    options: {
      chart: {
        height: 350,
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      title: {
        text: "Children Present",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return `$${addDecimalToNumber(val)}`;
        },
      },
      labels: ["No Data", "Yes", "NO", "Unknown"],
      xaxis: {
        type: "category",
      },
      yaxis: [],
      tooltip: {
        enabled: true,
        y: {
          formatter: (
            seriesName,
            { series, dataPointIndex, seriesIndex, w }
          ) => {
            const finalValue = series[seriesIndex][dataPointIndex];
            return `$${addDecimalToNumber(finalValue)} `;
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default ChildrenPresentGraph;
