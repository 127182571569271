import { Dialog, Transition } from "@headlessui/react";
import CustomTable from "components/common/fields/CustomTable";
import { showPerPage, showPrice } from "module/util";
import { Fragment, useRef, useState } from "react";

const OrderDetailsModal = (props) => {
  const { isModalOpen, setModalOpen, data, orderData, selectedId = 0 } = props;
  const [sortIndex, setSortIndex] = useState(12);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);

  const totalRecords = 100;
  const currentPerPage = showPerPage;

  const OrderDetailsObj = orderData?.find(
    (item) => item.order_id === selectedId
  );

  const orderDataLoading = true;

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      // Cell: ({ cell }) => (
      //   <span>{cell?.row?.values?.customer?.order_date ?? ""}</span>
      // ),
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      // Cell: ({ cell }) => (
      //   <span>{cell?.row?.values?.customer?.price ?? ""}</span>
      // ),
    },
    {
      Header: "Price",
      accessor: "per_price",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    {
      Header: "Total Amount",
      accessor: "total_price",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
  ];

  const removeUnderscore = (data) => {
    if (data !== null) {
      return (
        data
          .split("_")
          // .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ")
      );
    } else return "N/A";
  };

  const cancelButtonRef = useRef(null);
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111]"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-bold text-lg">Order Details</span>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className=" mt-4">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-normal text-gray-900 text-lg border-b border-gray-300">
                      Customer Details :
                    </span>
                  </div>
                  <div className="w-full flex-wrap flex   mt-4 mb-6 md:mb-0 ml-2">
                    <div className="flex  justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">First Name :</label>{" "}
                      <label>{OrderDetailsObj?.first_name ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2">
                      <label className="font-light">Last Name :</label>{" "}
                      <label>{OrderDetailsObj?.last_name ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Email :</label>{" "}
                      <label>{OrderDetailsObj?.email ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2">
                      <label className="font-light">Phone :</label>{" "}
                      <label>{OrderDetailsObj?.phone ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2">
                      <label className="font-light">Address :</label>{" "}
                      <label>{OrderDetailsObj?.address1 ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2">
                      <label className="font-light">Zip :</label>{" "}
                      <label>{OrderDetailsObj?.zip ?? "N/A"}</label>
                    </div>
                  </div>
                </div>

                <div className=" mt-4">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-normal text-gray-900 text-lg border-b border-gray-300">
                      Order Details :
                    </span>
                  </div>
                  <div className="w-full flex-wrap flex   mt-4 mb-6 md:mb-0 ml-2">
                    <div className="flex  justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Discount Name :</label>{" "}
                      <label>
                        {OrderDetailsObj?.orders_discount_name ?? "N/A"}
                      </label>
                    </div>
                    <div className="flex  justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Quantity :</label>{" "}
                      <label>{OrderDetailsObj?.quantity ?? "N/A"}</label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2">
                      <label className="font-light">Total Amount :</label>{" "}
                      <label>
                        {showPrice(OrderDetailsObj?.total_price) ?? "N/A"}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Paid Amount :</label>{" "}
                      <label>
                        {showPrice(OrderDetailsObj?.total_money) ?? "N/A"}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Discount Price :</label>{" "}
                      <label>
                        {showPrice(OrderDetailsObj?.total_discount_money) ??
                          "N/A"}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Discount Type :</label>{" "}
                      <label>
                        {removeUnderscore(OrderDetailsObj?.discount_type)}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Tip Price :</label>{" "}
                      <label>
                        {showPrice(OrderDetailsObj?.total_tip_money) ?? "N/A"}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">
                        Total Service Charge :
                      </label>{" "}
                      <label>
                        {showPrice(
                          OrderDetailsObj?.total_service_charge_money
                        ) ?? "N/A"}
                      </label>
                    </div>
                    <div className="flex justify-start gap-x-2 md:w-1/2 mb-2">
                      <label className="font-light">Order Type :</label>{" "}
                      <label>
                        {OrderDetailsObj?.order_fulfillment_type ?? "N/A"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-normal text-gray-900 text-lg border-b border-gray-300">
                      Order Items :
                    </span>
                  </div>
                  <div className=" mt-4 flex flex-col h-[30rem] ml-2 max-h-[300px]">
                    <CustomTable
                      data={OrderDetailsObj?.order_items ?? []}
                      isLoading={orderDataLoading === false}
                      columns={columns}
                      defaultSortIndexColumn={sortIndex}
                      defaultOrder={sortOrder}
                      setSortIndex={setSortIndex}
                      setSortOrder={setSortOrder}
                      totalRecords={totalRecords}
                      center
                      perPage={perPage ? perPage : currentPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setPerPage={setPerPage}
                      isPaginationHide={true}
                      colSpan={12}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderDetailsModal;
