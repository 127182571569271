import { addDecimalToNumber, ageSpendRange } from "module/util";
import ReactApexChart from "react-apexcharts";

const HeatMapComponent = (props) => {
  const { getCampaignReportData, loading } = props;
  const graphDetails = getCampaignReportData?.incomeSpentGraph ?? [];

  const initValue = {
    options: {
      chart: {
        height: 350,
        type: "heatmap",
      },
      xaxis: {
        position: "top",
      },
      legend: {
        position: "bottom",
      },
      noData: {
        text: loading ? "Loading..." : "No Data present in the graph!",
        style: {
          color: "#000000",
        },
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          enableShades: false,
          radius: 0,
          useFillColorAsStroke: false,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                name: "0",
                color: "#c8eafa",
              },
              ...ageSpendRange(graphDetails),
            ],
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (
          value,
          { series, dataPointIndex, seriesIndex, w }
        ) {
          const finalValue = w.globals.series[seriesIndex][dataPointIndex];
          return `$${addDecimalToNumber(finalValue)}`;
        },
        style: { colors: ["#1c1b1b"] },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const currentName = w.globals.seriesNames[seriesIndex];
          const currentValue = series[seriesIndex][dataPointIndex];
          const finalData = `${currentName}  -  $${addDecimalToNumber(
            currentValue
          )} `;
          return finalData;
        },
      },
      stroke: {
        width: 2,
      },
      title: {
        text: "Age / Income Vs. Spend",
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={initValue.options}
        series={graphDetails}
        type="heatmap"
        height={350}
      />
    </div>
  );
};

export default HeatMapComponent;
