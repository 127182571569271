const MulticolorDiv = () => {
  return (
    <div className="sticky top-0  flex justify-between z-[999]">
      <div className="h-1.5 flex-1 bg-gradient-to-r from-[#ffd25f] to-[#fdf186]"></div>
      <div className="h-1.5 flex-1 bg-gradient-to-r from-[#f35288] to-[#f186ac]"></div>
      <div className="h-1.5 flex-1 bg-gradient-to-r from-[#37d6eb] to-[#B5E4FA]"></div>
      <div className="h-1.5 flex-1 bg-gradient-to-r from-[#a5d583] to-[#dcf1af] "></div>
    </div>
  );
};

export default MulticolorDiv;
