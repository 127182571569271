import { API_URL } from "module/api";
import {
  convertTimeStampToDateTime,
  createFirstLetterCapital,
  getCurrentLocation,
  isTokenExpired,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SpinnerButton from "components/common/fields/SpinnerButton";
import GuestWifiLoader from "components/front/GuestWifiLoader";
import CustomDropDown from "components/reputation/CustomDropDown";
import { handleServerValidation } from "module/validation";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";
import { getAssociateList } from "redux/reputation/getAssociateListSlice";
import { getGoogleLocation } from "redux/reputation/getGoogleLocationSlice";
import { getReviewTemplate } from "redux/reputation/getReviewTemplateSlice";
import { googleDisconnect } from "redux/reputation/googleDisconnectSlice";
import { googleRefreshKey } from "redux/reputation/googleRefeshKeySlice";

export default function GoogleIntegration({
  integration,
  currentCompanyId = "",
  currentLocationId = "",
}) {
  const dispatch = useDispatch();
  const currentLocation = getCurrentLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [selectedLocation, setSelectedLocation] = useState({
    name: "Select Locations",
    id: "",
    category: "",
  });
  const [buttonDisable, setButtonDisable] = useState(true);

  let locationname = selectedLocation?.name?.split("|");
  let data = {
    company_id: currentCompanyId,
    location_id: currentLocation?.id,
    google_location_id: selectedLocation?.id,
    google_location_company_name: locationname[0],
    google_location_category: selectedLocation?.category,
  };

  const [
    {
      locationListData = {},
      checkExpireData = {},
      googleLocationLoading = false,
      associateLocationLoading = false,
      googleRefreshKeyLaoding = false,
    },
  ] = useSelector((state) => [
    {
      locationListData: state?.getGoogleLocation?.data ?? {},
      googleLocationLoading: state?.getGoogleLocation?.loading ?? false,
      checkExpireData: state?.checkExpireToken?.data ?? {},
      associateLocationLoading: state?.getAssociateList?.loading ?? false,
      googleRefreshKeyLaoding: state?.googleRefreshKey?.loading ?? false,
    },
  ]);

  const handleRefreshToken = () => {
    dispatch(googleRefreshKey(data)).then((data) => {
      if (data?.payload?.code === 200) {
        handleCheckExpireToken();
        showSuccessMsg(data?.payload?.dataObj?.message);
      } else handleServerValidation(data);
    });
  };

  const discoonectGoogle = () => {
    setIsLoading(true);
    dispatch(googleDisconnect(data)).then((data) => {
      if (data?.payload?.code === 200) {
        handleCheckExpireToken();
        showSuccessMsg(
          data?.payload?.dataObj?.message ?? "Disconnected Successfully"
        );
      }
    });
  };
  const saveAssociateLocation = () => {
    setIsLoading(true);
    dispatch(getAssociateList(data)).then((res) => {
      showSuccessMsg(res?.payload?.dataObj?.message);
      setButtonDisable(true);
      dispatch(getReviewTemplate(data)).then((data) => {
        if (data?.payload?.code === 200) {
          setIsLoading(false);
        }
      });
    });
  };
  const createLoactionStatusBadge = (text) => {
    const parts = text?.split("|")?.map((part) => part.trim());
    let statusBadge = "";
    if (parts.length === 2) {
      switch (parts[1]) {
        case "Complete Ownership":
          statusBadge = `bg-green-500`;
          break;
        case "No Ownership":
          statusBadge = `bg-red-800`;
          break;
        case "Need Verification":
          statusBadge = `bg-amber-400`;
          break;
        case "Location Under Review":
          statusBadge = `bg-yellow-400`;
          break;
        case "Unspecified":
          statusBadge = `bg-orange-400`;
          break;
        case "Suspended":
          statusBadge = `bg-red-500`;
          break;
        case "Disabled":
          statusBadge = `bg-gray-400`;
          break;
      }
      return (
        <>
          <div className="sm:flex">
            {parts[0]}
            <p
              className={`ml-2 text-white ${statusBadge} text-sm font-medium me-2 px-2.5 py-1 rounded-md`}
            >
              {parts[1]}
            </p>
          </div>
        </>
      );
    } else {
      return "Select Locations";
    }
  };

  const handleGetGoogleLocation = () => {
    dispatch(getGoogleLocation(data)).then((data) => {
      if (data?.payload?.code === 200) {
        setSelectedLocation({
          name: data?.payload?.dataObj?.location?.name ?? "Select Locations",
          id: data?.payload?.dataObj?.location?.id ?? "",
          category: data?.payload?.dataObj?.location?.category ?? "",
        });
      } else {
        showErrorMsg(data ?? "Something went wrong.");
      }
    });
  };

  const handleCheckExpireToken = () => {
    dispatch(checkExpireToken(data)).then((res) => {
      if (res?.payload?.dataObj?.status === true) {
        handleGetGoogleLocation();
      } else if (res?.payload?.dataObj?.status === false) {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    handleCheckExpireToken();
  }, [currentLocationId]);

  useEffect(() => {
    if (
      checkExpireData?.dataObj?.status === false ||
      checkExpireData?.dataObj?.status === "refresh_key"
    ) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, [1000]);
    }
  }, [currentLocationId]);

  return (
    <div className="w-full py-4">
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn-pink w-20"
          onClick={() => {
            navigate(`/user-settings?tab=integrations`);
          }}
        >
          Back
        </button>

        <div>
          <SpinnerButton
            className={
              !isTokenExpired(checkExpireData?.dataObj?.key_expired_at).expired
                ? `btn-green`
                : `btn-yellow  w-[148px]`
            }
            title={`Refresh Key @`}
            action={() => {
              handleRefreshToken();
            }}
            type={"button"}
            loading={googleRefreshKeyLaoding}
            isDisable={
              !checkExpireData?.dataObj?.status ||
              !isTokenExpired(checkExpireData?.dataObj?.key_expired_at).expired
            }
          />
        </div>
      </div>

      <div className="mt-2 flex flex-col xl:flex-row gap-4">
        <div
          className={`bg-white border-2 border-gray-400 2xl:w-[25%] xl:w-[40%]  w-full flex flex-col justify-between items-center`}
        >
          <div className="p-4 flex flex-col gap-y-4 justify-center items-center">
            <div className=" h-16 w-16">
              <img className="h-16 w-16" src={integration?.image} />
            </div>
            <p className="text-md font-semibold text-gray-700">{`Google`}</p>
            <div>
              <p className="text-md text-gray-700">
                {integration?.description}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white border-2 border-gray-400 2xl:w-[80%] xl:w-[75%] w-full flex flex-col justify-between">
          <div className="flex flex-col ">
            <div className="p-4">
              <p className="text-md font-semibold text-gray-700">
                {`Connect to your Google account`}
              </p>
              <div>
                <p className="text-md text-gray-700">
                  {integration?.instruction}
                </p>
              </div>
            </div>

            <div className="">
              <form
                className="w-full flex flex-col gap-20"
                action={`${API_URL}gmb/oauth?company_id=${currentCompanyId}&location_id=${currentLocationId}`}
                method="POST"
                id="form1"
                name="form1"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              >
                <div className="w-full">
                  <div className="flex w-full px-4 mt-2 gap-2 ">
                    <div className="w-[40%] text-end">
                      <label
                        className="font-light leading-6 text-gray-900 px-4 "
                        htmlFor="authorization"
                      >
                        Authorization
                      </label>
                    </div>
                    <div className="w-full  ">
                      {checkExpireData?.dataObj?.status === false ? (
                        <button className=" btn btn-yellow">
                          Connect Google
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" btn btn-red"
                          onClick={() => {
                            discoonectGoogle();
                          }}
                        >
                          Disconnect Google
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="flex w-full gap-2 px-4 mt-4">
                    <div className="w-[40%] text-end">
                      <label
                        className="font-light leading-6 text-gray-900 px-4"
                        htmlFor="status"
                      >
                        Status
                      </label>
                    </div>
                    <div
                      className={`w-full font-semibold  ${
                        checkExpireData?.dataObj?.status === true
                          ? `text-green-600`
                          : checkExpireData?.dataObj?.status === false
                          ? `text-orange-600`
                          : `text-amber-600`
                      }`}
                    >
                      <p>
                        {checkExpireData?.dataObj?.status === true
                          ? createFirstLetterCapital(
                              locationListData?.dataObj?.status
                            )
                          : checkExpireData?.dataObj?.status === false
                          ? "Not Connected"
                          : createFirstLetterCapital(
                              checkExpireData?.dataObj?.message
                            )}
                      </p>
                    </div>
                  </div>

                  {checkExpireData?.dataObj?.status === true && (
                    <div className="flex w-full gap-2 px-4 mt-5 ">
                      <div className="w-[40%] text-end ">
                        <label
                          className="font-light leading-6 text-gray-900 px-4 "
                          htmlFor="location"
                        >
                          Location
                        </label>
                      </div>
                      <div className="w-full">
                        <CustomDropDown
                          filterDropDown={locationListData?.dataObj?.data}
                          locationListData={locationListData?.dataObj}
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          createLoactionStatusBadge={createLoactionStatusBadge}
                          setButtonDisable={setButtonDisable}
                          buttonDisable={buttonDisable}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          {checkExpireData?.dataObj?.status === true && (
            <>
              <div
                className={` bg-gray-200 w-full flex mt-4 ${
                  checkExpireData?.dataObj?.status === true
                    ? "justify-between"
                    : "justify-end"
                } items-center p-[11px]`}
              >
                {" "}
                <p>
                  Last Update:{" "}
                  {convertTimeStampToDateTime(
                    checkExpireData?.dataObj?.key_refreshed_at
                  )}
                </p>
                <SpinnerButton
                  className={`btn-blue `}
                  title={`Associate Location`}
                  action={() => {
                    saveAssociateLocation();
                  }}
                  type={"button"}
                  loading={associateLocationLoading}
                  isDisable={buttonDisable}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isLoading && <GuestWifiLoader isModalOpen={isLoading} message="" />}
      {googleLocationLoading && (
        <div className=" flex justify-center items-center">
          {" "}
          <GuestWifiLoader
            isModalOpen={googleLocationLoading}
            message="Please wait a while, Fetching Details..."
          />
        </div>
      )}
    </div>
  );
}
