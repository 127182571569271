import { campaignTypeForList } from "module/data";
import { isValidArray } from "module/util";

const TopCampaigns = ({ topCampaigns = [] }) => {
  return (
    <div className="">
      <div className="text-lg font-semibold text-gray-700 border-b-2 border-gray-300 leading-10">
        <span className="">Top Campaigns</span>{" "}
      </div>
      <div className="mt-4 flow-root overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <table className="w-full">
            <thead className="bg-gray-200">
              <tr className="px-2">
                <th
                  scope="col"
                  className="relative isolate py-2 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Campaign Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-2 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-2 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  ROI
                </th>
              </tr>
            </thead>
            <tbody>
              {isValidArray(topCampaigns) ? (
                topCampaigns?.map((campaign) => (
                  <tr key={campaign?.campaign_id ?? ""}>
                    <td className="relative py-2 pr-3 text-sm font-medium text-gray-800">
                      {campaign?.name ?? ""}
                    </td>
                    <td className="hidden px-3 py-2 text-sm text-gray-800 sm:table-cell">
                      {campaignTypeForList[campaign?.type] ?? ""}
                    </td>
                    <td className="hidden px-3 py-2 text-sm text-gray-800 md:table-cell">
                      {campaign?.roi ?? ""}
                    </td>
                  </tr>
                ))
              ) : (
                <tr key={1} className="text-center">
                  <td
                    colSpan={3}
                    className="relative py-2 pr-3 text-sm font-medium text-gray-900"
                  >
                    No Data...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopCampaigns;
