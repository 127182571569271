import dummyImage from "assets/images/dummyImage.svg";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { API_URL } from "module/api";
import { campaignTypeForList } from "module/data";
import {
  changeDateFormat,
  dateDiff,
  getCampaignImage,
  getCurrentDate,
  isValidObject,
  parseDate,
} from "module/util";
import { useState } from "react";

const CampaignsSummary = (props) => {
  const {
    getCampaignDetailData = {},
    loading = false,
    campaignType = "",
    barCodeImgUrl = "",
  } = props;
  const [downloadLoading, setLoading] = useState(false);

  const totalDays = isValidObject(getCampaignDetailData)
    ? dateDiff(
        parseDate(getCampaignDetailData?.start_date),
        parseDate(getCampaignDetailData?.end_date)
      ) + 1
    : 0;

  const currentDays = isValidObject(getCampaignDetailData)
    ? dateDiff(
        parseDate(getCampaignDetailData?.start_date),
        parseDate(getCurrentDate())
      )
    : 0;

  const isDateGrater =
    Math.sign(currentDays) === 1 || Math.sign(currentDays) === 0;

  const daysInMarket = isDateGrater ? currentDays + 1 : 0;
  const daysLeft = isDateGrater ? totalDays - currentDays : totalDays;

  const handleDownloadCsvFile = () => {
    setLoading(true);
    var a = document.createElement("a");
    a.setAttribute(
      "href",
      `${API_URL}storage/documents/${getCampaignDetailData?.file_export}`
    );
    a.setAttribute("download", "newFile");
    a.setAttribute("target", "_blank");
    a.click();
    setLoading(false);
  };

  const handleDownloadFile = () => {
    setLoading(true);

    var a = document.createElement("a");
    a.setAttribute(
      "href",
      `${API_URL}download-barcode/${getCampaignDetailData?.barcode_file}`
    );
    a.setAttribute("download", getCampaignDetailData?.barcode_file);
    a.setAttribute("target", "_blank");
    a.click();

    setLoading(false);
  };

  return (
    <div className="flex bg-white p-3 gap-x-4">
      <div className="flex-1 max-w-[60%]">
        <p className="text-xl font-semibold">CAMPAIGNS SUMMARY</p>

        <p className="mt-10">
          <span className="text-md font-semibold">NAME :</span>{" "}
          {loading ? (
            <CustomerTextLoader />
          ) : (
            <span>{getCampaignDetailData?.name}</span>
          )}
        </p>
        <p className="mt-4">
          <span className="text-md font-semibold">TYPE :</span>{" "}
          {loading ? (
            <CustomerTextLoader />
          ) : (
            <span>
              {campaignTypeForList[getCampaignDetailData?.type] ??
                getCampaignDetailData?.type}
            </span>
          )}
        </p>
        <div className="w-full flex flex-wrap justify-between mt-4">
          <div className="flex-1 w-[50%]">
            <span className="text-md font-semibold">START DATE :</span>{" "}
            {loading ? (
              <CustomerTextLoader />
            ) : (
              <span>
                {changeDateFormat(getCampaignDetailData?.start_date ?? "")}
              </span>
            )}
          </div>
          <div className="flex-2 w-[50%]">
            <span className="text-md font-semibold">END DATE :</span>{" "}
            {loading ? (
              <CustomerTextLoader />
            ) : (
              <span>
                {changeDateFormat(getCampaignDetailData?.end_date ?? "")}
              </span>
            )}
          </div>
        </div>
        <div className="w-full flex flex-wrap mt-4">
          <div className="flex-1 w-[50%]">
            <span className="text-md font-semibold">DAYS IN MARKET :</span>{" "}
            {loading ? <CustomerTextLoader /> : <span>{daysInMarket}</span>}
          </div>
          <div className="flex-2 w-[50%]">
            <span className="text-md font-semibold">DAYS LEFT :</span>
            {loading ? (
              <CustomerTextLoader />
            ) : (
              <span>
                {" ~"}
                {daysLeft && Math.sign(daysLeft) === 1 ? daysLeft : 0}
              </span>
            )}
          </div>
        </div>
        {["direct_mail"].includes(campaignType) && (
          <div className="mt-8">
            <SpinnerButton
              className="btn-pink"
              title={"Download CSV"}
              action={handleDownloadCsvFile}
              type={"button"}
              loading={downloadLoading}
            />
          </div>
        )}

        {["print_ad"].includes(campaignType) && (
          <div className="mt-8">
            <div className="mt-2 flex flex-wrap items-center justify-start gap-x-2 ">
              {isValidObject(getCampaignDetailData) && (
                <img
                  alt="barcode"
                  src={barCodeImgUrl}
                  className="qvt-sheet-container border-white border-2 w-[480px] h-[80px]"
                />
              )}

              <SpinnerButton
                className="btn-pink"
                title={"Download"}
                action={() => handleDownloadFile()}
                type={"button"}
                loading={downloadLoading}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex-2 max-w-[40%] w-full flex justify-end">
        {loading ? (
          <div className="bg-gray-200 animate-pulse object-fill w-full h-[300px] flex flex-col gap-y-2 items-center justify-center">
            <img
              className="w-14 h-14"
              alt="Campaign Thumbnail"
              src={dummyImage}
            />
          </div>
        ) : (
          <>
            {getCampaignDetailData?.mailer_upload?.includes(".") ? (
              <div className="w-[640px] h-[360px]">
                <img
                  className="object-contain w-full h-full"
                  alt="Campaign Thumbnail"
                  src={getCampaignImage(getCampaignDetailData?.mailer_upload)}
                />
              </div>
            ) : (
              <div className="bg-gray-200 object-fill w-full h-[300px] flex flex-col gap-y-2 items-center justify-center">
                <p className="text-center">IMAGE NOT AVAILABLE</p>
                <img
                  className="w-14 h-14"
                  alt="Campaign Thumbnail"
                  src={dummyImage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CampaignsSummary;

const CustomerTextLoader = () => (
  <span
    style={{ visibility: "none" }}
    className="bg-slate-300 animate-pulse w-10 h-6"
  >
    <span className="opacity-0">Loading..........................</span>
  </span>
);
