export default function GuestWifiAuthForm({ queryParams = {}, formRef = {} }) {
  return (
    <form
      className=""
      action={`https://${decodeURIComponent(queryParams?.post)}/cgi-bin/login`}
      method="POST"
      id="form1"
      ref={formRef}
      name="form1"
    >
      <input name="user" value="user1" type="hidden" />
      <input name="password" value="pass1" type="hidden" />
      <input name="cmd" value="authenticate" type="hidden" />
      <input name="session_timeout" type="hidden" />
    </form>
  );
}
