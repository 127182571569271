import { Formik } from "formik";

const DemographicsForm = (props) => {
  const { getCustomerDetailData = {} } = props;
  const leadsData = getCustomerDetailData?.demographics ?? {};

  const handleChildrenSeniorPresentValue = (value = "") => {
    const newValue = !!value ? value.toLocaleLowerCase() : value;
    if (newValue === "y") {
      return "Yes";
    } else if (newValue === "n") {
      return "No";
    }
    return newValue;
  };

  const initialValues = {
    income: leadsData?.income ?? "Unknown",
    ageRange: leadsData?.age ?? "Unknown",
    maritalStatus: leadsData?.marital_status ?? "Unknown",
    homeOwner: leadsData?.owner_renter ?? "Unknown",
    gender: leadsData?.gender ?? "Unknown",
    children: handleChildrenSeniorPresentValue(
      leadsData?.children_present ?? "Unknown"
    ),
    houseHoldSize: leadsData?.no_of_people ?? "Unknown",
    lengthInHome: leadsData?.length_in_residence ?? "Unknown",
    status: leadsData?.status ?? "Unknown",
    homeType: leadsData?.home_type ?? "Unknown",
    seniorPresent: handleChildrenSeniorPresentValue(
      leadsData?.seniors_present ?? "Unknown"
    ),
    latLong: leadsData?.lat_long ?? "Unknown",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {}}
      onSubmit={() => {}}
      enableReinitialize={true}
      validateOnBlur={false}
    >
      {({ values }) => (
        <>
          <form className="w-full min-w-xl border border-gray-200 p-3 bg-white">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">Income :</span>
                <span className="font-light ml-2">{values?.income ?? ""}</span>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <span className="text-[15px] font-semibold">Home Type :</span>
                <span className="font-light ml-2">
                  {values?.homeType ?? ""}
                </span>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">Age :</span>
                <span className="font-light ml-2">
                  {values?.ageRange ?? ""}
                </span>
              </div>
              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">Homeowner :</span>
                <span className="font-light ml-2">
                  {values?.homeOwner ?? ""}
                </span>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                <span className="text-[15px] font-semibold">Gender :</span>
                <span className="font-light ml-2">{values?.gender ?? ""}</span>
              </div>

              <div className="w-full md:w-1/2 px-3">
                <span className="text-[15px] font-semibold">
                  Household Size :
                </span>
                <span className="font-light ml-2">
                  {values?.houseHoldSize ?? ""}
                </span>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">Children :</span>
                <span className="font-light ml-2">
                  {values?.children ?? ""}
                </span>
              </div>

              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">
                  Length in Home :
                </span>
                <span className="font-light ml-2">
                  {values?.lengthInHome ?? ""}
                </span>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                <span className="text-[15px] font-semibold">
                  Senior Present :
                </span>
                <span className="font-light ml-2">
                  {values?.seniorPresent ?? ""}
                </span>
              </div>

              <div className="w-full md:w-1/2  px-3">
                <span className="text-[15px] font-semibold">
                  Lat. & Long. :
                </span>
                <span className="font-light ml-2">{values?.latLong ?? ""}</span>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                <span className="text-[15px] font-semibold">
                  Marital Status :
                </span>
                <span className="font-light ml-2">
                  {values?.maritalStatus ?? ""}
                </span>
              </div>
            </div>
          </form>
          <div className="flex flex-wrap -mx-3 mt-2">
            <div className="w-full flex  px-3">
              <span className="text-[15px] text-red-600 font-semibold">
                DISCLAIMER:
              </span>
              <span className="font-light text-black text-[14px] ml-2">
                Pendio utilizes third-party data sources and an advanced AI
                system for customer and lead demographic matching. While we
                strive for optimal accuracy, mismatches may occur. This data is
                provided to complement and enhance performance and should not be
                solely relied upon for decision-making.
              </span>
              <span className="font-light ml-2">{""}</span>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default DemographicsForm;
