import GuestWifiLoader from "components/front/GuestWifiLoader";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ToastIntegration({
  integration,
  currentLocationId = "",
  currentCompanyId = "",
}) {
  const [isLoading, setIsLoading] = useState();
  const [inputValue, setInputValue] = useState({
    id: "",
    secret: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const settingRedirect =
    searchParams.get("redirect") === "true"
      ? true
      : searchParams.get("redirect") === "false"
      ? false
      : null;

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, [1000]);
  }, [currentLocationId]);

  return (
    <div className="w-full py-4">
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn-pink w-20"
          onClick={() => {
            navigate(`/user-settings?tab=integrations`);
          }}
        >
          Back
        </button>
        <button
          type="button"
          className={`btn-green`}
          disabled={!settingRedirect}
        >
          Refresh Key @
        </button>
      </div>

      <div className="mt-2 flex flex-col xl:flex-row gap-4">
        <div
          className={`bg-white border-2 border-gray-400 2xl:w-[25%] xl:w-[40%] w-full flex flex-col justify-between items-center`}
        >
          <div className="p-4 flex flex-col gap-y-4 justify-center items-center">
            <div className="">
              <img className="h-16 w-24" src={integration?.image} />
            </div>
            <p className="text-md font-semibold text-gray-700">{`Toast`}</p>
            <div>
              <p className="text-md text-gray-700">
                {integration?.description}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white border-2 border-gray-400 2xl:w-[80%] xl:w-[75%] w-full flex flex-col justify-between">
          <div className="flex flex-col gap-6">
            <div className="p-4">
              <p className="text-md font-semibold text-gray-700">
                {`Connect to your Toast account`}
              </p>
              <div>
                <p className="text-md text-gray-700">
                  {integration?.instruction}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <GuestWifiLoader
          isModalOpen={isLoading}
          message="Please wait a while, Fetching Details..."
        />
      )}
    </div>
  );
}
