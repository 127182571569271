import classNames from "classnames";
import { leadsColumnObj } from "module/data";
import {
  checkValueExists,
  getKeyByValue,
  isValidArray,
  showErrorMsg,
} from "module/util";
import { useEffect } from "react";

const ColumnMappingForm = (props) => {
  const {
    csvData,
    setSelectedData,
    selectedData,
    mappingColumnsArray = [],
  } = props;

  const dataArray = isValidArray(csvData) ? csvData : [];
  const headerArray = dataArray[0];
  const lowercaseHeaderArray = headerArray?.map((word) => word.toLowerCase());

  const matchColumnByArray = (dataArray = []) => {
    let columnIndex = "";
    dataArray.forEach((item) => {
      if (lowercaseHeaderArray?.includes(item)) {
        columnIndex = lowercaseHeaderArray?.indexOf(item);
      }
    });
    return columnIndex;
  };

  const handleSetInitColumn = (itemId = 0) => {
    if (itemId === "first_name") {
      return matchColumnByArray([
        "first name",
        "firstname",
        "fname",
        "first_name",
      ]);
    } else if (itemId === "last_name") {
      return matchColumnByArray([
        "last name",
        "lastname",
        "lname",
        "last_name",
      ]);
    } else if (itemId === "middle_name") {
      return matchColumnByArray(["middle name", "middlename", "mname"]);
    } else if (itemId === "full_name") {
      return matchColumnByArray(["full name", "fullname"]);
    } else if (itemId === "address1") {
      return matchColumnByArray(["address", "street address"]);
    } else if (itemId === "zip") {
      return matchColumnByArray(["postal code", "zip"]);
    } else if (itemId === "age") {
      return matchColumnByArray(["ageintwoyearincrements", "age"]);
    } else if (itemId === "income") {
      return matchColumnByArray(["incomenarrowranges", "income"]);
    } else if (itemId === "marital_status") {
      return matchColumnByArray(["martialstatus", "marital status"]);
    } else if (itemId === "length_in_residence") {
      return matchColumnByArray([
        "householdlengthofresidence",
        "length_in_residence",
      ]);
    } else if (itemId === "children_present") {
      return matchColumnByArray(["childrenpresent", "kids"]);
    } else if (itemId === "seniors_present") {
      return matchColumnByArray(["senioradultinhousehold", "seniors present"]);
    } else if (itemId === "owner_renter") {
      return matchColumnByArray(["ownerrenter", "owner renter"]);
    } else if (itemId === "no_of_people") {
      return matchColumnByArray(["householdsize", "no of people"]);
    } else if (itemId === "home_type") {
      return matchColumnByArray(["homepropertytype", "home_type"]);
    } else if (itemId === "user_id") {
      return matchColumnByArray(["customer_id", "Customer id", "customer id"]);
    } else {
      return lowercaseHeaderArray?.includes(itemId)
        ? lowercaseHeaderArray?.indexOf(itemId)
        : "";
    }
  };

  const setInitialColums = () => {
    let tempData = {};
    mappingColumnsArray.forEach((item) => {
      tempData = {
        ...tempData,
        [item.id]: handleSetInitColumn(item.id),
      };
    });
    setSelectedData(tempData);
  };

  useEffect(() => {
    setInitialColums();
  }, [csvData]);

  return (
    <div
      className={classNames("p-3 w-full flex flex-wrap ", {
        "opacity-50 pointer-events-none": !isValidArray(csvData),
      })}
    >
      <span className="text-white font-semibold w-full text-center mb-2 bg-red-500 ">
        Please select columns according to csv file.
      </span>
      {mappingColumnsArray.map((column, index) => (
        <div className="flex w-full md:w-1/2 flex-wrap mb-2" key={column.id}>
          <div className="flex-1 flex items-center px-3 mb-6 md:mb-0">
            <span className="text-md">{column.name}</span>
          </div>
          <div className="flex-2 min-w-[10%] w-full px-3 mb-6 md:mb-0">
            <div className="mt-2">
              <select
                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                name="company"
                value={selectedData[column.id]}
                onChange={(e) => {
                  const isSelected = getKeyByValue(
                    selectedData,
                    e.target.value
                  );

                  if (!isSelected) {
                    setSelectedData({
                      ...selectedData,
                      [column.id]: e.target.value,
                    });
                  } else {
                    const colName =
                      e?.target?.selectedOptions?.item("data-name")?.dataset
                        ?.name ?? "This option";
                    showErrorMsg(
                      `${colName ?? "This option"} is already selected`
                    );
                  }
                }}
              >
                <option key={1} value={""}>
                  Please Select
                </option>
                {isValidArray(headerArray) &&
                  headerArray.map((item) => {
                    return (
                      <option
                        key={item}
                        data-name={item}
                        value={headerArray?.indexOf(item)}
                        defaultValue={""}
                      >
                        {item}
                      </option>
                    );
                    // }
                  })}
              </select>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColumnMappingForm;
