import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getCompanyStatus,
  showErrorMsg,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTag } from "redux/companySetting/addTagSlice";
import { deleteTag } from "redux/companySetting/deleteTagSlice";
import { getTagDetail } from "redux/companySetting/getTagDetailSlice";
import { getTagsList } from "redux/companySetting/getTagsListSlice";
import { updateTag } from "redux/companySetting/updateTagSlice";
import * as Yup from "yup";

export default function Tags({ currentLocationId, currentCompanyId }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    pageLoading: "",
    tableLoading: "",
  });
  const [tagId, setTagId] = useState({ editTagId: "", deleteTagId: "" });
  const [sortIndex, setSortIndex] = useState(12);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [linkedContacts, setLinkedContacts] = useState(0);
  const totalRecords = 100;
  const currentPerPage = showPerPage;
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const tagsList = useSelector(
    (state) => state?.getTagsList?.data?.dataObj?.data
  );
  const tagDetail = useSelector((state) => state?.getTagDetail?.data?.dataObj);

  const tagsListLoading = useSelector((state) => state?.getTagsList?.loading);
  const addTagloading = useSelector((state) => state?.addTag?.loading);
  const updateTagLoading = useSelector((state) => state?.updateTag?.loading);
  const deleteTagLoading = useSelector((state) => state?.deleteTag?.loading);

  const action = [
    {
      name: <PencilSquareIcon title={"Edit"} className="w-[1.3rem]" />,
      event: (id) => {
        setTagId({ editTagId: id });
        const formData = {
          tag: id,
        };
        dispatch(getTagDetail(formData)).then((data) => {
          if (data?.payload.code === 200) {
            ("");
          } else {
            showErrorMsg(data);
          }
        });
      },
    },
    {
      name: <TrashIcon title={"Delete"} className="w-[1.3rem]" />,
      event: (id) => {
        setIsConfirmationModalOpen(true);
        setTagId({ ...tagId, deleteTagId: id });
      },
    },
  ];

  const columns = [
    {
      Header: "Name",
      accessor: "tag",
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ cell }) => {
        return (
          <div
            className={`${
              tagId?.editTagId === cell.row.values?.id && `opacity-50`
            }`}
          >
            {cell?.row?.values?.description
              ? cell?.row?.values?.description
              : "No Details"}
          </div>
        );
      },
    },
    {
      Header: "Linked Contacts",
      accessor: "total_association_count",
    },
    ...(isCompanyActive
      ? [
          {
            Header: "Action",
            accessor: "id",
            Cell: ({ cell }) => (
              <div className="flex  items-center gap-x-[4px]">
                {action?.map((action, actionIndex) => (
                  <div key={actionIndex}>
                    <button
                      onClick={() => {
                        setLinkedContacts(
                          cell.row.values?.total_association_count
                        );
                        action.event(cell.row.values?.id);
                      }}
                      className={`hover:text-gray-900 hover:underline 
                ${
                  tagId?.editTagId === cell.row.values?.id &&
                  `opacity-50 cursor-not-allowed`
                }
                `}
                      disabled={tagId?.editTagId === cell.row.values?.id}
                    >
                      {action.name}
                    </button>
                  </div>
                ))}
              </div>
            ),
          },
        ]
      : []),
  ];

  const initialValues = {
    tag: tagId?.editTagId ? tagDetail?.tag || "" : "",
    description: tagId?.editTagId ? tagDetail?.description || "" : "",
    color: tagId?.editTagId ? tagDetail?.hex || "" : "",
  };

  const handleSubmit = (value, { resetForm }) => {
    if (!value.tag.trim()) {
      showErrorMsg("Tag cannot be blank");
      return;
    }

    const formData = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      tag: value?.tag,
      description: value?.description,
      hex: value?.color,
    };
    if (tagId?.editTagId) {
      dispatch(updateTag({ ...formData, id: tagId?.editTagId })).then(
        (data) => {
          if (data?.payload.code === 200) {
            handleTagList();
            setTagId({ editTagId: null });
            resetForm();
            showSuccessMsg(
              data?.payload?.message ?? "Tag Updated Successfully."
            );
            Form;
          } else {
            const errorMsg = data?.payload?.exception?.errors?.tag[0];
            showErrorMsg(errorMsg);
          }
        }
      );
    } else {
      dispatch(addTag(formData)).then((data) => {
        if (data?.payload.code === 201) {
          handleTagList();
          showSuccessMsg(data?.payload?.message ?? "Tag Added Successfully.");
          resetForm();
        } else {
          const errorMsg = data?.payload?.exception?.errors?.tag[0];
          showErrorMsg(errorMsg);
        }
      });
    }
  };

  const handleDeleteTag = () => {
    const isForcedAction = linkedContacts === 0 ? false : true;

    const formData = {
      tag: tagId?.deleteTagId,
    };
    dispatch(deleteTag({ formData, isForcedAction })).then((data) => {
      setIsConfirmationModalOpen(false);
      if (data?.payload.code === 200) {
        handleTagList();
        showSuccessMsg(data?.payload?.message ?? "Tag Deleted Successfully.");
      } else {
        showErrorMsg(
          data?.payload?.dataObj?.message ??
            "Tag is linked with contact. Please unlink the tag."
        );
      }
    });
  };

  const handleTagList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getTagsList(formData));
  };

  useEffect(() => {
    handleTagList();
  }, [currentLocationId]);

  useEffect(() => {
    setIsLoading({ pageLoading: true });
    setTimeout(() => {
      setIsLoading({ pageLoading: false });
    }, [1000]);
  }, []);

  return (
    <div
      className={classNames(
        "flex mt-4 border gap-x-4 border-gray-200 p-3 pb-10 bg-white w-full"
      )}
    >
      <div className="block 2xl:flex w-full  mt-3 gap-4">
        {isLoading?.pageLoading ? (
          <div
            role="status"
            className={`w-full 2xl:w-[62%] space-y-4 rounded animate-pulse`}
          >
            {[...Array(4)]?.map((_, arrayIndex) => {
              return (
                <div
                  key={arrayIndex}
                  className="w-full items-center px-3 mb-3 "
                >
                  <div
                    className={`${
                      arrayIndex === 2 ? `h-24` : `h-8`
                    }  bg-gray-300`}
                  ></div>
                </div>
              );
            })}

            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div
            className={`w-full tems-center  px-3 mb-3 ${
              !isCompanyActive && `pointer-events-none opacity-60`
            }`}
          >
            <div className="text-lg font-semibold text-gray-700 mb-4">Tag</div>
            <Formik
              enableReinitialize={true}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, { resetForm });
              }}
              validationSchema={Yup.object({
                tag: Yup.string()
                  .trim()
                  .matches(/\S+/, "Tag cannot be blank.")
                  .required("Tag required."),
              })}
              initialValues={initialValues}
            >
              <Form className="w-full" id="form1" name="form1">
                <div className="flex flex-start w-full gap-1">
                  <label
                    className="font-light leading-6 text-gray-900 w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
                    htmlFor="tag"
                  >
                    Tags :
                  </label>
                  <div className="w-full">
                    <Field
                      className={`input-field`}
                      id="tag"
                      name="tag"
                      type="text"
                      placeholder="Tag Name..."
                      readOnly={!isCompanyActive}
                    ></Field>
                    {isCompanyActive && (
                      <ErrorMessage
                        component={"div"}
                        className="text-red-500"
                        name={"tag"}
                      />
                    )}
                  </div>
                </div>

                <div className="flex w-full mt-2 gap-2">
                  <label
                    className="font-light leading-6 text-gray-900 w-[30%] md:w-[15%] xl:w-[11.3%] 2xl:w-[22%]"
                    htmlFor="description"
                  >
                    Description :
                  </label>
                  <Field
                    as="textarea"
                    rows={5}
                    className={`input-field`}
                    id="description"
                    name="description"
                    type="text"
                    placeholder="About Tag..."
                    readOnly={!isCompanyActive}
                  />
                </div>

                {isCompanyActive && (
                  <div className="mt-4 flex gap-2">
                    <SpinnerButton
                      className="btn-pink w-20"
                      title={tagId?.editTagId ? `Save` : `Add`}
                      action={() => {}}
                      type={"submit"}
                      loading={
                        tagId?.editTagId ? updateTagLoading : addTagloading
                      }
                    />
                    {tagId?.editTagId && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-light-gray"
                          onClick={() => {
                            setTagId({ editTagId: null });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        )}

        <div className="block 2xl:hidden w-full h-[1px] bg-gray-300 my-6"></div>

        {isLoading?.pageLoading ? (
          <div
            role="status"
            className={`w-full space-y-4 rounded animate-pulse`}
          >
            {[...Array(2)]?.map((_, arrayIndex) => {
              return (
                <div
                  key={arrayIndex}
                  className="w-full items-center px-3 mb-3 "
                >
                  <div
                    className={`${
                      arrayIndex === 1 ? `h-48` : `h-8`
                    }  bg-gray-300`}
                  ></div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full">
            <span className="text-lg font-semibold text-gray-700">
              Tag List
            </span>
            <div className="custom-scrollbar mt-4 flex flex-col 2xl:max-h-[25rem]">
              <CustomTable
                data={tagsList ?? []}
                isLoading={tagsListLoading}
                columns={columns}
                defaultSortIndexColumn={sortIndex}
                defaultOrder={sortOrder}
                setSortIndex={setSortIndex}
                setSortOrder={setSortOrder}
                totalRecords={totalRecords}
                perPage={perPage ? perPage : currentPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPerPage={setPerPage}
                isPaginationHide={true}
                colSpan={12}
              />
            </div>
          </div>
        )}
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={handleDeleteTag}
          isLoading={deleteTagLoading}
          message={
            linkedContacts !== 0
              ? `This tag is linked with ${linkedContacts} contact(s). Please confirm if you want to delete tag.`
              : "You want to delete this tag ?"
          }
        />
      )}
    </div>
  );
}
