import ReactApexChart from "react-apexcharts";

const GenderGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.gender ?? {};

  const seriesArray = [
    graphDetails?.["Male"]?.[0]?.percent ?? 0,
    graphDetails?.["Female"]?.[0]?.percent ?? 0,
    graphDetails?.["Unisex"]?.[0]?.percent ?? 0,
  ];

  const config = {
    series: seriesArray,
    options: {
      chart: {
        height: 300,
        type: "donut",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb", "#a5d583", "#f35288"],
      labels: ["Male", "Female", "Unisex"],
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="donut"
        height={300}
      />
    </div>
  );
};

export default GenderGraph;
