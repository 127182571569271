import barcodeImg from "assets/images/barcode.png";
import { getDateFormatLocal } from "module/util";
import { useEffect, useRef, useState } from "react";
import Moveable from "react-moveable";

const MovableBarcode = (props) => {
  const {
    image,
    targetFrames,
    setTargetFrames,
    frameId,
    setFrameId,
    barcodeRef,
    expirationDateRef,
  } = props;
  const [target, setTarget] = useState();
  const [dragTarget, setDragTarget] = useState();
  const imageRef = useRef(null);

  useEffect(() => {
    setDragTarget(document.querySelector(".main-moveable"));
  }, []);

  useEffect(() => {
    if (target && frameId) {
      target.style.left = `${targetFrames?.[frameId]?.translate[0]}px`;
      target.style.top = `${targetFrames?.[frameId]?.translate[1]}px`;
    }
  }, [targetFrames]);

  const getTargetId = (target) => target.getAttribute("data-target");

  const updateTarget = (target) => {
    const targetId = getTargetId(target);
    if (targetFrames[targetId]) {
      setFrameId(targetId);
      setTarget(target);
    } else {
      setTarget(null);
      setFrameId("");
    }
  };

  return (
    <div
      className="main-moveable relative border border-gray-600 h-[1200px] bg-gray-300 w-[1600px]"
      onClick={(e) => {
        updateTarget(e.target);
      }}
    >
      {image && (
        <img
          ref={imageRef}
          alt="Campaign Image"
          src={
            image && typeof image === "string"
              ? image
              : URL.createObjectURL(image)
          }
          className="object-fill h-full w-full"
        />
      )}
      <img
        data-target="barcode"
        ref={barcodeRef}
        src={barcodeImg}
        style={{
          cursor: frameId === "barcode" ? "move" : "pointer",
          position: "absolute",
          width: "820px",
          height: "136px",
          top: "150px",
        }}
        alt="bar-code"
      />

      <span
        data-target="expirationDate"
        ref={expirationDateRef}
        style={{
          position: "absolute",
          cursor: frameId === "expirationDate" ? "move" : "pointer",
          top: "0px",
          left: "0px",
        }}
        className="font-semibold text-[30px] leading-[30px]"
      >
        {getDateFormatLocal()
          ? getDateFormatLocal()?.toUpperCase() ?? "MM/DD/YYYY"
          : ""}
      </span>

      <Moveable
        target={target}
        dragTarget={dragTarget}
        draggable={true}
        throttleResize={0}
        throttleDrag={0}
        throttleRotate={0}
        startDragRotate={0}
        throttleDragRotate={0}
        snappable={true}
        bounds={{ left: 0, top: 0, right: 1600, bottom: 1200 }}
        zoom={1}
        origin={false}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onDragStart={({ set }) => {
          set(targetFrames[frameId].translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          let frames = Object.assign({}, targetFrames);
          frames[frameId].translate = [...beforeTranslate];
          setTargetFrames(frames);
          target.style.left = `${beforeTranslate[0]}px`;
          target.style.top = `${beforeTranslate[1]}px`;
        }}
      />
    </div>
  );
};

export default MovableBarcode;
