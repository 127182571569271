import { useEffect, useState } from "react";

const ProgressBar = ({ stars }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (percent < 100) {
      setTimeout(() => setPercent(stars ?? 0), 200);
    }
  }, [percent, stars]);

  return (
    <div className="w-[80%] h-3.5  rounded-2xl bg-gray-200 mb-1 ">
      <div
        className={`h-full rounded-2xl bg-[#38d4ea] transition-width duration-500 ease-out`}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
