import { NumericFormat } from "react-number-format";

const CustomCostInput = (props) => {
  const {
    values = "",
    setFieldValue,
    name = "",
    title = "",
    onBlur = () => {},
  } = props;
  return (
    <div className="relative mt-2">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-gray-500 sm:text-sm">$</span>
      </div>
      <NumericFormat
        name={name}
        value={values}
        decimalScale={2}
        fixedDecimalScale={true}
        onValueChange={({ value }) => {
          setFieldValue(name, value);
        }}
        onBlur={onBlur}
        className="input-field pl-7 pr-12"
        placeholder={`Enter ${title}`}
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span className="text-gray-500 sm:text-sm" id="price-currency">
          USD
        </span>
      </div>
    </div>
  );
};

export default CustomCostInput;
