import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const removeCustomerFromLead = createAsyncThunk(
  "campaign/remove-customer",
  async (formDataObj, { rejectWithValue }) => {
    const { remove_customer, location_id, lead_list_id, send_to } = formDataObj;

    let queryString = `remove_customer=${remove_customer}&location_id=${location_id}&send_to=${send_to}`;
    if (lead_list_id) {
      queryString = `${queryString}&lead_list_id=${lead_list_id}`;
    }
    try {
      const response = await getApi(`api/modifyleadcount?${queryString}`);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const removeCustomerFromLeadSlice = createSlice({
  name: "removeCustomerFromLead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeCustomerFromLead.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeCustomerFromLead.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(removeCustomerFromLead.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default removeCustomerFromLeadSlice.reducer;
