import GoogleMapComponent from "components/common/fields/GoogleMapComponent";
import { convertLatLongObj, getCurrentLocation } from "module/util";

const GoogleHeatMap = ({ getCampaignReportData, maxDensityPoint }) => {
  const { lat_long } = getCurrentLocation();
  const heatMapData = getCampaignReportData?.lat_long ?? [];
  const defaultLatLong = maxDensityPoint
    ? maxDensityPoint
    : lat_long
    ? convertLatLongObj(lat_long)
    : {
        lat: 32.640054,
        lng: -117.084195,
      };

  return (
    <GoogleMapComponent
      latLong={defaultLatLong}
      heatMapData={heatMapData}
      isHeatMap={true}
    />
  );
};

export default GoogleHeatMap;
