import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import { showPerPage, showSuccessMsg } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLocationStatus } from "redux/location/changeLocationStatusSlice";
import { locationList } from "redux/location/locationListSlice";

const LocationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalLocations, setTotalLocation] = useState([]);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(showPerPage);

  const [{ locationListData = {}, locationListLoading = false }] = useSelector(
    (state) => [
      {
        locationListData: state?.locationList?.data ?? {},
        locationListLoading: state?.locationList?.loading ?? false,
      },
    ]
  );

  const totalRecords = locationListData?.total ?? 0;

  const handleLocationList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
    };
    dispatch(locationList(sortObj)).then((data) => {
      if (data?.payload?.data) {
        setTotalLocation(data?.payload?.data);
      }
    });
  };

  const handleLocationStatusChange = (locationId, status) => {
    dispatch(changeLocationStatus({ locationId, status })).then(() => {
      showSuccessMsg("Location archived successfully.");
      handleLocationList(searchInput);
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <span
          className="hover:underline hover:cursor-pointer "
          onClick={() => navigate(`/locations/edit/${cell.row.values.id}`)}
        >
          {cell.row.values.name}
        </span>
      ),
    },
    {
      Header: "Address",
      accessor: "address1",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      width: 100,
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ cell }) => (
        <div className="flex items-center gap-x-2 ">
          <img
            alt="Edit"
            src={editIcon}
            className="h-5 w-5 hover:cursor-pointer"
            onClick={() => navigate(`/locations/edit/${cell.row.values.id}`)}
          />

          <img
            alt="archive"
            src={deleteIcon}
            className="h-5 w-5 hover:cursor-pointer"
            onClick={() =>
              handleLocationStatusChange(cell.row.values.id, "archived")
            }
          />
        </div>
      ),
    },
  ];

  const globalSearch = (search) => {
    handleLocationList(search);
  };

  useEffect(() => {
    handleLocationList(searchInput);
  }, [sortOrder, currentPage, perPage]);

  return (
    <>
      <div className="">
        <ShowPageTitle title="locations" />
        <div className="sm:flex  justify-end items-center gap-x-2">
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => navigate("/locations/add")}
              className="btn btn-gray"
            >
              New Location
            </button>
          </div>
          <div className="flex rounded-md shadow-sm">
            <SearchInput
              globalSearch={globalSearch}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <CustomTable
            data={totalLocations}
            columns={columns}
            isLoading={locationListLoading}
            defaultSortIndexColumn={sortIndex}
            defaultOrder={sortOrder}
            setSortIndex={setSortIndex}
            setSortOrder={setSortOrder}
            totalRecords={totalRecords}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPerPage={setPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default LocationList;
