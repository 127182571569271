import LoaderCommon from "components/common/fields/LoaderCommon";

const DashboardMatrix = ({
  dashboardData = {},
  dashboardDataLoading = false,
}) => {
  return (
    <div className="flex md:gap-y-2 sm:gap-y-2 mt-4 min-h-[112px] 2xl:justify-between xl:gap-x-4 lg:gap-x-2 md:gap-x-4 sm:gap-x-1">
      <div className="w-full bg-gradient-to-b from-[#f35288] to-[#f186ac] text-center p-3 border-2 border-gray-400">
        <p>TOTAL SENT</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2 text-center">
                {dashboardData?.totalSent ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
        <p>REDEEMED</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardData?.redeemed ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af]  text-center p-3 border-2 border-gray-400">
        <p>REVENUE</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardData?.revenue ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-center  p-3 border-2 border-gray-400">
        <p>CONVERSION</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardData?.conversions
                  ? `${Number(dashboardData?.conversions).toFixed(2)}%`
                  : "0%"}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-[#f35288] to-[#f186ac]  text-center  p-3 border-2 border-gray-400">
        <p>COST</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2 break-words">
                {dashboardData?.cost ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
        <p>ROI</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2 break-words">
                {dashboardData?.roi ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af]  text-center p-3 border-2 border-gray-400">
        <p>EROI</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardData?.eroi ?? 0}
              </p>
              <p className="text-green-700"></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardMatrix;
