import React from "react";
import Select from "react-select";

const MultiSelectDropdown = ({
  selectedOptions = [],
  options = [],
  onChange = () => {},
  name = "tags",
  value = [],
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: "#dcdcdc",
        color: "black",
      },
    }),
    multiValue: (provided, state) => {
      const option = options.find(
        (option) => option.value === state.data.value
      );

      const backgroundColor = "none";
      const border = "1px solid gray";
      return {
        ...provided,
        backgroundColor: backgroundColor,
        border: border,
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        backgroundColor: "transparent",
        color: "black",
      },
    }),
  };

  return (
    <Select
      defaultValue={selectedOptions}
      styles={customStyles}
      isMulti
      value={value ?? []}
      name={name}
      options={options}
      onChange={onChange}
      className="basic-multi-select w-full"
      classNamePrefix="select"
    />
  );
};

export default MultiSelectDropdown;
