import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";
import { showErrorMsg, showSuccessMsg } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postApi("api/auth/logout");
      if (response.data.code === 200) {
        showSuccessMsg("Logout successfully");
      }

      return response?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      if (error.response && error.response.data.message) {
        showErrorMsg(error.response.data.message);
        return rejectWithValue(error.response.data.message);
      } else {
        showErrorMsg(error.response.data.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = null;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default logoutSlice.reducer;
