import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

export default function TagListComponent({ selectedOptions }) {
  const [isOpen, setOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tagArray = selectedOptions?.map((item) => {
    return item.label;
  });
  const containerRef = useRef(null);

  useEffect(() => {
    const containerHeight = containerRef.current.clientHeight;
    setIsOverflowing(containerHeight > 60);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        "w-[230px] flex flex-wrap gap-y-1 relative select-none",
        {
          "max-h-[64px] overflow-hidden": !isOpen,
          "h-full ": isOpen,
        }
      )}
    >
      {isOverflowing && (
        <ChevronDownIcon
          className={classNames(
            "absolute right-0 top-0 cursor-pointer text-md text-black",
            {
              "rotate-180": isOpen,
              "rotate-0": !isOpen,
            }
          )}
          onClick={(e) => {
            setOpen(!isOpen);
          }}
          height={15}
          width={15}
        />
      )}
      {tagArray
        ?.sort((a, b) => a.localeCompare(b))
        ?.map((tags, tagIndex) => {
          const isLastTag = tagIndex === tagArray.length - 1;
          return (
            <span key={tagIndex} className="p-1">
              {tags}
              {!isLastTag && ", "}
            </span>
          );
        })}
    </div>
  );
}
