import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import ProcessingModal from "components/contacts/ProcessingModal";
import TagActionModal from "components/contacts/tags/TagActionModal";
import TagDropDownOptions from "components/contacts/tags/TagDropDownOptions";
import TagListComponent from "components/contacts/tags/TagListComponent";
import { API_URL } from "module/api";
import {
  formatPhoneNumberAlways,
  getShortCodeOfStates,
  isValidArray,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { exportCustomers } from "redux/customer/exportCustomersSlice";
import { deleteProspect } from "redux/leadList/deleteProspectSlice";
import RatingField from "../customer/RatingField";

const ProspectsList = ({
  isCompanyActive = false,
  currentCompanyId = "",
  currentLocationId = "",
  handleContactList = () => {},
  contactListData = {},
  contactListLoading = false,
  activeTab = "prospects",
  setSortIndex = () => {},
  sortIndex = 6,
  setSortOrder = () => {},
  sortOrder = 1,
  setCurrentPage = () => {},
  currentPage = 1,
  setPerPage = () => {},
  perPage = 0,
  setListColumns = () => {},
  listColumns = [],
  setExportContactModal = () => {},
  exportContactModal = false,
  setIsAllChecked = () => {},
  isAllChecked = false,
  setSelectType = () => {},
  selectType = { type: "NONE", page: [] },
  setSelectedRowIds = () => {},
  selectedRowIds = [],
  setUnSelectedRowIds = () => {},
  unSelectedRowIds = [],
  setTagModal = () => {},
  tagModalOpen = false,
  setTags = () => {},
  tags = [],
  setDeletedContactsModal = () => {},
  deletedContactsModal = false,
  setProcessingModal = () => {},
  processingModal = {
    isProcessingModalOpen: false,
    processType: "",
  },
  setSearchInput = () => {},
  searchInput = "",
  setContactList = () => {},
  contactList = { totalRecords: 0, data: [] },
  selectedContacts = 0,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState({
    id: 0,
    isAlreadyCustomer: false,
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const checkRef = useRef([]);

  const currentPerPage = contactListData?.per_page ?? showPerPage;
  const tagsOptions = TagDropDownOptions();

  const exportCustomersLoading = useSelector(
    (state) => state?.exportCustomers?.loading ?? false
  );

  const deleteProspectLoading = useSelector(
    (state) => state?.deleteProspect?.loading ?? false,
    shallowEqual
  );

  const handleDeleteProspects = () => {
    dispatch(deleteProspect({ id: selectedId.id })).then((data) => {
      setModalOpen(false);
      if (data?.payload.code === 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Prospect deleted successfully."
        );
        handleContactList(searchInput);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
      Cell: ({ cell }) => (
        <span
          className={`hover:underline hover:cursor-pointer ${
            !isCompanyActive && `pointer-events-none`
          }`}
          onClick={() =>
            navigate(
              `/contacts/edit/${cell.row.values?.id ?? 0}?type=${"prospect"}`,
              {
                state: "prospects",
              }
            )
          }
        >
          {cell.row.values?.first_name}
        </span>
      ),
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "address",
      accessor: "address1",
      Cell: ({ cell }) => (
        <>
          <p>{cell.row.original?.address1}</p>
          <span>{cell.row.original?.city}</span>
          {!!cell.row.original?.state && (
            <span>
              {", "} {getShortCodeOfStates(cell.row.original?.state)}
            </span>
          )}
          {!!cell.row.original?.zip && (
            <span>
              {", "} {cell.row.original?.zip}
            </span>
          )}
        </>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => {
        return formatPhoneNumberAlways(cell.row.values?.phone);
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },

    {
      Header: "Tags",
      accessor: "tags",
      Cell: ({ cell }) => {
        const selectedTags = !!cell.row.values?.tags
          ? JSON.parse(cell.row.values?.tags)
          : [];

        const selectedOptions = tagsOptions?.filter((item) => {
          if (isValidArray(selectedTags)) {
            return selectedTags.includes(item?.value);
          }
          return false;
        });

        return <TagListComponent selectedOptions={selectedOptions} />;
      },
    },
    {
      Header: "Score",
      accessor: "score",
      Cell: ({ cell }) => <RatingField rating={cell.row.values?.score ?? 0} />,
    },

    // {
    //   Header: "Campaign Name",
    //   accessor: "campaign",
    //   Cell: ({ cell }) => {
    //     const campaign = cell?.row?.values?.campaign
    //       ? cell?.row?.values?.campaign
    //       : {};
    //     return campaign?.name ?? "";
    //   },
    // },
    // {
    //   Header: "Registration Date",
    //   accessor: "created_at",
    //   Cell: ({ cell }) => {
    //     return cell?.row?.values?.created_at
    //       ? changeDateFormat(cell?.row?.values?.created_at)
    //       : "";
    //   },
    // },
    ...(isCompanyActive
      ? [
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ cell }) => (
              <div className="w-[100px] flex items-center gap-x-2 ">
                <img
                  alt="Edit"
                  src={editIcon}
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/contacts/edit/${
                        cell.row.values?.id ?? 0
                      }?type=${"prospect"}`,
                      {
                        state: "prospects",
                      }
                    )
                  }
                />
                <img
                  alt="archive"
                  src={deleteIcon}
                  title="Archive"
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() => {
                    setSelectedId({
                      id: cell.row.values?.id,
                      isAlreadyCustomer: cell.row.values?.customer_id
                        ? true
                        : false,
                    });
                    setModalOpen(true);
                  }}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleRowSelect = (selectedRows) => {
    checkRef.current = selectedRows;
  };

  const handleExportCustomers = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(exportCustomers(formData)).then((data) => {
      if (data?.payload?.dataObj) {
        const filePath = data?.payload?.dataObj?.file_path ?? "";
        showSuccessMsg(data?.payload?.dataObj?.message ?? "");
        handleDownloadCsvFile(filePath);
        setExportContactModal(false);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleDownloadCsvFile = (filePath = "") => {
    var a = document.createElement("a");
    a.setAttribute("href", `${API_URL}storage/${filePath}`);
    a.setAttribute("download", "newFile");
    a.setAttribute("target", "_blank");
    a.click();
  };

  const handleDeleteSelectedContacts = () => {
    setDeletedContactsModal(false);
    setProcessingModal({
      isProcessingModalOpen: true,
      processType: "DELETE",
    });
  };

  const handleAddTagToSelectedContacts = () => {
    setProcessingModal({
      isProcessingModalOpen: true,
      processType: "ADD_TAGS",
    });
  };

  useEffect(() => {
    handleContactList(searchInput);
  }, [sortOrder, currentPage, perPage, currentLocationId]);

  useEffect(() => {
    setSortIndex(6);
    setSortOrder(1);
    setCurrentPage(1);
    setPerPage(0);
    setListColumns(columns);
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: "Prospects",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <div>
      <ShowPageTitle title="contactsProspect" />
      <div className="mt-2 flex flex-col">
        <CustomTable
          data={
            isValidArray(contactListData?.dataObj?.data)
              ? contactListData?.dataObj?.data
              : []
          }
          columns={columns}
          isLoading={contactListLoading}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={contactList?.totalRecords ?? 0}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          colSpan={12}
          onRowSelect={handleRowSelect}
          isColumnSelectionRequired={true}
          setIsAllChecked={setIsAllChecked}
          isAllChecked={isAllChecked}
          setSelectedRowIds={setSelectedRowIds}
          selectedRowIds={selectedRowIds}
          setSelectType={setSelectType}
          selectType={selectType}
          setUnSelectedRowIds={setUnSelectedRowIds}
          unSelectedRowIds={unSelectedRowIds}
          isCompanyActive={isCompanyActive}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleDeleteProspects}
          isLoading={deleteProspectLoading}
          message={
            selectedId?.isAlreadyCustomer
              ? "Selected prospect is already a customer, Do you still want to delete the prospect?"
              : "You want to delete this prospect?"
          }
        />
      )}
      {exportContactModal && (
        <ConfirmationModal
          isModalOpen={exportContactModal}
          setModalOpen={setExportContactModal}
          handleAction={() => {
            handleExportCustomers();
          }}
          message={"Are you sure you want to export prospect(s) ?"}
          isLoading={exportCustomersLoading}
        />
      )}
      {deletedContactsModal && (
        <ConfirmationModal
          isModalOpen={deletedContactsModal}
          setModalOpen={setDeletedContactsModal}
          handleAction={() => {
            handleDeleteSelectedContacts();
          }}
          message={
            selectType?.type === `ALL`
              ? `Are you sure you want to delete all your contact(s) ?`
              : `Are you sure you want to delete ${selectedContacts} contact(s) ?`
          }
          isLoading={false}
        />
      )}
      {tagModalOpen && (
        <TagActionModal
          isModalOpen={tagModalOpen}
          setModalOpen={setTagModal}
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
          handleAction={() => {
            handleAddTagToSelectedContacts();
          }}
          tagsOptions={tagsOptions}
          setTags={setTags}
          tags={tags}
        />
      )}
      {processingModal?.isProcessingModalOpen && (
        <ProcessingModal
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          currentLocationId={currentLocationId}
          searchInput={searchInput}
          handleCustomerList={handleContactList}
          tags={tags}
          setProcessingModal={setProcessingModal}
          processingModal={processingModal}
          setSelectType={setSelectType}
          selectType={selectType}
          setIsAllChecked={setIsAllChecked}
          contactType={activeTab}
        />
      )}
    </div>
  );
};

export default ProspectsList;
