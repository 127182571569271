const Skeleton = ({ className, counter, height, type }) => {
  const skeletonItems = new Array(counter ? counter : 1).fill(0);
  return (
    <div className={`${className ? className : ""}`}>
      {skeletonItems?.map((item, index) => (
        <div
          key={index}
          role="status"
          className={`w-full   space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse  ${
            !type && "md:p-6 mb-4 p-4"
          } `}
          style={{ height: `${height ? height : 4}rem` }}
        >
          {!type ? (
            <div className="flex items-center justify-between w-full">
              <div className="w-full">
                <div className="h-6  bg-gray-300   w-[80%] mb-2.5"></div>
                <div className="w-[80%] h-6 bg-gray-300 mb-2.5  "></div>
                <div className="w-[80%] h-6 bg-gray-300  "></div>
              </div>
              <div className="h-6 bg-gray-300  w-14"></div>
            </div>
          ) : (
            <div
              className=" p-2  "
              style={{ height: `${height ? height : 4}rem` }}
            >
              <div className="flex items-center justify-between gap-2 w-full">
                <div className="w-[50%]">
                  <div className="h-56 bg-gray-300  w-full"></div>
                </div>

                <div className="w-[50%] ">
                  <div className=" h-6 bg-gray-300 mb-6"></div>
                  <div className=" h-6 bg-gray-300 mb-6"></div>
                  <div className=" h-6 bg-gray-300 mb-6"></div>
                  <div className=" h-6 bg-gray-300 mb-6"></div>
                  <div className=" h-6 bg-gray-300  "></div>
                </div>
              </div>
            </div>
          )}

          <span className="sr-only">Loading...</span>
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
