import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import SplashPreview from "components/companySettings/SplashPreview";
import copy from "copy-to-clipboard";
import { Formik } from "formik";
import {
  HOST_URL,
  getCompanyStatus,
  getUserDataByParam,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation, showError } from "module/validation";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { guestWifiOtpToggle } from "redux/companySetting/guestWifiOtpToggleSlice";
import { guestWifiToggle } from "redux/companySetting/guestWifiToggleSlice";
import { updateSessionTimeout } from "redux/companySetting/updateSessionTimeoutSlice";
import * as yup from "yup";

const GuestWIFI = ({
  currentLocationId = "",
  type = "",
  locationName = "",
  companyName = "",
  handleLocationDetail = () => {},
  getLocationDetailData = {},
}) => {
  const dispatch = useDispatch();
  const companyId = getUserDataByParam("COMPANY_ID");
  const [sessionTimeout, setSessionTimeout] = useState(60);
  const [sessionDelay, setSessionDelay] = useState(5);
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const isWifiEnabled =
    getLocationDetailData?.companyLocation?.guest_wifi == "1" ? true : false;

  const isOtpVerificationSkip =
    getLocationDetailData?.companyLocation?.skip_otp == "1" ? true : false;

  const timeout =
    getLocationDetailData?.companyLocation?.session_timeout ?? "60";

  const delay = getLocationDetailData?.companyLocation?.session_delay ?? "5";

  const updateSessionTimeoutLoading = useSelector(
    (state) => state?.updateSessionTimeout?.loading ?? false
  );

  const [isEnabled, setEnabled] = useState(false);
  const [isSkipOtp, setSkipOtp] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const connectURL = `${HOST_URL}/wifi/${currentLocationId}/${locationName}/${companyId}/${companyName}`;

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  useEffect(() => {
    setEnabled(isWifiEnabled);
    setSkipOtp(isOtpVerificationSkip);
    setSessionTimeout(timeout);
    setSessionDelay(delay);
  }, [getLocationDetailData]);

  const handleWifiToggle = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(guestWifiToggle(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Guest WIfi status updated successfully."
        );
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleSkipOtpVerification = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(guestWifiOtpToggle(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        handleLocationDetail();
        showSuccessMsg(
          data?.payload?.message ?? "Skip guest wIfi otp verification updated."
        );
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleUpdateSessionTimeout = (value) => {
    const timeout = value?.session_timeout ?? 60;
    setSessionTimeout(timeout);
    const delay = value?.session_delay ?? 5;
    setSessionDelay(delay);

    const formData = {
      location_id: currentLocationId,
      session_timeout: timeout,
      session_delay: delay,
    };
    dispatch(updateSessionTimeout(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Session timeout updated successfully."
        );
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <div className="bg-white border border-gray-200 2xl:flex justify-between gap-x-2 pt-4 pb-10 px-2 mt-4">
      <div
        className={`w-full 2xl:w-[50%] ${
          !isCompanyActive && `pointer-events-none opacity-60`
        }`}
      >
        <div className="mt-2 flex items-center">
          <span className="mr-4 text-md leading-6 font-light text-gray-900 w-44">
            Enable Guest WIFI :{" "}
          </span>
          <SwitchButton
            value={isEnabled}
            handleToggleForCampaigns={(values, id) => {
              setEnabled(values);
              handleWifiToggle();
            }}
            id={0}
            isDisable={!isCompanyActive}
          />
        </div>
        {isEnabled && (
          <>
            <Formik
              initialValues={{
                session_timeout: sessionTimeout ?? 60,
                session_delay: sessionDelay ?? 5,
              }}
              validationSchema={yup.object({
                session_timeout: yup
                  .number()
                  .min(1, "Value should be greater than 0.")
                  .integer("Value shoule be integer.")
                  .required("Value is required."),

                session_delay: yup
                  .number()
                  .min(1, "Value should be greater than 0.")
                  .integer("Value shoule be integer.")
                  .required("Value is required."),
              })}
              onSubmit={handleUpdateSessionTimeout}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                validateField,
                validateForm,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mt-5 flex items-center">
                    <span className="mr-4 text-md leading-6 font-light text-gray-900 w-44">
                      Skip OTP Verification :{" "}
                    </span>
                    <SwitchButton
                      value={isSkipOtp}
                      handleToggleForCampaigns={(values, id) => {
                        setSkipOtp(values);
                        handleSkipOtpVerification();
                      }}
                      id={0}
                      isDisable={!isCompanyActive}
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center">
                      <span className="mr-4 text-md leading-6 font-light text-gray-900 w-44">
                        Session Timeout :{" "}
                      </span>
                      <div className="relative mt-2">
                        <NumericFormat
                          name={"name"}
                          value={values?.session_timeout}
                          onValueChange={({ value }) => {
                            setFieldValue("session_timeout", value);
                          }}
                          className="input-field pr-12"
                          placeholder={`Enter Session Timeout`}
                          readOnly={!isCompanyActive}
                        />

                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            MIN
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[12rem]">
                      {touched?.session_timeout &&
                        showError(errors?.session_timeout)}
                    </div>

                    <div className="flex items-center">
                      <span className="mr-4 text-md leading-6 font-light text-gray-900 w-44">
                        Session Delay :{" "}
                      </span>
                      <div className="relative mt-2">
                        <NumericFormat
                          name={"name"}
                          value={values?.session_delay}
                          onValueChange={({ value }) => {
                            setFieldValue("session_delay", value);
                          }}
                          className="input-field pr-12"
                          placeholder={`Enter Session Timeout`}
                          readOnly={!isCompanyActive}
                        />
                        <div className="absolute">
                          {touched?.session_delay &&
                            showError(errors?.session_delay)}
                        </div>

                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            SEC
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <SpinnerButton
                        className="btn-pink mt-4"
                        title={"Update"}
                        action={() => {}}
                        type={"submit"}
                        loading={updateSessionTimeoutLoading}
                        isDisable={!isCompanyActive}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            <div className="mt-8 flex  items-center gap-y-2">
              <span className="mr-4 min-w-[107px] font-light text-[16px] text-gray-900">
                Connect URL :
              </span>
              <div className="flex flex-col  gap-y-1 relative">
                {isCompanyActive && (
                  <div className="absolute -top-[33px] right-0">
                    <span
                      className="p-1.5 shadow hover:bg-slate-50 cursor-pointer border bg-gary-50 text-gray-500"
                      onClick={() => {
                        const isCopied = copy(connectURL);
                        if (isCopied) {
                          setCopied(true);
                        }
                      }}
                    >
                      {isCopied ? "Copied!" : "Copy URL"}
                    </span>
                  </div>
                )}
                <div className="flex flex-wrap break-words">
                  <span className="select-none p-2 bg-gray-200 font-normal text-[14px] text-gray-900 border-0 ring-1 ring-inset ring-gray-300  ">
                    {" "}
                    {connectURL}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="block 2xl:hidden w-full h-[1px] bg-gray-300 my-4"></div>
      {isEnabled && (
        <div className="bg-gray-300 w-full 2xl:w-[50%] flex justify-center py-2">
          <div className="">
            <SplashPreview
              currentLocationId={currentLocationId}
              locationName={locationName}
              companyName={companyName}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestWIFI;
