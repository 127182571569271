import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import SwitchButton from "components/common/fields/SwitchButton";
import { companyNameForMsg } from "module/data";
import {
  formatPhoneNumberAlways,
  getShortCodeOfStates,
  getUserData,
  showErrorMsg,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { changeCompanyStatus } from "redux/company/changeCompanyStatusSlice";
import { companyList } from "redux/company/companyListSlice";
import { switchToCompany } from "redux/company/switchToCompanySlice";
import { toggleCampaigns } from "redux/company/toggleCampaignsSlice";

const CompanyList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentCompanyStatus = searchParams.get("status");
  const companyListStatus = [
    "active",
    "pending",
    "archived",
    "denied",
  ].includes(currentCompanyStatus)
    ? currentCompanyStatus
    : "active";

  const [companyStatus, setCompanyStatus] = useState(companyListStatus);
  const [totalCompanyList, setTotalCompanyList] = useState([]);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);

  const [
    {
      companyListData = {},
      companyListLoading = false,
      changeCompanyStatusLoading = false,
    },
  ] = useSelector((state) => [
    {
      companyListData: state?.companyList?.data ?? {},
      companyListLoading: state?.companyList?.loading ?? false,
      changeCompanyStatusLoading: state?.changeCompanyStatus?.loading ?? false,
    },
  ]);

  const totalRecords = companyListData?.total ?? 0;
  const currentPerPage = companyListData?.per_page ?? showPerPage;

  const handleCompanyList = (search = "") => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
    };
    dispatch(companyList({ ...sortObj, companyStatus: companyStatus })).then(
      (data) => {
        if (data?.payload?.data) {
          setTotalCompanyList(data?.payload?.data);
        }
      }
    );
  };

  const handleCompanySwitch = (companyId) => {
    const userData = getUserData();
    const currentUserData = userData ? JSON.parse(userData) : "";

    const obj = {
      userCode: currentUserData.user_code,
      companyId,
    };
    dispatch(switchToCompany(obj)).then((data) => {
      if (data?.payload?.code === 200) {
        navigate("/account");
        showSuccessMsg("Welcome to company user");
      } else {
        showErrorMsg(data?.payload ?? "Something went wrong");
      }
    });
  };

  const handleCompanyStatusChanges = () => {
    dispatch(
      changeCompanyStatus({ companyId: selectedId, status: selectedStatus })
    ).then((data) => {
      if (data?.payload?.code == 200) {
        const message =
          selectedStatus === "delete"
            ? `Company deleted successfully.`
            : `Company ${selectedStatus} successfully.`;
        showSuccessMsg(message);
        setConfirmModalOpen(false);
        handleCompanyList(searchInput);
      } else {
        setConfirmModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  const handleToggleForCampaigns = (value, id, column = "") => {
    setToggleLoading(true);
    const formData = {
      id: id,
      column_name: column,
    };
    dispatch(toggleCampaigns(formData)).then((data) => {
      if (data.payload.code === 200) {
        showSuccessMsg(
          "Campaign Show/Hide Successfully" ?? "Activated successfully"
        );
        handleCompanyList(searchInput);
        setTimeout(() => {
          setToggleLoading(false);
        }, 300);
      }
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "company",
      Cell: ({ cell }) => (
        <span
          className="hover:underline hover:cursor-pointer "
          onClick={() => navigate(`/company/edit/${cell.row.values.id}`)}
        >
          {cell.row.values.company}
        </span>
      ),
    },
    {
      Header: "Location",
      accessor: "locations_count",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
      Cell: ({ cell }) => (
        <span>{getShortCodeOfStates(cell.row.values?.state)}</span>
      ),
    },
    {
      Header: "Zip",
      accessor: "zip",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => {
        return formatPhoneNumberAlways(cell.row.values?.phone ?? "");
      },
    },
    ...(["active", "pending"].includes(companyStatus)
      ? [
          {
            Header: "Direct Mail",
            accessor: "show_direct_mail",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex justify-start items-center ">
                <SwitchButton
                  value={
                    cell.row.values?.show_direct_mail == "1" ? true : false
                  }
                  handleToggleForCampaigns={(values, id) =>
                    handleToggleForCampaigns(values, id, "show_direct_mail")
                  }
                  id={cell.row.values?.id ?? 0}
                />
              </div>
            ),
          },
          {
            Header: "Door Hanger",
            accessor: "show_door_hangar",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex justify-start items-center ">
                <SwitchButton
                  value={
                    cell.row.values?.show_door_hangar == "1" ? true : false
                  }
                  handleToggleForCampaigns={(values, id) =>
                    handleToggleForCampaigns(values, id, "show_door_hangar")
                  }
                  id={cell.row.values?.id ?? 0}
                />
              </div>
            ),
          },
        ]
      : []),

    {
      // width: 100,
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ cell }) => (
        <div className="flex flex-wrap items-center gap-x-2 ">
          {["active"].includes(companyStatus) && (
            <>
              <span
                onClick={() => {
                  navigate(`/company/edit/${cell.row.values?.id}`);
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Edit
              </span>
              {" | "}
            </>
          )}
          {["pending", "archived", "denied"].includes(companyStatus) && (
            <>
              <span
                onClick={() => {
                  setConfirmModalOpen(true);
                  setSelectedId(cell.row.values?.id);
                  setSelectedStatus("active");
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Activate
              </span>
              {companyStatus === "active" ? " | " : ""}
            </>
          )}
          {["active", "archived", "denied"].includes(companyStatus) && (
            <>
              {" "}
              {["denied", "archived"].includes(companyStatus) ? " | " : ""}
              <span
                onClick={() => {
                  setConfirmModalOpen(true);
                  setSelectedId(cell.row.values?.id);
                  setSelectedStatus("pending");
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Pending
              </span>
            </>
          )}
          {["active", "pending"].includes(companyStatus) && (
            <>
              {" "}
              {" | "}
              <span
                to=""
                onClick={() => {
                  setConfirmModalOpen(true);
                  setSelectedId(cell.row.values?.id);
                  setSelectedStatus("archived");
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Archive
              </span>
            </>
          )}
          {["active", "pending"].includes(companyStatus) && (
            <>
              {" "}
              {" | "}
              <span
                to=""
                onClick={() => {
                  setConfirmModalOpen(true);
                  setSelectedId(cell.row.values?.id);
                  setSelectedStatus("denied");
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Deny
              </span>
            </>
          )}
          {["denied"].includes(companyStatus) && (
            <>
              {" "}
              {" | "}
              <span
                to=""
                onClick={() => {
                  setConfirmModalOpen(true);
                  setSelectedId(cell.row.values?.id);
                  setSelectedStatus("delete");
                }}
                className=" hover:cursor-pointer hover:underline "
              >
                Delete
              </span>
            </>
          )}

          {["active"].includes(companyStatus) && (
            <>
              {" "}
              {" | "}
              <span
                to=""
                onClick={() => handleCompanySwitch(cell.row.values?.id)}
                className=" hover:cursor-pointer hover:underline "
              >
                Login As Company
              </span>
            </>
          )}
        </div>
      ),
    },
  ];

  const globalSearch = (search) => {
    handleCompanyList(search);
  };

  useEffect(() => {
    handleCompanyList(searchInput);
  }, [sortOrder, currentPage, perPage, companyStatus]);

  const breadCrumbsTitle = {
    active: "Active",
    pending: "Pending",
    archived: "Archived",
    denied: "Denied",
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Companies List",
          href: "/company/list",
          current: false,
        },
        {
          name: `${breadCrumbsTitle[companyStatus]}`,
          href: "",
          current: false,
        },
      ])
    );
  }, [companyStatus]);

  const companyStatusArray = [
    { name: "Active", value: "active", href: "#", current: false },
    { name: "Pending", value: "pending", href: "#", current: false },
    { name: "Archived", value: "archived", href: "#", current: true },
    { name: "Denied", value: "denied", href: "#", current: false },
  ];

  return (
    <>
      <ShowPageTitle title="companies" />
      <div className="">
        <div className="flex border-b border-collapse border-gray-300">
          <div className=" flex-1  w-full">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {companyStatusArray.map((tab) => (
                <span
                  key={tab.name}
                  onClick={() => {
                    setCompanyStatus(tab.value);
                    navigate(`/company/list?status=${tab.value}`);
                  }}
                  className={classNames(
                    " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                    {
                      "border-pink-600 hover:border-pink-600 text-gray-800":
                        tab.value === companyStatus,
                      "border-transparent": tab.value !== companyStatus,
                    }
                  )}
                  aria-current={
                    tab.value === companyStatus ? "page" : undefined
                  }
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex  justify-end items-center mt-2">
          {["active"].includes(companyStatus) && (
            <button
              type="button"
              onClick={() => {
                navigate("/company/add");
              }}
              className="btn-pink w-46"
            >
              Add Company
            </button>
          )}
          <div className="ml-2 flex shadow-sm">
            <SearchInput
              globalSearch={globalSearch}
              companyStatus={companyStatus}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
            />
          </div>
        </div>

        <div className="flex flex-col mt-2">
          <CustomTable
            data={totalCompanyList}
            isLoading={toggleLoading ? toggleLoading : companyListLoading}
            columns={columns}
            defaultSortIndexColumn={sortIndex}
            defaultOrder={sortOrder}
            setSortIndex={setSortIndex}
            setSortOrder={setSortOrder}
            totalRecords={totalRecords}
            perPage={perPage ? perPage : currentPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPerPage={setPerPage}
          />
        </div>
        {isConfirmModalOpen && (
          <ConfirmationModal
            isModalOpen={isConfirmModalOpen}
            setModalOpen={setConfirmModalOpen}
            handleAction={handleCompanyStatusChanges}
            isLoading={changeCompanyStatusLoading}
            message={
              selectedStatus !== "delete"
                ? `You want to ${companyNameForMsg[selectedStatus]} this company ?`
                : `This is IRREVERSIBLE, This will permanently delete the company, all locations, and data.`
            }
          />
        )}
      </div>
    </>
  );
};

export default CompanyList;
