import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetData, getApi } from "module/api";
import { createQueryParams } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const customerList = createAsyncThunk(
  "customer/list",
  async (data = {}, { rejectWithValue }) => {
    const { search, sort, order, page, perPage, location_id } = data;

    const queryString = createQueryParams({
      search,
      sort,
      order,
      page,
      perPage,
      location_id,
    });
    try {
      const response = await getApi(`api/customers${queryString}`);
      return apiGetData(response?.data);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customerList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(customerList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(customerList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default customerListSlice.reducer;
