import datePicker from "assets/images/datePicker.svg";
import TypeAheadSource from "components/campaigns/TypeAheadSource";
import PrintAdStep2 from "components/campaigns/printAd/PrintAdStep2";
import CustomCostInput from "components/common/fields/CustomCostInput";
import SpinnerButton from "components/common/fields/SpinnerButton";
import CustomerFetchingLoader from "components/leadList/CustomerFetchingLoader";
import { Field, Formik } from "formik";
import {
  convertDate,
  getCampaignImage,
  getCompanyStatus,
  getCurrentLocation,
  getDateFormatLocal,
  isValidArray,
  isValidObject,
  showErrorMsg,
} from "module/util";
import {
  handleServerValidation,
  printAdForm1,
  showError,
} from "module/validation";
import { useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addCampaign } from "redux/campaign/addCampaignSlice";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { getSourceList } from "redux/campaign/getSourceListSlice";
import { updateCampaignDetail } from "redux/campaign/updateCampaignDetailSlice";

const PrintAdStep1 = (props) => {
  const { setSearchParams, setSelectStep, type, selectedStep } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();
  const initFormRef = formRef?.current?.values ?? {};
  const defaultDateFormat = getDateFormatLocal();
  const isCompanyActive = getCompanyStatus() === "active";

  const isEdit = !!id;
  const { location: currentLocationName } = getCurrentLocation();

  const [
    {
      sourceListData = [],
      addCampaignLoading = false,
      updateCampaignDetailLoading = false,
      getCampaignDetailData = {},
      validationMsgObj = {},
    },
  ] = useSelector((state) => [
    {
      sourceListData: state?.getSourceList?.data,
      addCampaignLoading: state?.addCampaign?.loading,
      updateCampaignDetailLoading: state?.updateCampaignDetail?.loading,
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
      validationMsgObj: state?.getMessages?.data ?? {},
    },
  ]);

  const offersObj = isValidArray(getCampaignDetailData?.offers)
    ? getCampaignDetailData.offers[0]
    : {};

  const initialValues = {
    name: isEdit ? getCampaignDetailData?.name : initFormRef?.name,
    isEdit: isEdit,
    campaign_cost: isEdit
      ? getCampaignDetailData?.campaign_cost
      : initFormRef?.campaign_cost,
    source: isEdit ? getCampaignDetailData?.source : initFormRef?.source,
    start_date: isEdit
      ? convertDate(getCampaignDetailData?.start_date)
      : new Date(),
    end_date: isEdit
      ? convertDate(getCampaignDetailData?.end_date)
      : initFormRef?.end_date ?? new Date(),
    expires: isEdit
      ? getCampaignDetailData?.expires
      : initFormRef?.expires ?? "yes",
    crossAccept: isEdit
      ? getCampaignDetailData?.cross_accept
      : initFormRef?.crossAccept ?? "no",
    mailer_upload: isEdit ? "" : initFormRef?.mailer_upload,
    offer_name: isEdit ? offersObj?.offer_name ?? "" : initFormRef?.offer_name,
    discount_cost: isEdit
      ? offersObj?.discount_cost ?? ""
      : initFormRef?.discount_cost,
    location: currentLocationName,
  };

  const handleSubmit = (value) => {
    const { id: currentLocationId } = getCurrentLocation();
    const expiration = value?.expires === "yes" ? true : false;

    const formData = {
      name: value?.name ?? "",
      campaign_cost: value?.campaign_cost ?? "",
      source: value?.source ?? "",
      start_date: value?.start_date ?? "",
      end_date: expiration ? value?.end_date : "" ?? "",
      expires: value?.expires ?? "no",
      cross_accept: value?.crossAccept ?? "no",
      mailer_upload: value?.mailer_upload ?? "",
      type: "print_ad",
      offers: [
        {
          ...(isValidObject(offersObj) && isEdit
            ? { id: offersObj?.id ?? 0 }
            : {}),
          offer_name: value?.offer_name ?? "",
          discount_cost: value?.discount_cost,
        },
      ],
      location_id: currentLocationId,
    };

    if (isEdit) {
      dispatch(
        updateCampaignDetail({
          id: id,
          formData: formData,
        })
      ).then((data) => {
        if (data?.payload?.code === 200) {
          setSelectStep(2);
          navigate(`/campaigns/edit/${data?.payload?.dataObj}/${type}?step=2`);
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(
        addCampaign({
          formData: formData,
        })
      ).then((data) => {
        if (data?.payload.code === 200) {
          setSelectStep(2);
          navigate(`/campaigns/edit/${data?.payload?.dataObj}/${type}?step=2`);
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetail(id));
    }
    dispatch(getSourceList());
  }, []);

  return (
    <>
      {selectedStep == 1 ? (
        <div className="flex justify-center gap-x-6">
          <Formik
            initialValues={initialValues}
            validationSchema={() =>
              printAdForm1(validationMsgObj?.campaign ?? {})
            }
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formRef}
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
              setFieldTouched,
              setFieldValue,
            }) => (
              <form className="w-full min-w-xl mt-4" onSubmit={handleSubmit}>
                <div className="w-full border border-gray-200 p-3 bg-white mt-4">
                  <div className="flex  flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="name" className="input-field-label">
                        Campaign Name
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          value={values?.name ?? ""}
                          onChange={handleChange}
                          placeholder="Enter Name"
                          className="input-field"
                        />
                      </div>
                      {touched?.name && showError(errors?.name)}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="campaign_cost"
                        className="input-field-label"
                      >
                        Ad Cost
                      </label>
                      <CustomCostInput
                        values={values?.campaign_cost ?? ""}
                        setFieldValue={setFieldValue}
                        name={"campaign_cost"}
                        title="Ad Cost"
                        onBlur={handleBlur}
                      />
                      {touched?.campaign_cost &&
                        showError(errors?.campaign_cost)}
                    </div>
                  </div>

                  <div className="flex  flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="source" className="input-field-label">
                        Source
                      </label>
                      <div className="mt-2">
                        <TypeAheadSource
                          setFieldValue={setFieldValue}
                          values={values?.source}
                          data={
                            isValidArray(sourceListData) ? sourceListData : []
                          }
                          setFieldTouched={setFieldTouched}
                        />
                        {touched?.source && showError(errors?.source)}
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="offer_name" className="input-field-label">
                        Offer Name
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="offer_name"
                          id="offer_name"
                          value={values?.offer_name ?? ""}
                          onChange={handleChange}
                          autoComplete="offer_name"
                          placeholder="Enter Offer Name"
                          className="input-field"
                        />
                      </div>
                      {touched?.offer_name && showError(errors?.offer_name)}
                    </div>
                  </div>

                  <div className="flex   flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/4 px-3">
                      <label htmlFor="expires" className="input-field-label">
                        Code Expires
                      </label>
                      <div className="mt-2 flex items-center">
                        <label>
                          <Field
                            type="radio"
                            name="expires"
                            value="yes"
                            className="remove-focus-ring"
                            onChange={(e) =>
                              setFieldValue("expires", e.target.value)
                            }
                          />{" "}
                          Yes
                        </label>
                        <label className="ml-2">
                          <Field
                            type="radio"
                            name="expires"
                            value="no"
                            className="remove-focus-ring"
                            onChange={(e) =>
                              setFieldValue("expires", e.target.value)
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                      {touched?.expires && showError(errors?.expires)}
                    </div>
                    <div className="w-full md:w-1/4 px-3">
                      <label
                        htmlFor="crossAccept"
                        className="input-field-label"
                      >
                        Accept in other locations ?
                      </label>
                      <div className="mt-2 flex items-center">
                        <label>
                          <Field
                            type="radio"
                            name="crossAccept"
                            value="yes"
                            className="remove-focus-ring"
                            onChange={(e) =>
                              setFieldValue("crossAccept", e.target.value)
                            }
                          />{" "}
                          Yes
                        </label>
                        <label className="ml-2">
                          <Field
                            type="radio"
                            name="crossAccept"
                            value="no"
                            className="remove-focus-ring"
                            onChange={(e) =>
                              setFieldValue("crossAccept", e.target.value)
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                      {touched?.crossAccept && showError(errors?.crossAccept)}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="discount_cost"
                        className="input-field-label"
                      >
                        Offer Cost
                      </label>
                      <CustomCostInput
                        values={values?.discount_cost ?? ""}
                        setFieldValue={setFieldValue}
                        name={"discount_cost"}
                        title="Offer Cost"
                        onBlur={handleBlur}
                      />
                      {touched?.discount_cost &&
                        showError(errors?.discount_cost)}
                    </div>
                  </div>

                  <div className="flex   flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="start_date" className="input-field-label">
                        Target Start date
                      </label>
                      <div className="mt-2 ">
                        <div className="relative w-full z-9">
                          <ReactDatePicker
                            className="input-field"
                            selected={values?.start_date ?? ""}
                            dateFormat={defaultDateFormat}
                            onChange={(date) => {
                              setFieldValue("start_date", date);
                            }}
                            minDate={new Date()}
                            placeholderText="Select Start Date"
                          />
                          <img
                            src={datePicker}
                            className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                            alt="Date picker"
                          />
                        </div>
                      </div>
                      {touched?.start_date && showError(errors?.start_date)}
                    </div>
                    {values?.expires === "yes" && (
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="end_date" className="input-field-label">
                          End Date
                        </label>
                        <div className="mt-2">
                          <div className="relative w-full">
                            <ReactDatePicker
                              className="input-field"
                              selected={values?.end_date ?? ""}
                              dateFormat={defaultDateFormat}
                              onChange={(date) => {
                                setFieldValue("end_date", date);
                              }}
                              minDate={new Date()}
                              placeholderText="Select End Date"
                            />
                            <img
                              src={datePicker}
                              className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                              alt="Date picker"
                            />
                          </div>
                        </div>
                        {touched?.end_date && showError(errors?.end_date)}
                      </div>
                    )}
                  </div>

                  <div className="flex   flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="location" className="input-field-label">
                        Location
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="location"
                          id="location"
                          value={values?.location ?? ""}
                          onChange={handleChange}
                          autoComplete="location"
                          placeholder="Enter location"
                          readOnly={true}
                          disabled={true}
                          className="input-field"
                        />
                      </div>
                      {touched?.location && showError(errors?.location)}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="mailer_upload"
                        className="input-field-label"
                      >
                        Image
                      </label>
                      <div className="mt-2">
                        <input
                          type="file"
                          name="mailer_upload"
                          id="mailer_upload"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => {
                            let filesData = e.target.files[0];
                            setFieldValue("mailer_upload", filesData);
                          }}
                          onBlur={handleBlur}
                          autoComplete="mailer_upload"
                          placeholder="Enter image"
                          className="relative m-0 block w-full min-w-0 flex-auto border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                        />
                        <span className="text-sm font-light mr-2">
                          (Upload: JPEG or PNG, 1000x1000, max 500k)
                        </span>
                        {touched?.mailer_upload &&
                          showError(errors?.mailer_upload)}
                        {!!values?.mailer_upload ||
                        (isValidObject(getCampaignDetailData) &&
                          getCampaignDetailData?.mailer_upload !== "" &&
                          isEdit) ? (
                          <img
                            alt="thumbnail"
                            src={
                              typeof values?.mailer_upload === "string"
                                ? getCampaignImage(
                                    values?.mailer_upload
                                      ? values?.mailer_upload
                                      : getCampaignDetailData?.mailer_upload
                                  )
                                : URL.createObjectURL(values?.mailer_upload)
                            }
                            className="w-[150px] h-[100px] mt-2"
                          />
                        ) : (
                          <div className="w-[150px] h-[100px] bg-gray-200 border-2 border-gray-300 mt-2"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between mb-6 mt-4">
                  <button
                    onClick={() => {
                      navigate(`/campaigns/list?type=${type}`);
                    }}
                    className="btn btn-light-gray ml-2"
                  >
                    Cancel
                  </button>
                  {isCompanyActive && (
                    <SpinnerButton
                      className="btn-pink"
                      title={"Build"}
                      action={() => {}}
                      type={"submit"}
                      loading={
                        addCampaignLoading || updateCampaignDetailLoading
                      }
                    />
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="w-full border border-gray-200 p-3 bg-white mt-4">
          <PrintAdStep2
            setSearchParams={setSearchParams}
            setSelectStep={setSelectStep}
            type={type}
            selectedStep={selectedStep}
          />
        </div>
      )}
      {(addCampaignLoading || updateCampaignDetailLoading) && (
        <CustomerFetchingLoader
          isModalOpen={true}
          message="Please wait a while, Processing....."
        />
      )}
    </>
  );
};

export default PrintAdStep1;
