import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomerForm from "components/contacts/customer/CustomerForm";
import DemographicsForm from "components/contacts/customer/DemographicsForm";
import CampaignForm from "components/contacts/customer/campaign/CampaignForm";
import OrdersList from "components/contacts/orders/OrdersList";
import Visits from "components/contacts/visits/Visits";
import { convertLatLongObj } from "module/util";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getContactDetail } from "redux/customer/getContactDetailSlice";
import { getCustomerReport } from "redux/customer/getCustomerReportSlice";
import { getProspectReport } from "redux/customer/getProspectReportSlice";

const CustomerFormMain = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [latLong, setLatLong] = useState(null);
  const [isSearchFormOpen, setSearchFormOpen] = useState(true);

  const contactType = searchParams.get("type") ?? "customer";
  const currentTab = searchParams.get("tab");
  const isEdit = location.pathname.includes("/contacts/edit") && id;
  const sampleTab = ["1", "2", "3", "4", "5"];
  // const [tagId, setTagId] = useState([]);

  const [handleTab, setTab] = useState(
    currentTab && sampleTab.includes(currentTab) ? Number(currentTab) : 1
  );

  const customersTabs = [
    { name: isEdit ? "Details" : "Add", value: 1, href: "#", current: false },
    { name: "Campaigns", value: 2, href: "#", current: false },
    { name: "Demographics", value: 3, href: "#", current: true },
    { name: "Orders", value: 4, href: "#", current: true },
    { name: "Visits", value: 5, href: "#", current: true },
  ];

  const breadCrumbsTitle = {
    1: isEdit
      ? "Contact Details"
      : isSearchFormOpen
      ? "Search Customers"
      : "Add Customers",
    2: "Contact Campaigns",
    3: "Contact Demographics",
    4: "Contact Orders",
    5: "Contact Visits",
  };
  const [
    {
      getCustomerReportData = [],
      getCustomerDetailData = {},
      getProspectReportData = {},
    },
  ] = useSelector((state) => [
    {
      getCustomerReportData: state?.getCustomerReport?.data ?? {},
      getCustomerDetailData: state?.getContactDetail?.data ?? {},
      getProspectReportData: state?.getProspectReport?.data ?? {},
    },
  ]);

  useEffect(() => {
    if (isEdit) {
      handleContactDetails();
      if (contactType === "customer") {
        dispatch(getCustomerReport(id));
      } else if (contactType === "prospect") {
        dispatch(getProspectReport(id));
      }
    }
  }, []);

  const handleContactDetails = () => {
    const fomrData = {
      type: contactType,
      id: id,
    };
    dispatch(getContactDetail(fomrData)).then((data) => {
      if (data?.payload) {
        // isValidArray(JSON.parse(data?.payload?.tags)) &&
        //   setTagId(JSON.parse(data?.payload?.tags));
        let latLong = "";
        if (data?.payload?.lat_long) {
          latLong = convertLatLongObj(data?.payload?.lat_long);
        } else {
          latLong = convertLatLongObj(data?.payload?.demographics?.lat_long);
        }
        setLatLong(latLong);
      }
    });
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: `/contacts?tab=${location?.state}`,
          current: true,
        },
        {
          name: breadCrumbsTitle[handleTab],
          href: "",
          current: false,
        },
      ])
    );
  }, [handleTab, isSearchFormOpen]);

  return (
    <div>
      <ShowPageTitle title="customers" />
      <div className="flex mt-2">
        <div className="border-b border-collapse border-gray-300 w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {customersTabs.map((tab, index) => {
              if ((!isEdit && index === 0) || isEdit)
                return (
                  <span
                    key={tab.name}
                    onClick={() => {
                      if (isEdit) {
                        navigate(
                          `/contacts/edit/${id}?type=${contactType}&tab=${tab.value}`,
                          {
                            state: location?.state,
                          }
                        );
                      }
                      setTab(tab.value);
                    }}
                    className={classNames(
                      " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                      {
                        "border-pink-600 hover:border-pink-600 text-gray-800":
                          tab.value === handleTab,
                        "border-transparent": tab.value !== handleTab,
                      }
                    )}
                    aria-current={tab.value === handleTab ? "page" : undefined}
                  >
                    {tab.name}
                  </span>
                );
            })}
          </nav>
        </div>
      </div>
      <div className="mt-4">
        <div className={handleTab === 1 ? "block" : "hidden"}>
          {handleTab === 1 && (
            <CustomerForm
              contactType={contactType}
              setSearchFormOpen={setSearchFormOpen}
              isSearchFormOpen={isSearchFormOpen}
              handleContactDetails={handleContactDetails}
              getCustomerDetailData={getCustomerDetailData}
              setLatLong={setLatLong}
              latLong={latLong}
            />
          )}
        </div>
        <div className={handleTab === 2 ? "block" : "hidden"}>
          {handleTab === 2 && (
            <CampaignForm
              getCustomerReportData={
                contactType === "prospect"
                  ? getProspectReportData
                  : getCustomerReportData
              }
            />
          )}
        </div>
        <div className={handleTab === 3 ? "block" : "hidden"}>
          {handleTab === 3 && (
            <DemographicsForm getCustomerDetailData={getCustomerDetailData} />
          )}
        </div>
        <div className={handleTab === 4 ? "block" : "hidden"}>
          {handleTab === 4 && <OrdersList customerId={id} />}
        </div>
        <div className={handleTab === 5 ? "block" : "hidden"}>
          {handleTab === 5 && <Visits customerId={id} />}
        </div>
      </div>
    </div>
  );
};

export default CustomerFormMain;
