import CustomTable from "components/common/fields/CustomTable";
import {
  convertTimeStampToDate,
  convertTimeStampToDateTime,
  getCurrentLocation,
  showPerPage,
  showPrice,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "redux/redemption/getAllCouponsSlice";

const CouponsList = (props) => {
  const { data, customerId } = props;
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);

  const currentPerPage = showPerPage;

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const { id: currentLocationId } = getCurrentLocation();

  const couponsListData = useSelector(
    (state) => state?.getAllCoupons?.data?.dataObj ?? []
  );
  const lastUpdated = couponsListData?.last_executed_at;

  const couponListLoading = useSelector(
    (state) => state?.getAllCoupons?.loading ?? false
  );

  const totalRecords = couponsListData?.coupons?.length ?? 0;

  const handleOrdersList = (search) => {
    const sortObj = {
      // sort: columns[sortIndex]?.accessor,
      // order: sortOrder,
      // search: search,
      // page: currentPage,
      // perPage: perPage,
      location_id: currentLocationId,
      // customer_id: customerId,
    };
    dispatch(getAllCoupons(sortObj));
  };

  useEffect(() => {
    handleOrdersList();
  }, [locationId]);

  useEffect(() => {}, []);

  const removeUnderscore = (data) => {
    if (data !== null) {
      return data.split("_").join(" ");
    } else return "N/A";
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    {
      Header: "Discount Type",
      accessor: "discount_type",
      Cell: ({ cell }) => <span>{removeUnderscore(cell?.value)} </span>,
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell: ({ cell }) => <span>{convertTimeStampToDate(cell?.value)}</span>,
    },

    // {
    //   Header: "Type",
    //   accessor: "coupon_type",
    // },
    // {
    //   Header: "Count",
    //   accessor: "count",
    // },
    // {
    //   Header: "Action",
    //   accessor: "order_id",
    //   Cell: ({ cell }) => (
    //     <span
    //       className="cursor-pointer"
    //       title="View"
    //       onClick={() => {
    //         setSelectedId(cell?.row?.values?.order_id);
    //         setModalOpen(true);
    //       }}
    //     >
    //       <EyeIcon className="w-6" />
    //     </span>
    //   ),
    // },
  ];

  const lastUpdate = new Date();
  const hours = lastUpdate.getHours() % 12 || 12; // Get hours in 12-hour format
  const minutes = lastUpdate.getMinutes().toString().padStart(2, "0");
  const period = lastUpdate.getHours() >= 12 ? "PM" : "AM";

  return (
    <div className="">
      <div className="flex justify-end items-center mt-2">
        {/* <div className="ml-2 flex shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            companyStatus={""}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div> */}
        {couponsListData?.coupons?.length !== 0 && (
          <div className="flex justify-between font-bold text-gray-800">
            <div className="flex justify-between font-bold text-gray-800">
              <p className="text-md font-semibold text-gray-700">
                Last Update: {convertTimeStampToDateTime(lastUpdated)}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-col">
        <CustomTable
          data={couponsListData?.coupons ?? []}
          isLoading={couponListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          isPaginationHide={false}
          colSpan={12}
        />
      </div>
    </div>
  );
};

export default CouponsList;
