import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getCustomHeatmapData = createAsyncThunk(
  "getCustomHeatmapData",
  async (data, { rejectWithValue }) => {
    const { companyId, currentLocationId, type } = data;
    try {
      const response = await getApi(
        `api/heatmap-type?company_id=${companyId}&location_id=${currentLocationId}&type=${type}`
      );
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCustomHeatmapDataSlice = createSlice({
  name: "getCustomHeatmapData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomHeatmapData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCustomHeatmapData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getCustomHeatmapData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getCustomHeatmapDataSlice.reducer;
