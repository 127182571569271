import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  PowerIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "assets/images/logo-pendio.png";
import classNames from "classnames";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

const SidebarMenu = (props) => {
  const {
    sidebarOpen,
    setSidebarOpen,
    navigation,
    setMenuItem,
    selectedMenuItem,
    teams,
    isBackToLoginShow,
    handleLogout,
    handleBackToAdmin,
    isLogoutButtonShow,
  } = props;

  const navigate = useNavigate();
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999] lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-2">
                  <div className="flex h-16 shrink-0 items-center px-6">
                    <img
                      className="h-20 w-auto"
                      src={Logo}
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-[1px] space-y-1">
                          {navigation.map((item) => (
                            <li
                              key={item.name}
                              onClick={(e) => {
                                if (!e.ctrlKey) {
                                  setMenuItem(item.name);
                                }
                              }}
                            >
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.name === selectedMenuItem
                                    ? "bg-gray-200 text-gray-800 "
                                    : "text-gray-700 hover:text-gray-800 hover:bg-gray-200 ",
                                  "group flex gap-x-3 p-2 text-md leading-6 px-6"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.name === selectedMenuItem
                                      ? "text-gray-800"
                                      : "text-gray-400 group-hover:text-gray-800",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <ul role="list" className="-mx-[1px] mt-2 space-y-1">
                          {teams.map((team) => (
                            <li
                              key={team.name}
                              onClick={(e) => {
                                if (e.ctrlKey) {
                                  setMenuItem(team.name);
                                }
                              }}
                            >
                              <Link
                                to={team.href}
                                className={classNames(
                                  team.name === selectedMenuItem
                                    ? "bg-gray-200 text-gray-800"
                                    : "text-gray-700 hover:text-gray-800 hover:bg-gray-200",
                                  "group flex gap-x-3 p-2 text-md leading-6 px-6"
                                )}
                              >
                                <team.icon
                                  className={classNames(
                                    team.name === selectedMenuItem
                                      ? "text-gray-800"
                                      : "text-gray-400 group-hover:text-gray-800",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {team.name}
                              </Link>
                            </li>
                          ))}
                          {isLogoutButtonShow && (
                            <li key="logout" onClick={handleLogout}>
                              <span
                                className={classNames(
                                  "text-gray-700 hover:text-gray-800 hover:bg-gray-200 hover:cursor-pointer",
                                  "group flex gap-x-3 p-2 text-md leading-6 px-6"
                                )}
                              >
                                <PowerIcon
                                  className={classNames(
                                    "text-gray-400 group-hover:text-gray-800",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Logout
                              </span>
                            </li>
                          )}
                          {isBackToLoginShow && (
                            <li key="backToAdmin" onClick={handleBackToAdmin}>
                              <span
                                className={classNames(
                                  "text-gray-700 hover:text-gray-800 hover:bg-gray-200 hover:cursor-pointer",
                                  "group flex gap-x-3 p-2 text-md leading-6 px-6"
                                )}
                              >
                                <ArrowLeftOnRectangleIcon
                                  className={classNames(
                                    "text-gray-400 group-hover:text-gray-800",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                Back To Admin
                              </span>
                            </li>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white ">
          <div className="flex h-16 shrink-0 items-center px-6">
            <img
              className="h-20 w-auto hover:cursor-pointer mt-2"
              onClick={() => navigate("/dashboard")}
              src={Logo}
              alt="Pendio"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-[1px] space-y-1 sidebar-ul">
                  {navigation.map((item) => (
                    <li
                      key={item.name}
                      onClick={(e) => {
                        if (!e.ctrlKey) {
                          setMenuItem(item.name);
                        }
                      }}
                    >
                      <Link
                        to={item.href}
                        className={classNames(
                          item.name === selectedMenuItem
                            ? "bg-gray-100 text-gray-800 "
                            : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                          "group flex gap-x-3  p-2 text-md leading-6 px-6"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.name === selectedMenuItem
                              ? "text-gray-800"
                              : "text-gray-400 group-hover:text-gray-800",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul role="list" className="-mx-[1px] mt-2 space-y-1">
                  {teams.map((team) => (
                    <li
                      key={team.name}
                      onClick={(e) => {
                        if (!e.ctrlKey) {
                          setMenuItem(team.name);
                        }
                      }}
                    >
                      <Link
                        to={team.href}
                        className={classNames(
                          team.name === selectedMenuItem
                            ? "bg-gray-100 text-gray-800 "
                            : "text-gray-700 hover:text-gray-800 hover:bg-gray-100 ",
                          "group flex gap-x-3 p-2 text-md leading-6 px-6"
                        )}
                      >
                        <team.icon
                          className={classNames(
                            team.name === selectedMenuItem
                              ? "text-gray-800"
                              : "text-gray-400 group-hover:text-gray-800",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {team.name}
                      </Link>
                    </li>
                  ))}
                  {isLogoutButtonShow && (
                    <li key="logout" onClick={handleLogout}>
                      <span
                        className={classNames(
                          "text-gray-700 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer",
                          "group flex gap-x-3 p-2 text-md leading-6 px-6"
                        )}
                      >
                        <PowerIcon
                          className={classNames(
                            "text-gray-400 group-hover:text-gray-800",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Logout
                      </span>
                    </li>
                  )}
                  {isBackToLoginShow && (
                    <li key="backToAdmin" onClick={handleBackToAdmin}>
                      <span
                        className={classNames(
                          "text-gray-700 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer",
                          "group flex gap-x-3 p-2 text-md leading-6 px-6"
                        )}
                      >
                        <ArrowLeftOnRectangleIcon
                          className={classNames(
                            "text-gray-400 group-hover:text-gray-800",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        Back To Admin
                      </span>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
