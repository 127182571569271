import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putApi } from "module/api";
import { showSuccessMsg } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await putApi(`api/customers/${id}`, formData);
      if (response?.data?.code === 200) {
        showSuccessMsg(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateCustomerSlice = createSlice({
  name: "updateCustomer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default updateCustomerSlice.reducer;
