import { EyeIcon } from "@heroicons/react/24/outline";
import CustomTable from "components/common/fields/CustomTable";
import OrderDetailsModal from "components/redemption/orders/OrderDetailsModal";
import {
  convertTimeStampToDate,
  convertTimeStampToDateTime,
  getCompanyStatus,
  getCurrentLocation,
  parsedListOfObject,
  showPerPage,
  showPrice,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "redux/redemption/getAllOrdersSlice";

const OrdersList = (props) => {
  const { data, customerId } = props;
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const currentPerPage = showPerPage;

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const { id: currentLocationId } = getCurrentLocation();
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const orderListData = useSelector(
    (state) => state?.getAllOrders?.data?.dataObj ?? {}
  );
  const lastUpdated = orderListData?.last_executed_at;

  const orderListLoading = useSelector(
    (state) => state?.getAllOrders?.loading ?? false
  );
  const totalRecords = orderListData?.dataObj?.length ?? 0;

  const handleOrdersList = (search) => {
    const sortObj = {
      location_id: currentLocationId,
    };
    dispatch(getAllOrders(sortObj));
  };
  useEffect(() => {
    handleOrdersList();
  }, [locationId]);

  useEffect(() => {}, []);

  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Created Date",
      accessor: "order_created_at",
      Cell: ({ cell }) => <span>{convertTimeStampToDate(cell?.value)}</span>,
    },
    {
      Header: "Total Items",
      accessor: "quantity",
    },
    {
      Header: "Order Type",
      accessor: "order_fulfillment_type",
    },

    {
      Header: "Coupon",
      accessor: "orders_discount_name",
    },
    {
      Header: "Discount",
      accessor: "total_discount_money",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    {
      Header: "Paid Amount",
      accessor: "total_money",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    // {
    //   Header: "Total Price",
    //   accessor: "total_price",
    //   Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    // },
    ...(isCompanyActive
      ? [
          {
            Header: "Action",
            accessor: "order_id",
            Cell: ({ cell }) => (
              <span
                className="cursor-pointer"
                title="View"
                onClick={() => {
                  setSelectedId(cell?.row?.values?.order_id);
                  setModalOpen(true);
                }}
              >
                <EyeIcon className="w-6" />
              </span>
            ),
          },
        ]
      : []),
  ];
  return (
    <div className="">
      <div className="flex justify-end items-center mt-2">
        {/* <div className="ml-2 flex shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            companyStatus={""}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div> */}
        {parsedListOfObject(orderListData?.orders)?.length !== 0 && (
          <div className="flex justify-between font-bold text-gray-800">
            <p className="text-md font-semibold text-gray-700">
              Last Update: {convertTimeStampToDateTime(lastUpdated)}
            </p>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-col">
        <CustomTable
          data={parsedListOfObject(orderListData?.orders) ?? []}
          isLoading={orderListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          isPaginationHide={false}
          colSpan={12}
        />
        {isModalOpen && (
          <OrderDetailsModal
            orderData={parsedListOfObject(orderListData?.orders)}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            isLoading={false}
            selectedId={selectedId}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersList;
