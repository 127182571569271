import failedIcon from "assets/images/failedIcon.svg";
import Logo from "assets/images/logo-pendio.png";
import successIcon from "assets/images/successIcon.svg";
import { Link, useSearchParams } from "react-router-dom";

const VerifyEmailResponsePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const verifiedStatus = searchParams.get("status");

  const isVerifiedSuccess = verifiedStatus === "success";

  return (
    <div className="text-center mt-[5%]">
      {isVerifiedSuccess ? (
        <>
          <div className="flex justify-center items-center">
            <span className="text-6xl font-bold">Welcome to </span>{" "}
            <img
              className=" h-30 w-30 ml-4 mt-3"
              src={Logo}
              alt="Pendio Logo"
            />
          </div>
          <div className="flex justify-center">
            <img src={successIcon} className="h-25 w-25" alt="success" />
          </div>
          <p className="text-2xl mt-10">
            Your Account is verified. Please{" "}
            <Link
              className="hover:underline text-blue-600 underline"
              to={"/login"}
            >
              Login
            </Link>
          </p>
        </>
      ) : (
        <>
          <div className="flex justify-center">
            <img src={failedIcon} alt="success" />
          </div>
          <p className="text-2xl mt-10">
            Something went wrong. Please try again.
          </p>
          <p className="text-xl mt-1">
            <Link
              className="hover:underline text-blue-600 underline"
              to={"/register"}
            >
              Register
            </Link>
            <Link
              className="hover:underline text-blue-600 underline ml-4"
              to={"/login"}
            >
              Login
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default VerifyEmailResponsePage;
