import CustomTable from "components/common/fields/CustomTable";
import { showPerPage } from "module/util";
import { useState } from "react";

const SettingsList = () => {
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const totalRecords = 100;
  const currentPerPage = showPerPage;

  const columns = [
    {
      Header: "Lead List Name",
      accessor: "name",
      //   Cell: ({ cell }) => (
      //     <span
      //       className="hover:underline hover:cursor-pointer "
      //       onClick={() => navigate(`/company/edit/${cell.row.values.id}`)}
      //     >
      //       {cell.row.values.name}
      //     </span>
      //   ),
    },
    {
      Header: "Algorithm",
      accessor: "zip",
    },
    {
      Header: "Threshold",
      accessor: "email",
    },
    {
      Header: "Total Leads",
      accessor: "locations_count",
    },
    {
      Header: "Total Customers",
      accessor: "customer_total",
    },
    {
      Header: "Customers Matched",
      accessor: "city",
    },
    {
      Header: "Total",
      accessor: "state",
    },
  ];
  return (
    <div className="mt-0 flex flex-col mt-4">
      <CustomTable
        data={[]}
        isLoading={false}
        columns={columns}
        defaultSortIndexColumn={sortIndex}
        defaultOrder={sortOrder}
        setSortIndex={setSortIndex}
        setSortOrder={setSortOrder}
        totalRecords={totalRecords}
        perPage={perPage ? perPage : currentPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPerPage={setPerPage}
        isPaginationHide={true}
      />
    </div>
  );
};

export default SettingsList;
