import { ReactComponent as DigitalOfferIcon } from "assets/images/digital-offer.svg";
import { ReactComponent as DirectMailIcon } from "assets/images/direct-mail.svg";
import { ReactComponent as EmailIcon } from "assets/images/email.svg";
import { ReactComponent as PrintAdIcon } from "assets/images/print-ad.svg";
import { ReactComponent as SmsIcon } from "assets/images/sms.svg";
import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const CampaignTypes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const campaignType = searchParams.get("type");

  const [selectType, setSelectType] = useState(campaignType ?? "direct_mail");

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Campaigns List",
          href: "/campaigns/list",
          current: true,
        },
        {
          name: "Campaign Types",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <div className="w-full border border-gray-200 p-3 bg-white h-full">
      <ShowPageTitle title="campaingsType" />
      <div className="w-full flex justify-start items-center">
        <span
          onClick={() => {}}
          className=" hover:cursor-pointer hover:underline text-lg font-light"
        >
          Select Campaigns Type
        </span>
      </div>

      <div className="flex justify-center gap-x-6 mt-[10%]">
        <div
          onClick={() => {
            setSelectType("direct_mail");
            navigate("/campaigns/type?type=direct_mail");
          }}
          className={classNames(
            "direct-mail-camp max-w-[135px] w-full min-h-[100px] flex flex-col items-center justify-between cursor-pointer text-center p-3",
            {
              active: selectType === "direct_mail",
            }
          )}
        >
          <p className="font-bold">Direct Mail</p>
          <DirectMailIcon />
          <p></p>
        </div>
        <div
          onClick={() => {
            setSelectType("digital_offer");
            navigate("/campaigns/type?type=digital_offer");
          }}
          className={classNames(
            "digital-offer-camp max-w-[135px] w-full min-h-[100px] flex flex-col items-center justify-between cursor-pointer text-center p-3",
            {
              active: selectType === "digital_offer",
            }
          )}
        >
          <p className="font-bold">Digital Offer</p>
          <DigitalOfferIcon />
          <p></p>
        </div>

        <div
          onClick={() => {
            setSelectType("print_ad");
            navigate("/campaigns/type?type=print_ad");
          }}
          className={classNames(
            "print-ad-camp  max-w-[135px] w-full min-h-[100px] flex flex-col items-center justify-between cursor-pointer text-center p-3",
            {
              active: selectType === "print_ad",
            }
          )}
        >
          <p className="font-bold">Print Ad</p>
          <PrintAdIcon />
          <p></p>
        </div>

        <div
          className={classNames(
            "email-camp  max-w-[135px] w-full min-h-[100px] flex flex-col items-center justify-between text-center p-3"
          )}
        >
          <p className="font-bold">Email</p>
          <EmailIcon />
          <p className="font-light text-sm">Coming soon..</p>
        </div>

        <div
          className={classNames(
            "sms-camp  max-w-[135px] w-full min-h-[100px] flex flex-col items-center justify-between  text-center p-3"
          )}
        >
          <p className="font-bold">SMS</p>
          <SmsIcon />
          <p className="font-light text-sm">Coming soon..</p>
        </div>
      </div>
      <div className="flex justify-center gap-x-3 mt-6">
        <button
          onClick={() => navigate(`/campaigns/add/${selectType}`)}
          className="btn btn-gray"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CampaignTypes;
