import LoaderCommon from "components/common/fields/LoaderCommon";

const DashboardMatrixTop = ({
  dashboardData = {},
  dashboardDataLoading = false,
}) => {
  const dashboardMatrixData = dashboardData?.locationMetrics ?? {};
  return (
    <div className="flex md:gap-y-2 sm:gap-y-2 mt-4 min-h-[112px] 2xl:justify-between xl:gap-x-4 lg:gap-x-2 md:gap-x-4 sm:gap-x-1">
      <div className=" w-full bg-gradient-to-b from-[#f35288] to-[#f186ac] text-center p-3 border-2 border-gray-400">
        <p>CUSTOMERS</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon size={25} />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl  mb-2 mt-2 text-center">
                {dashboardMatrixData?.customers?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.customers?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>
      <div className=" w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
        <p>PROSPECTS</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardMatrixData?.prospects?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.prospects?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>
      <div className=" w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af]  text-center p-3 border-2 border-gray-400">
        <p>LEADS</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardMatrixData?.leads?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.leads?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>

      <div className=" w-full bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-center  p-3 border-2 border-gray-400">
        <p>AVG. RATING</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardMatrixData?.ratingAverage?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.ratingAverage?.increment ?? 0}%)
              </p>
            </div>
          )}
        </div>
      </div>
      <div className=" w-full bg-gradient-to-b from-[#f35288] to-[#f186ac]  text-center  p-3 border-2 border-gray-400">
        <p>EMAILS</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2 break-words">
                {dashboardMatrixData?.emails?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.emails?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>
      <div className=" w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
        <p>PHONES</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2 break-words">
                {dashboardMatrixData?.phone?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.phone?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>
      <div className=" w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af]  text-center p-3 border-2 border-gray-400">
        <p>ADDRESSES</p>
        <div className="text-center flex justify-center w-full">
          {dashboardDataLoading ? (
            <LoaderCommon />
          ) : (
            <div>
              <p className="font-medium xl:text-3xl md:text-2xl mb-2 mt-2">
                {dashboardMatrixData?.address?.count ?? 0}
              </p>
              <p className="text-gray-900">
                {" "}
                (+ {dashboardMatrixData?.address?.increment ?? 0})
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardMatrixTop;
