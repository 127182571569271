const NoDataRow = ({ colSpan, noDataMsg = "" }) => {
  return (
    <tr className="hover:bg-gray-100">
      <td
        className="py-4 px-6 text-sm opacity-75  text-center whitespace-nowrap"
        colSpan={colSpan}
      >
        {noDataMsg}
      </td>
    </tr>
  );
};

export default NoDataRow;
