import UserTable from "components/account/location/UserTable";

const UserTabs = (props) => {
  const { locationId } = props;

  return (
    <div>
      <ul className="flex  flex-wrap text-sm font-medium text-center py-2 text-gray-500 border-b border-gray-200">
        <li className="mr-2">
          <span
            onClick={() => {}}
            aria-current="page"
            className="cursor-pointer text-gray-700 border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 mb-2 py-2 px-1 text-[15px]"
          >
            Users
          </span>
        </li>
      </ul>

      <div className="mb-6">
        <div
          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-users"
          role="tabpanel"
          aria-labelledby="tabs-users-tab"
          data-te-tab-active
        >
          <UserTable locationId={locationId} />
        </div>
      </div>
    </div>
  );
};

export default UserTabs;
