import classNames from "classnames";
import ExpiredList from "components/contacts/customer/campaign/ExpiredList";
import RedeemList from "components/contacts/customer/campaign/RedeemList";
import RedeemNotList from "components/contacts/customer/campaign/RedeemNotList";
import { useState } from "react";

const CampaignForm = (props) => {
  const { getCustomerReportData = {} } = props;

  const [openTab, setOpenTab] = useState(1);

  const campaignDetails = getCustomerReportData?.CampaignDetails ?? [];

  const customersTabs = [
    { name: "Total Redeem", value: 1, href: "#", current: false },
    { name: "Total Pending", value: 2, href: "#", current: false },
    { name: "Total Expired", value: 3, href: "#", current: false },
  ];

  return (
    <div className="border border-gray-200 p-3 bg-white">
      <div className="section-1 flex gap-x-3 mt-6">
        <div className="flex flex-1  gap-x-6">
          <div className="min-w-[100px] bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-center   p-3 border-2 border-gray-400">
            <p>LIFETIME SPEND</p>
            <p className="font-bold text-lg mb-2 mt-2">
              ${getCustomerReportData?.TotalAmount ?? 0}
            </p>
          </div>
          <div className="min-w-[100px] bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
            <p>AVERAGE SPEND</p>
            <p className="font-bold text-lg mb-2 mt-2">
              ${getCustomerReportData?.AverageSpend ?? 0}
            </p>
          </div>
          <div className="min-w-[100px] bg-gradient-to-b from-[#a5d583] to-[#dcf1af]  text-center p-3 border-2 border-gray-400">
            <p>TOTAL REDEEMED</p>
            <p className="font-bold text-lg mb-2 mt-2">
              {campaignDetails?.redeemed?.length ?? 0}
            </p>
          </div>
          <div className="min-w-[100px] bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-center  p-3 border-2 border-gray-400">
            <p>TOTAL PENDING</p>
            <p className="font-bold text-lg mb-2 mt-2">
              {campaignDetails?.notRedeemed?.length ?? 0}
            </p>
          </div>
          <div className="min-w-[100px] bg-gradient-to-b from-[#f35288] to-[#f186ac]  text-center  p-3 border-2 border-gray-400">
            <p>TOTAL EXPIRED</p>
            <p className="font-bold text-lg mb-2 mt-2">
              {campaignDetails?.expired?.length ?? 0}
            </p>
          </div>
        </div>
        {/* <div className="flex flex-2 justify-between gap-x-6">
          <div className="min-w-[135px] bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-center  p-3 border-2 border-gray-400">
            <p>COST</p>
            <p className="font-bold text-lg mb-2 mt-2">
              {getCustomerReportData?.cost ?? 0}
            </p>
          </div>
          <div className="min-w-[135px] bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
            <p>ROI</p>
            <p className="font-bold text-lg mb-2 mt-2">
              {getCustomerReportData?.roi ?? 0}
            </p>
          </div>
        </div> */}
      </div>
      <div className="section-2 mt-12">
        <div className="border-b border-collapse border-gray-300 w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {customersTabs.map((tab, index) => {
              return (
                <span
                  key={tab.name}
                  onClick={() => {
                    setOpenTab(tab.value);
                  }}
                  className={classNames(
                    " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                    {
                      "border-pink-600 hover:border-pink-600 text-gray-800":
                        tab.value === openTab,
                      "border-transparent": tab.value !== openTab,
                    }
                  )}
                  aria-current={tab.value === openTab ? "page" : undefined}
                >
                  {tab.name}
                </span>
              );
            })}
          </nav>
        </div>
        <div className="p-3 mt-4 ">
          <div className={openTab === 1 ? "block" : "hidden"}>
            {openTab === 1 && <RedeemList data={campaignDetails} />}
          </div>
          <div className={openTab === 2 ? "block" : "hidden"}>
            {openTab === 2 && <RedeemNotList data={campaignDetails} />}
          </div>
          <div className={openTab === 3 ? "block" : "hidden"}>
            {openTab === 3 && <ExpiredList data={campaignDetails} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
