import GoogleMapComponent from "components/common/fields/GoogleMapComponent";
import {
  calculateDensity,
  convertLatLongObj,
  getUserDataByParam,
  isValidArray,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getCustomHeatmapData } from "redux/dashboard/getCustomHeatmapDataSlice";

const GoogleMapMain = ({
  lat_long = {},
  currentLocationId = 0,
  dashboardDataLoading = false,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setOption] = useState("all");
  const [filteredHeatMapData, setHeatMapData] = useState([]);
  const [maxDensityPoint, setMaxDensityPoint] = useState(null);
  const companyId = getUserDataByParam("COMPANY_ID");

  const getCustomHeatmapLoading = useSelector(
    (state) => state?.getCustomHeatmapData?.loading ?? false
  );

  const optionArray = [
    { label: "All", value: "all" },
    { label: "Customers", value: "customer" },
    { label: "Prospects", value: "prospect" },
    { label: "Leads", value: "lead" },
    { label: "Top Customers", value: "topCustomer" },
  ];

  const defaultLatLong = maxDensityPoint
    ? maxDensityPoint
    : lat_long
    ? convertLatLongObj(lat_long)
    : {
        lat: 32.640054,
        lng: -117.084195,
      };

  const handleHeatMapData = (value) => {
    setOption(value);
    const formData = {
      companyId,
      currentLocationId,
      type: value,
    };
    dispatch(getCustomHeatmapData(formData)).then((data) => {
      if (isValidArray(data?.payload)) {
        const coordinates = data?.payload ?? [];
        const radiusKm = 1.0;
        let currentMaxDensity = 0;
        let currentMaxDensityPoint = null;

        for (const point of coordinates) {
          const density = calculateDensity(point, coordinates, radiusKm);
          if (density > currentMaxDensity) {
            currentMaxDensity = density;
            currentMaxDensityPoint = point;
          }
        }
        setMaxDensityPoint(currentMaxDensityPoint);
        setHeatMapData(data?.payload);
      } else {
        setHeatMapData([]);
      }
    });
  };

  useEffect(() => {
    handleHeatMapData(selectedOption);
  }, []);

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <span className="text-lg text-gray-700 font-semibold">
          Google Heat Map
        </span>
        <div className="w-full flex  gap-x-1 items-center md:w-1/4 ">
          <span className="max-w-[40%] w-full">Contact Map Filter : </span>
          <Select
            className="w-full"
            name={"name"}
            value={optionArray.filter(function (option) {
              return option.value === selectedOption;
            })}
            isLoading={getCustomHeatmapLoading}
            onChange={(e) => handleHeatMapData(e.value)}
            options={optionArray}
            styles={{
              control: (baseStyles, { isFocused, isSelected }) => ({
                ...baseStyles,
                borderColor: isFocused ? "#64656680" : "#abadb180",
                borderRadius: 0,
              }),
              option: (baseStyles, { isSelected }) => ({
                ...baseStyles,
                backgroundColor: isSelected ? "#abadb180" : "#fff",
                color: "black",
                ":hover": {
                  backgroundColor: "#abadb180",
                },
              }),
              input: (baseStyles, { isFocused, isSelected }) => ({
                ...baseStyles,
                borderColor: isFocused ? "#64656680" : "#abadb180",
                ":hover": {
                  borderColor: "#64656680",
                  border: "1px",
                },
              }),
            }}
          />
        </div>
      </div>
      <div className="mt-4 border-2 border-gray-400">
        {!dashboardDataLoading ? (
          <GoogleMapComponent
            latLong={defaultLatLong}
            heatMapData={
              isValidArray(filteredHeatMapData) ? filteredHeatMapData : []
            }
            isHeatMap={true}
            isDashboard={true}
          />
        ) : (
          <div className="w-full h-[700px] flex justify-center items-center">
            Google Map is loading.....
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleMapMain;
