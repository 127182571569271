import { combineReducers, configureStore } from "@reduxjs/toolkit";
import addCompanyUserSlice from "redux/account/addCompanyUserSlice";
import getCompanyAccountDetailSlice from "redux/account/getCompanyAccountDetailSlice";
import getCompanyUserListSlice from "redux/account/getCompanyUserListSlice";
import updateCompanyAccountSlice from "redux/account/updateCompanyAccountSlice";
import updateCompanyUserSlice from "redux/account/updateCompanyUserSlice";
import addAdminUserSlice from "redux/adminUser/addAdminUserSlice";
import changeAdminUserStatusSlice from "redux/adminUser/changeAdminUserStatusSlice";
import getAdminUserDetailSlice from "redux/adminUser/getAdminUserDetailSlice";
import getAdminUserListSlice from "redux/adminUser/getAdminUserListSlice";
import updateAdminUserSlice from "redux/adminUser/updateAdminUserSlice";
import addCampaignSlice from "redux/campaign/addCampaignSlice";
import campaignEnquirySlice from "redux/campaign/campaignEnquirySlice";
import changeCampaignStatusSlice from "redux/campaign/changeCampaignStatusSlice";
import getCampaignDetailSlice from "redux/campaign/getCampaignDetailSlice";
import getCampaignListSlice from "redux/campaign/getCampaignListSlice";
import getCampaignReportSlice from "redux/campaign/getCampaignReportSlice";
import getDailyRedemptionSlice from "redux/campaign/getDailyRedemptionSlice";
import getSourceListSlice from "redux/campaign/getSourceListSlice";
import removeCustomerFromLeadSlice from "redux/campaign/removeCustomerFromLeadSlice";
import updateCampaignDetailSlice from "redux/campaign/updateCampaignDetailSlice";
import getLocationByUserSlice from "redux/common/getLocationByUserSlice";
import getMessagesSlice from "redux/common/getMessagesSlice";
import getProcessPercentageSlice from "redux/common/getProcessPercentageSlice";
import selectedLocationSlice from "redux/common/selectedLocationSlice";
import setBreadCrumbsSlice from "redux/common/setBreadCrumbsSlice";
import addCompanySlice from "redux/company/addCompanySlice";
import changeCompanyStatusSlice from "redux/company/changeCompanyStatusSlice";
import companyListSlice from "redux/company/companyListSlice";
import getCompanyDetailSlice from "redux/company/getCompanyDetailSlice";
import switchToCompanySlice from "redux/company/switchToCompanySlice";
import toggleCampaignsSlice from "redux/company/toggleCampaignsSlice";
import updateCompanyDetailSlice from "redux/company/updateCompanyDetailSlice";
import companySettingSlice from "redux/companySetting/companySettingSlice";
import getSquareLoginDetailSlice from "redux/companySetting/getSquareLoginDetailSlice";
import guestWifiToggleSlice from "redux/companySetting/guestWifiToggleSlice";
import saveSquareImportSlice from "redux/companySetting/saveSquareImportSlice";
import getAllContactsSlice from "redux/contacts/getAllContactsSlice";
import addCustomerSlice from "redux/customer/addCustomerSlice";
import addCustomerToLocationSlice from "redux/customer/addCustomerToLocationSlice";
import changeCustomerStatusSlice from "redux/customer/changeCustomerStatusSlice";
import customerListSlice from "redux/customer/customerListSlice";
import deleteImportedCustomersSlice from "redux/customer/deleteImportedCustomersSlice";
import exportCustomersSlice from "redux/customer/exportCustomersSlice";
import getCustomerDetailSlice from "redux/customer/getCustomerDetailSlice";
import getCustomerReportSlice from "redux/customer/getCustomerReportSlice";
import getOrdersListSlice from "redux/customer/getOrdersListSlice";
import getVisitsListSlice from "redux/customer/getVisitsListSlice";
import importCustomerDataSlice from "redux/customer/importCustomerDataSlice";
import importedCustomersListSlice from "redux/customer/importedCustomersListSlice";
import updateCustomerSlice from "redux/customer/updateCustomerSlice";
import dashboardSlice from "redux/dashboard/dashboardSlice";
import getCustomHeatmapDataSlice from "redux/dashboard/getCustomHeatmapDataSlice";
import addWifiDataSlice from "redux/front/addWifiDataSlice";
import getDetailByLocationSlice from "redux/front/getDetailByLocationSlice";
import loginSlice from "redux/front/loginSlice";
import logoutSlice from "redux/front/logoutSlice";
import registerSlice from "redux/front/registerSlice";
import resendVerificationCodeSlice from "redux/front/resendVerificationCodeSlice";
import resetPasswordSlice from "redux/front/resetPasswordSlice";
import setNewPasswordSlice from "redux/front/setNewPasswordSlice";
import verifyEmailSlice from "redux/front/verifyEmailSlice";
import verifyOtpForWIfiSlice from "redux/front/verifyOtpForWIfiSlice";
import ImportBulkLeadSlice from "redux/leadList/ImportBulkLeadSlice";
import deleteProspectSlice from "redux/leadList/deleteProspectSlice";
import getLeadListsSlice from "redux/leadList/getLeadListsSlice";
import getLeadSourcesSlice from "redux/leadList/getLeadSourcesSlice";
import getLocationsByCompanySlice from "redux/leadList/getLocationsByCompanySlice";
import getProspectsListSlice from "redux/leadList/getProspectsListSlice";
import leadListStatusChangeSlice from "redux/leadList/leadListStatusChangeSlice";
import addLocationSlice from "redux/location/addLocationSlice";
import changeLocationStatusSlice from "redux/location/changeLocationStatusSlice";
import changeLocationUserStatusSlice from "redux/location/changeLocationUserStatusSlice";
import getLocationDetailSlice from "redux/location/getLocationDetailSlice";
import getLocationUserDetailSlice from "redux/location/getLocationUserDetailSlice";
import getLocationUserListSlice from "redux/location/getLocationUserListSlice";

import locationListSlice from "redux/location/locationListSlice";
import updateLocationDetailSlice from "redux/location/updateLocationDetailSlice";
import submitQuerySlice from "redux/marketplace/submitQuerySlice";
import getAllCouponsSlice from "redux/redemption/getAllCouponsSlice";
import getAllOrdersSlice from "redux/redemption/getAllOrdersSlice";
import getDetailsByTrackIdSlice from "redux/redemption/getDetailsByTrackIdSlice";
import redeemLeadSlice from "redux/redemption/redeemLeadSlice";
import deleteReputationSlice from "redux/reputation/deleteReputationSlice";
import generateUrlSlice from "redux/reputation/generateUrlSlice";
import getReputationListSlice from "redux/reputation/getReputationListSlice";
import getSettingsDetailsSlice from "redux/settings/getSettingsDetailsSlice";
import updateSettingsSlice from "redux/settings/updateSettingsSlice";
import getGmbSlice from "redux/reputation/getGmbSlice";
import addStarTemplateSlice from "redux/reputation/addStarTemplateSlice";
import getStarTemlpateSlice from "redux/reputation/getStarTemlpateSlice";
import deleteStarTemplateSlice from "redux/reputation/deleteStarTemplateSlice";
import updateStarTemplateSlice from "redux/reputation/updateStarTemplateSlice";
import getSquareLocationsSlice from "redux/companySetting/getSquareLocationsSlice";
import getGoogleLocationSlice from "redux/reputation/getGoogleLocationSlice";
import associateSquareLocationSlice from "redux/companySetting/associateSquareLocationSlice";
import getReviewTemplateSlice from "redux/reputation/getReviewTemplateSlice";
import getAssociateListSlice from "redux/reputation/getAssociateListSlice";
import refreshSquareTokenSlice from "redux/companySetting/refreshSquareTokenSlice";
import getTagsListSlice from "redux/companySetting/getTagsListSlice";
import addTagSlice from "redux/companySetting/addTagSlice";
import updateTagSlice from "redux/companySetting/updateTagSlice";
import deleteTagSlice from "redux/companySetting/deleteTagSlice";
import getTagDetailSlice from "redux/companySetting/getTagDetailSlice";
import revokeSquareTokenSlice from "redux/companySetting/revokeSquareTokenSlice";
import getStarReviewSlice from "redux/reputation/getStarReviewSlice";
import checkExpireTokenSlice from "redux/reputation/checkExpireTokenSlice";
import googleRefeshKeySlice from "redux/reputation/googleRefeshKeySlice";
import googleDisconnectSlice from "redux/reputation/googleDisconnectSlice";
import addTagToContactSlice from "redux/customer/addTagToContactSlice";
import replyReviewSlice from "redux/reputation/replyReviewSlice";
import deleteReviewReplySlice from "redux/reputation/deleteReviewReplySlice";
import deleteContactsSlice from "redux/contacts/deleteContactsSlice";
import exportContactsSlice from "redux/contacts/exportContactsSlice";
import getAiSuggestionSlice from "redux/reputation/getAiSuggestionSlice";
import getContactDetailSlice from "redux/customer/getContactDetailSlice";
import updateContactDetailSlice from "redux/customer/updateContactDetailSlice";
import getQuarantineSlice from "redux/reputation/getQuarantineSlice";
import getProspectReportSlice from "redux/customer/getProspectReportSlice";
import guestWifiOtpToggleSlice from "redux/companySetting/guestWifiOtpToggleSlice";
import updateSessionTimeoutSlice from "redux/companySetting/updateSessionTimeoutSlice";
const rootReducer = combineReducers({
  login: loginSlice,
  register: registerSlice,
  logout: logoutSlice,
  locationList: locationListSlice,
  companyList: companyListSlice,
  switchToCompany: switchToCompanySlice,
  getCompanyAccountDetail: getCompanyAccountDetailSlice,
  getCompanyUserList: getCompanyUserListSlice,
  updateCompanyAccount: updateCompanyAccountSlice,
  getLocationDetail: getLocationDetailSlice,
  getLocationUserList: getLocationUserListSlice,
  addLocation: addLocationSlice,
  updateLocationDetail: updateLocationDetailSlice,
  getLocationUserDetail: getLocationUserDetailSlice,
  updateCompanyUser: updateCompanyUserSlice,
  addCompanyUser: addCompanyUserSlice,
  customerList: customerListSlice,
  addCustomer: addCustomerSlice,
  getCustomerDetail: getCustomerDetailSlice,
  updateCustomer: updateCustomerSlice,
  getAdminUserList: getAdminUserListSlice,
  addAdminUser: addAdminUserSlice,
  getAdminUserDetail: getAdminUserDetailSlice,
  updateAdminUser: updateAdminUserSlice,
  changeCompanyStatus: changeCompanyStatusSlice,
  changeAdminUserStatus: changeAdminUserStatusSlice,
  changeCustomerStatus: changeCustomerStatusSlice,
  changeLocationStatus: changeLocationStatusSlice,
  changeLocationUserStatus: changeLocationUserStatusSlice,
  getCompanyDetail: getCompanyDetailSlice,
  addCompany: addCompanySlice,
  updateCompanyDetail: updateCompanyDetailSlice,
  verifyEmail: verifyEmailSlice,
  importCustomerData: importCustomerDataSlice,
  getLocationByUser: getLocationByUserSlice,
  selectedLocation: selectedLocationSlice,
  ImportBulkLead: ImportBulkLeadSlice,
  getLeadLists: getLeadListsSlice,
  getLocationsByCompany: getLocationsByCompanySlice,
  leadListStatusChange: leadListStatusChangeSlice,
  getCampaignList: getCampaignListSlice,
  addCampaign: addCampaignSlice,
  changeCampaignStatus: changeCampaignStatusSlice,
  getCampaignDetail: getCampaignDetailSlice,
  resendVerificationCode: resendVerificationCodeSlice,
  updateCampaignDetail: updateCampaignDetailSlice,
  resetPassword: resetPasswordSlice,
  setNewPassword: setNewPasswordSlice,
  getSourceList: getSourceListSlice,
  getDetailsByTrackId: getDetailsByTrackIdSlice,
  redeemLead: redeemLeadSlice,
  getCampaignReport: getCampaignReportSlice,
  getCustomerReport: getCustomerReportSlice,
  getLeadSources: getLeadSourcesSlice,
  removeCustomerFromLead: removeCustomerFromLeadSlice,
  generateUrl: generateUrlSlice,
  getReputationList: getReputationListSlice,
  deleteReputation: deleteReputationSlice,
  addCustomerToLocation: addCustomerToLocationSlice,
  updateSettings: updateSettingsSlice,
  getSettingsDetails: getSettingsDetailsSlice,
  importedCustomersList: importedCustomersListSlice,
  deleteImportedCustomers: deleteImportedCustomersSlice,
  getProcessPercentage: getProcessPercentageSlice,
  submitQuery: submitQuerySlice,
  dashboard: dashboardSlice,
  getDailyRedemption: getDailyRedemptionSlice,
  getProspectsList: getProspectsListSlice,
  getBreadCrumbs: setBreadCrumbsSlice,
  getCustomHeatmapData: getCustomHeatmapDataSlice,
  campaignEnquiry: campaignEnquirySlice,
  companySetting: companySettingSlice,
  exportCustomers: exportCustomersSlice,
  getMessages: getMessagesSlice,
  deleteProspect: deleteProspectSlice,
  toggleCampaigns: toggleCampaignsSlice,
  addWifiData: addWifiDataSlice,
  getDetailByLocation: getDetailByLocationSlice,
  verifyOtpForWIfi: verifyOtpForWIfiSlice,
  guestWifiToggle: guestWifiToggleSlice,
  getVisitsList: getVisitsListSlice,
  getAllContacts: getAllContactsSlice,
  getOrdersList: getOrdersListSlice,
  getSquareLoginDetail: getSquareLoginDetailSlice,
  saveSquareImport: saveSquareImportSlice,
  getAllOrders: getAllOrdersSlice,
  getAllCoupons: getAllCouponsSlice,
  getGmb: getGmbSlice,
  addStarTemplate: addStarTemplateSlice,
  getStarTemplate: getStarTemlpateSlice,
  deleteStarTemplate: deleteStarTemplateSlice,
  updateStarTemplate: updateStarTemplateSlice,
  getSquareLocations: getSquareLocationsSlice,
  getGoogleLocation: getGoogleLocationSlice,
  associateSquareLocation: associateSquareLocationSlice,
  getReviewTemplate: getReviewTemplateSlice,
  getAssociateList: getAssociateListSlice,
  refreshSquareToken: refreshSquareTokenSlice,
  getTagsList: getTagsListSlice,
  addTag: addTagSlice,
  updateTag: updateTagSlice,
  deleteTag: deleteTagSlice,
  getTagDetail: getTagDetailSlice,
  revokeSquareToken: revokeSquareTokenSlice,
  getStarReview: getStarReviewSlice,
  checkExpireToken: checkExpireTokenSlice,
  googleRefreshKey: googleRefeshKeySlice,
  addTagToContact: addTagToContactSlice,
  googleDisconnect: googleDisconnectSlice,
  replyReview: replyReviewSlice,
  deleteReplyReview: deleteReviewReplySlice,
  deleteContacts: deleteContactsSlice,
  exportContacts: exportContactsSlice,
  getAiSuggestion: getAiSuggestionSlice,
  getContactDetail: getContactDetailSlice,
  updateContactDetail: updateContactDetailSlice,
  getQuarantine: getQuarantineSlice,
  getProspectReport: getProspectReportSlice,
  guestWifiOtpToggle: guestWifiOtpToggleSlice,
  updateSessionTimeout: updateSessionTimeoutSlice,
});

export default configureStore({
  reducer: rootReducer,
});
