import { MoonLoader } from "react-spinners";

const LoaderCommon = ({ color = "#FFFF", size = 25, marginTop = "15px" }) => {
  return (
    <MoonLoader
      cssOverride={{
        alignItems: "center",
        textAlign: "center",
        marginTop: marginTop,
      }}
      size={size}
      color={color}
      height={10}
      width={5}
      radius={5}
    />
  );
};

export default LoaderCommon;
