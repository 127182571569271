import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getCampaignDetail = createAsyncThunk(
  "campaign/detail",
  async (campaignId, { rejectWithValue }) => {
    try {
      const response = await getApi(`api/campaign/${campaignId}`);
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCampaignDetailSlice = createSlice({
  name: "getCampaignDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCampaignDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCampaignDetail.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getCampaignDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getCampaignDetailSlice.reducer;
