import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import {
  convertTimeStampToDateTime,
  formatPhoneNumberAlways,
  isValidArray,
  showPerPage,
} from "module/util";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getVisitsList } from "redux/customer/getVisitsListSlice";

const Visits = (props) => {
  const { data, customerId } = props;
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(12);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);

  const visitsListData = useSelector(
    (state) => state?.getVisitsList?.data?.dataObj ?? [],
    shallowEqual
  );
  const visitsListLoading = useSelector(
    (state) => state?.getVisitsList?.loading ?? false,
    shallowEqual
  );
  const currentPerPage = visitsListData?.per_page ?? showPerPage;

  const columns = [
    {
      Header: "Name",
      accessor: "customer",
      Cell: ({ cell }) => (
        <span>{cell?.row?.values?.customer?.first_name ?? ""}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "",
      Cell: ({ cell }) => (
        <span>{cell?.row?.values?.customer?.email ?? ""}</span>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => (
        <span>
          {formatPhoneNumberAlways(cell?.row?.values?.customer?.phone) ?? ""}
        </span>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ cell }) => <span>Guest Wifi</span>,
    },
    {
      Header: "cmd",
      accessor: "cmd",
    },
    {
      Header: "mac",
      accessor: "mac",
    },
    {
      Header: "network",
      accessor: "network",
    },
    {
      Header: "ip",
      accessor: "ip",
    },

    {
      Header: "apmac",
      accessor: "apmac",
    },
    {
      Header: "site",
      accessor: "site",
    },
    {
      Header: "post",
      accessor: "post",
    },
    {
      Header: "url",
      accessor: "url",
    },

    {
      Header: "Visit Date",
      accessor: "created_at",
      Cell: ({ cell }) => {
        return cell?.row?.values?.created_at
          ? convertTimeStampToDateTime(cell?.row?.values?.created_at)
          : "";
      },
    },
  ];

  useEffect(() => {
    dispatch(getVisitsList(customerId));
  }, []);

  return (
    <div className="">
      <ShowPageTitle title="customerVisit" />
      <div className="mt-4 flex flex-col">
        <CustomTable
          data={isValidArray(visitsListData?.data) ? visitsListData?.data : []}
          isLoading={visitsListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={visitsListData?.total ?? 0}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          isPaginationHide={false}
          colSpan={13}
        />
      </div>
    </div>
  );
};

export default Visits;
