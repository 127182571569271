import deleteIcon from "assets/images/deleteIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import {
  convertTimeStampToDate,
  getCompanyStatus,
  getCurrentLocation,
  isValidArray,
  isValidObject,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import LoaderCommon from "components/common/fields/LoaderCommon";
import { isNull } from "lodash";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { deleteImportedCustomers } from "redux/customer/deleteImportedCustomersSlice";
import { importedCustomersList } from "redux/customer/importedCustomersListSlice";

const ImportLogs = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const urlTab = searchParams.get("tab");
  const currentActiveTab = ["customers", "importedCustomers"].includes(urlTab)
    ? urlTab
    : "customers";

  let interval = null;
  let timeout = null;

  const [sortIndex, setSortIndex] = useState(6);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [archiveCustomerModalOpen, setArchiveCustomerModal] = useState(false);
  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const [customInterval, setCustomInterval] = useState(false);

  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const isCustomersTab = activeTab === "customers";

  const changeCustomerStatusLoading = useSelector(
    (state) => state?.changeCustomerStatus?.loading ?? false
  );
  const importedCustomerListData = useSelector(
    (state) => state?.importedCustomersList?.data ?? []
  );
  const importedCustomerListLoading = useSelector(
    (state) => state?.importedCustomersList?.loading ?? false
  );

  const totalRecords = importedCustomerListData?.total ?? 0;
  const currentPerPage = importedCustomerListData?.per_page ?? showPerPage;
  const totalCustomers = importedCustomerListData?.data ?? [];

  const { id: currentLocationId } = getCurrentLocation();

  const handleImportedCustomerList = (search) => {
    const { id: currentLocationId } = getCurrentLocation();

    const sortObj = {
      sort: ImportedCustomersColumns[sortIndex]?.accessor ?? 0,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      location_id: currentLocationId,
    };
    if (currentLocationId) {
      dispatch(importedCustomersList(sortObj)).then((data) => {
        if (data?.payload && isValidArray(data?.payload?.data)) {
          const needToRefresh = data?.payload?.data?.find(
            (item) => item?.percent_processed != "100.00"
          );
          if (isValidObject(needToRefresh)) {
            setCustomInterval(true);
          } else {
            clearTimeout(timeout);
          }
        }
      });
    }
  };

  const handleCustomersListInterval = () => {
    const { id: currentLocationId } = getCurrentLocation();

    const sortObj = {
      sort: ImportedCustomersColumns[sortIndex]?.accessor ?? 0,
      order: sortOrder,
      search: searchInput,
      page: currentPage,
      perPage: perPage,
      location_id: currentLocationId,
    };
    if (currentLocationId) {
      dispatch(importedCustomersList(sortObj)).then((data) => {
        if (data?.payload && isValidArray(data?.payload?.data)) {
          const needToRefresh = data?.payload?.data?.find(
            (item) => item?.percent_processed != "100.00"
          );
          if (isValidObject(needToRefresh)) {
            setCustomInterval(!customInterval);
          }
        }
      });
    }
  };

  useEffect(() => {
    timeout = setTimeout(() => {
      handleCustomersListInterval();
    }, 25000);
    return () => clearTimeout(timeout);
  }, [customInterval]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: "Import Logs",
          href: "",
          current: false,
        },
      ])
    );
  }, [isCustomersTab]);

  const deleteImportedCustomer = () => {
    dispatch(deleteImportedCustomers(selectedId)).then((data) => {
      if (data?.payload?.code == 200) {
        setArchiveCustomerModal(false);
        showSuccessMsg(
          data?.payload?.message ?? "Record deleted successfully."
        );
        handleImportedCustomerList(searchInput);
      }
    });
  };

  const handleProcessingLoader = (data) => (
    <>
      {isNull(data) ? (
        <div className="flex items-center">
          <LoaderCommon color="#000" size={20} marginTop="0px" />
          <span className="ml-2">Processing...</span>
        </div>
      ) : data ? (
        data
      ) : (
        0
      )}
    </>
  );

  const ImportedCustomersColumns = [
    {
      Header: "File Name",
      accessor: "name",
    },
    {
      Header: "Processed Records",
      accessor: "total_records",
      Cell: ({ cell }) => (
        <>{handleProcessingLoader(cell?.row?.values?.total_records)}</>
      ),
    },
    {
      Header: "Invalid Records",
      accessor: "total_failed_validation",
      Cell: ({ cell }) => (
        <>
          {handleProcessingLoader(cell?.row?.values?.total_failed_validation)}
        </>
      ),
    },
    {
      Header: "Duplicate Records",
      accessor: "duplicate_records",
      Cell: ({ cell }) => (
        <>
          <div className="flex items-center">
            {cell?.row?.values?.percent_processed == "100.00" ? (
              <>{`${cell?.row?.values?.duplicate_records}`}</>
            ) : (
              <span className="ml-1">Processing...</span>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Inserted Records",
      accessor: "total_inserted",
      Cell: ({ cell }) => (
        <>{handleProcessingLoader(cell?.row?.values?.total_inserted)}</>
      ),
    },
    {
      Header: "Lead Match(s)",
      accessor: "lead_match_count",
      Cell: ({ cell }) => (
        <>{handleProcessingLoader(cell?.row?.values?.lead_match_count)}</>
      ),
    },
    {
      Header: "Date Added",
      accessor: "created_at",
      Cell: ({ cell }) => (
        <span>{convertTimeStampToDate(cell?.row?.values?.created_at)}</span>
      ),
    },
    {
      Header: "Status",
      accessor: "percent_processed",
      Cell: ({ cell }) => (
        <div className="flex items-center">
          {cell?.row?.values?.percent_processed == "100.00" ? (
            <>{`${cell?.row?.values?.percent_processed ?? 0}%`}</>
          ) : (
            <>
              <LoaderCommon color="#000" size={20} marginTop="0px" />
              <span className="ml-1">{`${
                cell?.row?.values?.percent_processed ?? 0
              }%`}</span>
              <span className="ml-1">Processing...</span>
            </>
          )}
        </div>
      ),
    },
    ...(isCompanyActive
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "customer_import_id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-x-2 ">
                <img
                  alt="archive"
                  src={deleteIcon}
                  className={`h-5 w-5 ${
                    cell?.row?.values?.percent_processed == "100.00"
                      ? `cursor-pointer`
                      : `opacity-40 cursor-not-allowed`
                  }`}
                  onClick={() => {
                    if (cell?.row?.values?.percent_processed == "100.00") {
                      setSelectedId(cell.row.values?.customer_import_id);
                      setArchiveCustomerModal(true);
                    }
                  }}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const globalSearch = (search) => {
    clearTimeout(timeout);
    handleImportedCustomerList(search);
  };

  useEffect(() => {
    clearTimeout(timeout);
    handleImportedCustomerList(searchInput);

    return () => {
      clearInterval(interval);
    };
  }, [sortOrder, currentPage, perPage, currentLocationId, activeTab]);

  return (
    <div className="mt-[-8px]">
      <ShowPageTitle title="contactsImportLog" />

      <div className="flex justify-end items-center mt-2">
        <div className="ml-2 flex shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            companyStatus={activeTab}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <CustomTable
          data={totalCustomers ?? []}
          columns={ImportedCustomersColumns}
          isLoading={importedCustomerListLoading}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          colSpan={10}
        />
      </div>
      {archiveCustomerModalOpen && (
        <ConfirmationModal
          isModalOpen={archiveCustomerModalOpen}
          setModalOpen={setArchiveCustomerModal}
          handleAction={() => {
            deleteImportedCustomer();
          }}
          isLoading={changeCustomerStatusLoading}
        />
      )}
    </div>
  );
};

export default ImportLogs;
