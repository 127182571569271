import Logo from "assets/images/logo-pendio.png";
import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import {
  getUserTempEmail,
  removeUserTempEmail,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resendVerificationCode } from "redux/front/resendVerificationCodeSlice";
import { verifyEmail } from "redux/front/verifyEmailSlice";

const VerifySecurityCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [{ isLoading = false, isLoadingResentCode = false }] = useSelector(
    (state) => [
      {
        isLoading: state?.verifyEmail?.loading,
        isLoadingResentCode: state?.resendVerificationCode?.loading,
      },
    ]
  );

  const isOtpValid = otp?.length === 6;

  const handleConfirmSecurityCode = () => {
    if (isOtpValid && !!token) {
      dispatch(verifyEmail({ token: token, security_code: otp })).then(
        (data) => {
          if (data?.payload?.code === 200) {
            showSuccessMsg(data?.payload?.dataObj?.message);
            navigate("/login");
            removeUserTempEmail();
          } else {
            showErrorMsg(data?.payload?.dataObj?.message ?? "");
          }
        }
      );
    } else {
      if (!!!token) {
        showErrorMsg("Please enter valid token.");
      } else {
        showErrorMsg("Please enter valid OTP");
      }
    }
  };
  const handleChange = (code) => setOtp(code);

  const handleResendOTP = () => {
    const userEmail = getUserTempEmail();
    dispatch(resendVerificationCode({ email: userEmail })).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(data?.payload?.dataObj?.message);
      } else {
        showErrorMsg(data?.payload?.dataObj?.message);
      }
    });
  };

  return (
    <div className="flex flex-col justify-center sm:px-6 lg:px-8 mt-[5%]">
      <ShowPageTitle title="login" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-30 w-auto" src={Logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Verify Security Code
        </h2>
      </div>

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-4 shadow shadow-gray-400 sm:px-12">
          <div className="space-y-4">
            <div className="flex items-center justify-center">
              <label
                htmlFor="remember-me"
                className="ml-3 block text-md   leading-6 text-gray-900"
              >
                Please check your email for verification code.
              </label>
            </div>

            <div className="flex items-center justify-center mt-5">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                inputType="tel"
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid",
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  marginLeft: "5px",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <div className="">
              <SpinnerButton
                className={classNames(
                  "mt-4 flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                  {
                    "bg-pink-600 hover:bg-pink-500": isOtpValid,
                    "bg-pink-400": !isOtpValid,
                  }
                )}
                title={"Confirm Code"}
                action={handleConfirmSecurityCode}
                type={"button"}
                loading={isLoading}
                isDisable={!isOtpValid}
              />
            </div>
          </div>

          <div></div>
        </div>

        <p className="mt-2 flex items-center justify-center gap-x-1  ">
          <span className="text-sm text-gray-800">
            If you not receive the code
          </span>{" "}
          <SpinnerButton
            className="font-medium text-sm py-1 px-1 bg-pink-600 text-white shadow-sm shadow-gray-300 hover:bg-pink-700"
            title={"Resend"}
            action={handleResendOTP}
            type={"button"}
            loading={isLoadingResentCode}
          />
        </p>
      </div>
    </div>
  );
};

export default VerifySecurityCode;
