import deleteIcon from "assets/images/deleteIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import { showPerPage, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLocationUserStatus } from "redux/location/changeLocationUserStatusSlice";
import { getLocationUserList } from "redux/location/getLocationUserListSlice";

const UserTable = (props) => {
  const { locationId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const getLocationUserListData = useSelector(
    (state) => state?.getLocationUserList?.data?.data ?? {}
  );
  const locationUserLoading = useSelector(
    (state) => state?.getLocationUserList?.loading ?? false
  );
  const changeLocationUserStatusLoading = useSelector(
    (state) => state?.changeLocationUserStatus?.loading ?? false
  );

  const totalLocationUsers = getLocationUserListData?.data ?? [];
  const totalRecords = getLocationUserListData?.total ?? 0;
  const currentPerPage = getLocationUserListData?.per_page ?? showPerPage;

  const handleLocationsList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      location_id: locationId,
    };

    dispatch(getLocationUserList(sortObj));
  };

  const handleChangeLocationUserStatus = () => {
    dispatch(
      changeLocationUserStatus({ id: selectedId, status: "archived" })
    ).then((data) => {
      if (data?.payload?.code == 200) {
        setModalOpen(false);
        showSuccessMsg(
          data?.payload?.message ?? "Location user deleted successfully."
        );
        handleLocationsList(searchInput);
      } else {
        setModalOpen(false);
        handleServerValidation();
        handleLocationsList(searchInput);
      }
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Last Login",
      accessor: "lastLogin",
    },

    {
      width: 100,
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ cell }) => (
        <div className="flex items-center gap-x-2 ">
          <img
            alt="archive"
            src={deleteIcon}
            className="h-5 w-5 hover:cursor-pointer"
            onClick={() => {
              setSelectedId(cell.row.values.id);
              setModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  const globalSearch = (search) => {
    handleLocationsList(search);
  };

  useEffect(() => {
    handleLocationsList(searchInput);
  }, [sortOrder, currentPage, perPage]);

  return (
    <div className="">
      <div className="flex justify-end  gap-x-2 mt-2 mb-2">
        <button
          type="button"
          onClick={() => navigate("/account/users/add")}
          className="btn-pink"
        >
          Add user
        </button>
        <div className="sm:ml-2 flex rounded-md shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>
      <CustomTable
        data={totalLocationUsers}
        columns={columns}
        isLoading={locationUserLoading}
        defaultSortIndexColumn={sortIndex}
        defaultOrder={sortOrder}
        setSortIndex={setSortIndex}
        setSortOrder={setSortOrder}
        totalRecords={totalRecords}
        perPage={perPage ? perPage : currentPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPerPage={setPerPage}
      />
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleChangeLocationUserStatus}
          isLoading={changeLocationUserStatusLoading}
        />
      )}
    </div>
  );
};

export default UserTable;
