import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetData, getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getLocationsByCompany = createAsyncThunk(
  "Leads/list",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await getApi(`api/company-locations/all/${companyId}`);
      return apiGetData(response?.data?.dataObj);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getLocationsByCompanySlice = createSlice({
  name: "getLocationsByCompany",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocationsByCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationsByCompany.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getLocationsByCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getLocationsByCompanySlice.reducer;
