import { pageTitles } from "module/data";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ShowPageTitle = ({ title }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const showTitle = pageTitles?.[title] ?? "";
    document.title = showTitle ? `Pendio | ${showTitle}` : "Pendio";
  }, [navigate]);

  return <div></div>;
};

export default ShowPageTitle;
