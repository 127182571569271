import classNames from "classnames";
import AccountForm from "components/account/AccountForm";
import UserForm from "components/account/UserForm";
import UserList from "components/account/UserList";
import LocationForm from "components/account/location/LocationForm";
import LocationList from "components/account/location/LocationList";
import ShowPageTitle from "components/common/ShowPageTitle";
import {
  createFirstLetterCapital,
  getCompanyStatus,
  getUserData,
  isCompanyUser,
  isNoLocationExist,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyAccountDetail } from "redux/account/getCompanyAccountDetailSlice";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [handleTabForm, setTabForm] = useState({ tab: 1, isFormOpen: 0 });
  const [companyStatus, setCompanyStatus] = useState("");

  const currentUserData = getUserData() ? JSON.parse(getUserData()) : "";
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  useEffect(() => {
    if (isCompanyUser()) {
      dispatch(getCompanyAccountDetail(currentUserData?.company_id)).then(
        (data) => {
          if (data?.payload?.status) {
            setCompanyStatus(data?.payload?.status);
          }
        }
      );
    }
  }, []);

  const pathName = location.pathname;

  useEffect(() => {
    if (pathName.includes("/account/locations")) {
      if (
        pathName.includes("/account/locations/add") ||
        pathName.includes("/account/locations/edit")
      ) {
        setTabForm({ tab: 2, isFormOpen: 1 });
      } else {
        setTabForm({ tab: 2, isFormOpen: 0 });
      }
    } else if (pathName.includes("/account/users")) {
      if (
        pathName.includes("/account/users/add") ||
        pathName.includes("/account/users/edit")
      ) {
        setTabForm({ tab: 3, isFormOpen: 1 });
      } else {
        setTabForm({ tab: 3, isFormOpen: 0 });
      }
    } else {
      setTabForm({ tab: 1, isFormOpen: 0 });
    }
  }, [navigate]);

  const AccountsTabs = [
    { name: "Account", value: 1, href: "/account", current: false },
    ...(isCompanyActive
      ? [
          {
            name: "Locations",
            value: 2,
            href: "/account/locations",
            current: false,
          },
        ]
      : []),
    ...(isCompanyActive && !isNoLocationExist()
      ? [{ name: "Users", value: 3, href: "/account/users", current: true }]
      : []),
  ];

  return (
    <div>
      <ShowPageTitle title="account" />
      <div className="flex">
        <div className="border-b flex justify-between border-collapse border-gray-300 w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {AccountsTabs.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  navigate(tab.href);
                }}
                className={classNames(
                  "cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === handleTabForm?.tab,
                    "border-transparent": tab.value !== handleTabForm?.tab,
                  }
                )}
                aria-current={
                  tab.value === handleTabForm?.tab ? "page" : undefined
                }
              >
                {tab.name}
              </span>
            ))}
          </nav>
          <div className="flex flex-2 items-center">
            {handleTabForm.tab === 1 && (
              <ul className="ml-3">
                <li className="text-right text-md font-semibold text-gray-700">
                  Status :{" "}
                  <span
                    className={classNames(
                      "py-1.5 px-2 text-white text-md font-medium rounded-sm",
                      {
                        "bg-green-500": companyStatus === "active",
                        "bg-red-600": companyStatus !== "active",
                      }
                    )}
                  >
                    {createFirstLetterCapital(companyStatus)}
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="">
        <div className={handleTabForm.tab === 1 ? "block" : "hidden"}>
          {handleTabForm.tab === 1 && <AccountForm />}
        </div>
        <div className={handleTabForm.tab === 2 ? "block" : "hidden"}>
          {handleTabForm.tab === 2 &&
            (handleTabForm.isFormOpen ? <LocationForm /> : <LocationList />)}
        </div>
        <div className={handleTabForm.tab === 3 ? "block" : "hidden"}>
          {handleTabForm.tab === 3 &&
            (handleTabForm.isFormOpen ? <UserForm /> : <UserList />)}
        </div>
      </div>
    </div>
  );
};

export default Account;
