import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";

const ShowLeadCountsModal = (props) => {
  const { isModalOpen, setModalOpen, importedLeadData } = props;
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex justify-between">
                  <span className="text-lg font-bold">
                    Imported Leads Details
                  </span>
                  <button
                    type="button"
                    className=" bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setModalOpen(false);
                      navigate("/lead-list");
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="my-4">
                  <div className="mb-3">
                    <p>
                      <span className="font-semibold">Total Counts :- </span>
                      <span>{importedLeadData?.total_count ?? 0}</span>{" "}
                    </p>
                    <p>
                      <span className="font-semibold">Summary :-</span>{" "}
                      <span>Your imports are being processed...</span>
                    </p>
                  </div>
                </div>
                <div className="float-right">
                  {
                    <SpinnerButton
                      className="inline-flex  justify-center  bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2"
                      type="button"
                      action={() => {
                        setModalOpen(false);
                        navigate("/lead-list");
                      }}
                      title="Okay"
                      loading={false}
                    />
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShowLeadCountsModal;
