import { getAuthToken } from "module/util";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NoMatch = () => {
  const navigate = useNavigate();

  const isLoggedIn = !!getAuthToken();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      navigate("/dashboard");
    }
  }, [navigate]);

  return <div>NoMatch</div>;
};

export default NoMatch;
