import { isValidArray, showPerPage } from "module/util";
import {
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import LoaderTable from "components/common/fields/LoaderTable";
import NoDataRow from "components/common/fields/NoDataRow";
import Pagination from "components/common/fields/Pagination";
import { useEffect, useMemo } from "react";

const CustomTable = ({
  data = [],
  columns = [],
  isLoading = false,
  defaultSortIndexColumn = 0,
  defaultOrder = 0,
  setSortIndex = () => {},
  setSortOrder = () => {},
  totalRecords = 0,
  perPage = showPerPage,
  setCurrentPage = () => {},
  currentPage = 1,
  setPerPage = () => {},
  isPaginationHide = false,
  colSpan = 10,
  noDataMsg = "No Data Found!",
  onRowSelect = () => {},
  isColumnSelectionRequired = false,
  setIsAllChecked = () => {},
  isAllChecked = false,
  setSelectedRowIds = () => {},
  selectedRowIds = [],
  setSelectType = () => {},
  selectType = { type: "NONE", page: [] },
  setUnSelectedRowIds = () => {},
  unSelectedRowIds = [],
  isCompanyActive = false,
}) => {
  const handleRowSelection = (e, rowId) => {
    if (e.target.checked) {
      setSelectType({ ...selectType, type: "CURRENT_ROW" });
      setUnSelectedRowIds(() => {
        const uniqueIds = unSelectedRowIds.filter((id) => id === rowId);
        return [...uniqueIds];
      });
      setSelectedRowIds((prevSelectedRowIds) =>
        prevSelectedRowIds.includes(rowId)
          ? prevSelectedRowIds.filter((id) => id !== rowId)
          : [...prevSelectedRowIds, rowId]
      );
    } else {
      setSelectType({ ...selectType, type: "EXCEPT_CURRENT_ROW" });
      const unChecked = selectedRowIds.filter((id) => id === rowId);
      setUnSelectedRowIds((prevUnSelectedRowIds) => {
        const uniqueIds = new Set([...prevUnSelectedRowIds, ...unChecked]);
        return [...uniqueIds];
      });
      setSelectedRowIds((prevSelectedRowIds) =>
        prevSelectedRowIds.includes(rowId)
          ? prevSelectedRowIds.filter((id) => id !== rowId)
          : [...prevSelectedRowIds, rowId]
      );
    }
  };

  const handleRowAllSelection = (e) => {
    const totalData = data?.map((item) => item.id);
    const unChecked = selectedRowIds.filter((id) => totalData?.includes(id));
    if (e.target.checked) {
      setSelectType({ ...selectType, type: "CURRENT_PAGE" });
      setSelectedRowIds((prevSelectedRowIds) => {
        const uniqueIds = new Set([...prevSelectedRowIds, ...totalData]);
        return [...uniqueIds];
      });
      setUnSelectedRowIds((prevUnSelectedRowIds) => {
        const uniqueIds = new Set([...prevUnSelectedRowIds, ...unChecked]);
        return [...uniqueIds];
      });
    } else {
      setIsAllChecked(false);
      setSelectType({ ...selectType, type: "EXCEPT_CURRENT_PAGE" });
      setUnSelectedRowIds((prevUnSelectedRowIds) => {
        const uniqueIds = new Set([...prevUnSelectedRowIds, ...unChecked]);
        return [...uniqueIds];
      });
      setSelectedRowIds(() => {
        const uniqueIds = selectedRowIds.filter(
          (id) => !totalData?.includes(id)
        );
        return [...uniqueIds];
      });
    }
  };

  useEffect(() => {
    if (
      selectType?.type === "ALL" ||
      selectType?.type === "CURRENT_PAGE" ||
      selectType?.type === "CURRENT_ROW"
    ) {
      const dataIds = data?.map((data) => data.id);
      const isChecked = dataIds?.every((id) => selectedRowIds?.includes(id));
      setIsAllChecked(isChecked);
    } else if (selectType?.type === "EXCEPT_CURRENT_ROW") {
      const dataIds = data?.map((data) => data.id);
      const isChecked = dataIds?.every((id) => selectedRowIds?.includes(id));
      setIsAllChecked(isChecked);
    } else if (selectType?.type === "NONE") {
      setIsAllChecked(false);
    }
  }, [selectedRowIds, data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      enableRowSelection: true,
      selectedRowIds,
      // onRowSelect: handleRowSelection,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      isColumnSelectionRequired &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }) => {
              return (
                <div>
                  <IndeterminateCheckbox
                    indeterminate={false}
                    {...getToggleAllRowsSelectedProps()}
                    isAll={true}
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              return (
                <div>
                  <IndeterminateCheckbox
                    indeterminate={false}
                    rowId={row.values.id}
                    selectedRowIds={selectedRowIds}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    selectedFlatRows,
  } = tableInstance;

  const IndeterminateCheckbox = ({
    rowId,
    isAll = false,
    selectedRowIds = [],
    ...rest
  }) => {
    return (
      <>
        {isAll ? (
          <input
            type="checkbox"
            className="checkbox-field"
            checked={isAllChecked}
            disabled={
              selectType?.type === "ALL" ||
              !isValidArray(rows) ||
              !isCompanyActive
            }
            onChange={(e) => {
              handleRowAllSelection(e);
            }}
          />
        ) : (
          <input
            type="checkbox"
            className="checkbox-field"
            checked={
              selectedRowIds.includes(rowId) && selectType?.type !== "NONE"
            }
            disabled={selectType?.type === "ALL" || !isCompanyActive}
            onChange={(e) => {
              handleRowSelection(e, rowId);
            }}
          />
        )}
      </>
    );
  };

  const generateSortingIndicator = (columnIndex, order) => {
    const Index = isColumnSelectionRequired ? columnIndex - 1 : columnIndex;
    let sameCol = Index === defaultSortIndexColumn;
    let checkOrderDesc = order === 1;
    return sameCol ? (checkOrderDesc ? "⬇" : "⬆") : "";
  };

  useMemo(() => {
    onRowSelect(selectedFlatRows?.map((row) => row.original.id));
  }, [selectedFlatRows, onRowSelect]);

  return (
    <>
      <div className="overflow-x-auto shadow-md overflow-y-auto max-h-[65vh] ">
        <div className="inline-block min-w-full align-middle relative">
          <table
            className="min-w-full divide-y divide-gray-200 table-fixed"
            {...getTableProps()}
          >
            <thead className="bg-gray-200 sticky top-0 z-[99]">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`py-3 px-3 text-xs font-bold  tracking-wider text-left text-gray-900 uppercase ${
                        !isValidArray(rows)
                          ? `pointer-event-none`
                          : `hover:cursor-pointer`
                      }`}
                      onClick={() => {
                        if (isValidArray(rows)) {
                          if (column.id !== "id" && !column?.disableSortBy) {
                            const columnIndex = isColumnSelectionRequired
                              ? index - 1
                              : index;
                            setSortIndex(columnIndex);
                            setSortOrder(defaultOrder === 0 ? 1 : 0);
                          }
                        }
                      }}
                      key={index}
                    >
                      {generateSortingIndicator(index, defaultOrder)}{" "}
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {isValidArray(rows) && !isLoading ? (
                rows.map((row, i) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-gray-100"
                      key={i}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="py-4 px-3 text-sm  text-gray-700 break-words"
                            key={index}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : isLoading ? (
                <LoaderTable colSpan={colSpan} />
              ) : (
                <NoDataRow colSpan={colSpan} noDataMsg={noDataMsg} />
              )}
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </div>
      {isValidArray(page) && !isPaginationHide && (
        <Pagination
          totalRecords={totalRecords}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
        />
      )}
    </>
  );
};

export default CustomTable;
