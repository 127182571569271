import datePicker from "assets/images/datePicker.svg";
import searchIconWhite from "assets/images/searchIconWhite.svg";
import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Formik } from "formik";
import {
  changeDateFormat,
  getCompanyStatus,
  getCurrentLocation,
  getDateFormatLocal,
  getUserDataByParam,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { redemptionForm, showError } from "module/validation";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsByTrackId } from "redux/redemption/getDetailsByTrackIdSlice";
import { redeemLead } from "redux/redemption/redeemLeadSlice";

const RedemptionSub = () => {
  const dispatch = useDispatch();
  const [scanCode, setScanCode] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(new Date());
  const [isShowRedeemBtn, setShowRedeemBtn] = useState(false);
  const amountFieldRef = useRef();

  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const defaultDateFormat = getDateFormatLocal();

  const [
    {
      redemptionData = {},
      redeemLeadLoading = false,
      searchLeadLoading = false,
    },
  ] = useSelector((state) => [
    {
      redemptionData: state?.getDetailsByTrackId?.data?.dataObj ?? {},
      redeemLeadLoading: state?.redeemLead?.loading ?? false,
      searchLeadLoading: state?.getDetailsByTrackId?.loading ?? false,
    },
  ]);

  const campaignDetails = redemptionData?.campaign ?? {};
  const isCustomerDataExist = isValidObject(redemptionData?.customer)
    ? redemptionData?.customer
    : {};
  const leadData = isValidObject(redemptionData?.lead)
    ? redemptionData?.lead
    : isValidObject(redemptionData?.prospect)
    ? redemptionData?.prospect
    : isCustomerDataExist;
  const offersData = redemptionData?.offer ?? {};
  const isFieldsDisabled = campaignDetails?.type === "print_ad" ? false : true;

  const initialValues = {
    firstName: leadData ? leadData?.first_name : "",
    lastName: leadData ? leadData?.last_name : "",
    address1: leadData ? leadData?.address1 : "",
    address2: leadData ? leadData?.address2 : "",
    city: leadData ? leadData?.city : "",
    state: leadData ? leadData?.state : "",
    zip: leadData ? leadData?.zip : "",
  };

  const handleSearch = (searchValue = "") => {
    if (!!scanCode || !!searchValue) {
      const company_id = getUserDataByParam("COMPANY_ID");
      const { id } = getCurrentLocation();
      dispatch(
        getDetailsByTrackId({
          scanCode: !!searchValue ? searchValue : scanCode,
          company_id: company_id,
          location_id: id,
        })
      ).then((data) => {
        if (isValidObject(data?.payload) && data?.payload?.code === 200) {
          if (!!data?.payload?.dataObj) {
            setShowRedeemBtn(true);
          } else {
            showErrorMsg("Record Not Found");
          }
        } else {
          if (data?.payload?.code === 400) {
            showErrorMsg(data?.payload?.dataObj?.message ?? "");
          } else {
            showErrorMsg("Record Not Found");
          }
        }
      });
    } else {
      showErrorMsg("Please Enter Scan code");
    }
  };

  const handleRedeem = () => {
    if (!!date && !!amount && !!scanCode) {
      const formData = {
        redemption_date: date,
        redemption_value: amount,
        tracking_id: scanCode,
        offer_id: offersData?.id ?? 0,
      };
      dispatch(redeemLead(formData)).then((data) => {
        setScanCode("");
        setShowRedeemBtn(false);
        setAmount("");
        if (data?.payload?.code === 200) {
          showSuccessMsg(
            data?.payload?.dataObj?.message ?? "Code redeemed successfully"
          );
        } else {
          showErrorMsg(data?.payload?.dataObj?.message ?? "");
        }
      });
    } else {
      if (!!!amount) {
        showErrorMsg("Please enter amount");
      } else if (!!!scanCode) {
        showErrorMsg("Please enter scan code");
      } else if (!!!date) {
        showErrorMsg("Please select date");
      }
    }
  };

  const handleSubmit = (value) => {};

  useEffect(() => {
    if (isShowRedeemBtn) {
      amountFieldRef?.current?.focus();
    }
  }, [isShowRedeemBtn]);

  return (
    <>
      <ShowPageTitle title="redemptions" />

      <div
        className={classNames(
          "flex justify-between min-h-[70%] border gap-x-4 border-gray-200 p-3 bg-white",
          {
            "pointer-events-none opacity-70": !isCompanyActive,
          }
        )}
      >
        <div className="w-[50%] flex-1 mt-3">
          <div className="flex-wrap -mx-3 mb-3">
            <div className="flex w-full items-center  px-3 mb-3">
              <label
                htmlFor="name"
                className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
              >
                Scan Code:
              </label>
              <div className="ml-2 w-full">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onPaste={(e) =>
                    handleSearch(e?.clipboardData?.getData("text") ?? "")
                  }
                  value={scanCode}
                  placeholder="Enter Scan Code"
                  onChange={(e) => setScanCode(e.target.value)}
                  className={classNames("input-field", {
                    "opacity-70 cursor-default": isShowRedeemBtn,
                  })}
                  readOnly={isShowRedeemBtn || !isCompanyActive}
                />
              </div>
            </div>
            {isShowRedeemBtn && (
              <>
                <div
                  className={classNames("flex w-full items-center  px-3 mb-3", {
                    "opacity-60": !isShowRedeemBtn,
                  })}
                >
                  <label
                    htmlFor="name"
                    className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                  >
                    Amount :
                  </label>
                  <div className="ml-2 w-full">
                    <input
                      ref={amountFieldRef}
                      type="number"
                      name="name"
                      id="name"
                      disabled={!isShowRedeemBtn}
                      value={amount}
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                      className="input-field"
                    />
                  </div>
                </div>
                <div
                  className={classNames("flex w-full items-center  px-3 mb-3", {
                    "opacity-60": !isShowRedeemBtn,
                  })}
                >
                  <label
                    htmlFor="name"
                    className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                  >
                    Date :
                  </label>
                  <div className="relative ml-2 w-full">
                    <ReactDatePicker
                      className="input-field"
                      selected={date ?? new Date()}
                      dateFormat={defaultDateFormat}
                      defaultValue={new Date()}
                      onChange={(date) => {
                        setDate(date);
                      }}
                      maxDate={new Date()}
                    />
                    <img
                      src={datePicker}
                      className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                      alt="Date picker"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex justify-center items-center w-full px-3 mb-3 mt-6">
              {isCompanyActive &&
                (isShowRedeemBtn ? (
                  <>
                    <SpinnerButton
                      className=" bg-pink-600 w-full max-w-[50%] px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                      title={"Redeem"}
                      action={() => handleRedeem()}
                      type={"button"}
                      loading={redeemLeadLoading}
                    />

                    <button
                      className="btn btn-gray ml-2"
                      onClick={() => {
                        setShowRedeemBtn(false);
                        setDate(new Date());
                        setAmount("");
                        setScanCode("");
                      }}
                    >
                      Back
                    </button>
                  </>
                ) : (
                  <SpinnerButton
                    className="relative ml-[10%] max-w-[50%] w-full font-medium text-md py-2 px-3 bg-pink-600 text-white shadow-sm shadow-pink-300 hover:bg-pink-500"
                    title={
                      <>
                        Search
                        <img
                          src={searchIconWhite}
                          alt="scan"
                          className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-[58%]"
                        />
                      </>
                    }
                    action={() => handleSearch()}
                    type={"button"}
                    loading={searchLeadLoading}
                  />
                ))}
            </div>
          </div>
        </div>
        <div
          className={classNames("w-[50%] flex-2 mt-3", {
            "opacity-60": !isShowRedeemBtn,
          })}
        >
          {isShowRedeemBtn && (
            <>
              <div className="border border-gray-300 p-2 bg-gray-100">
                <p>
                  <span className="font-semibold text-gray-800">
                    Campaign :
                  </span>{" "}
                  {campaignDetails?.name ?? "--"}
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Offers :</span>{" "}
                  {isValidObject(offersData) ? offersData?.offer_name : ""}
                </p>
                {campaignDetails?.type !== "direct_mail" && (
                  <p>
                    <span className="font-semibold text-gray-800">
                      Medium :
                    </span>{" "}
                    {campaignDetails?.source ?? "--"}
                  </p>
                )}
                {/* <p>
                  <span className="font-semibold">Minimum Spend :</span> {"0"}
                </p> */}
                <p>
                  <span className="font-semibold text-gray-800">Sent :</span>{" "}
                  {campaignDetails?.start_date
                    ? changeDateFormat(campaignDetails?.start_date)
                    : "--"}
                </p>
              </div>
              {isFieldsDisabled && (
                <div className="border p-3 mt-3 shadow-sm">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={redemptionForm}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    // validateOnBlur={false}
                  >
                    {({
                      errors,
                      touched,
                      validateField,
                      validateForm,
                      handleChange,
                      setFieldValue,
                      values,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="w-full min-w-xl bg-white">
                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="firstName"
                                className="input-field-label"
                              >
                                First Name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  disabled={isFieldsDisabled}
                                  value={values?.firstName ?? ""}
                                  onChange={handleChange}
                                  className="input-field"
                                />
                                {touched?.firstName &&
                                  showError(errors?.firstName)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="lastName"
                                className="input-field-label"
                              >
                                Last Name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  value={values?.lastName ?? ""}
                                  onChange={handleChange}
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.lastName &&
                                  showError(errors?.lastName)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="address1"
                                className="input-field-label"
                              >
                                Address
                              </label>
                              <div className="mt-2">
                                <input
                                  id="address1"
                                  name="address1"
                                  type="text"
                                  value={values?.address1 ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address1"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.address1 &&
                                  showError(errors?.address1)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="address2"
                                className="input-field-label"
                              >
                                Address2
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="address2"
                                  id="address2"
                                  value={values?.address2 ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address2"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.address2 &&
                                  showError(errors?.address2)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="city"
                                className="input-field-label"
                              >
                                City
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  value={values?.city ?? ""}
                                  onChange={handleChange}
                                  autoComplete="City"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.city && showError(errors?.city)}
                              </div>
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="state"
                                className="input-field-label"
                              >
                                State
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  value={values?.state ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address-level1"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.state && showError(errors?.state)}
                              </div>
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="zip"
                                className="input-field-label"
                              >
                                ZIP / Postal code
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="zip"
                                  id="zip"
                                  value={values?.zip ?? ""}
                                  onChange={handleChange}
                                  autoComplete="zip"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.zip && showError(errors?.zip)}
                              </div>
                            </div>
                          </div>
                          {campaignDetails?.type === "print_ad" && (
                            <div className="flex justify-start mt-4">
                              <SpinnerButton
                                className="btn-pink"
                                title={"Save"}
                                action={() => {}}
                                type={"submit"}
                                loading={false}
                              />
                            </div>
                          )}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const Redemption = () => {
  return <RedemptionSub />;
};

export default Redemption;
