import deleteIcon from "assets/images/deleteIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import copy from "copy-to-clipboard";
import {
  getCompanyStatus,
  getCurrentLocation,
  isAdmin,
  isCompanyUser,
  isSuperAdmin,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { deleteReputation } from "redux/reputation/deleteReputationSlice";
import { getReputationList } from "redux/reputation/getReputationListSlice";

export default function GetReview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab = searchParams.get("tab");

  const [{ getReputationListsData = {}, reputationListsLoading = false }] =
    useSelector((state) => [
      {
        getReputationListsData: state?.getReputationList?.data ?? [],
        reputationListsLoading: state?.getReputationList?.loading ?? false,
      },
    ]);
  const isCompanyActive =
    (["active"].includes(getCompanyStatus()) && isCompanyUser()) ||
    isAdmin() ||
    isSuperAdmin();
  const { id: currentLocationId } = getCurrentLocation();
  const totalRecords = getReputationListsData?.total ?? 0;
  const currentPerPage = getReputationListsData?.per_page ?? showPerPage;

  const columns = [
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "Location Name",
      accessor: "location_name",
    },
    {
      Header: "Name",
      accessor: "title",
    },
    {
      Header: "Original URL",
      accessor: "original_url",
    },
    {
      Header: "Generated URL",
      accessor: "full_trackable_url",
      Cell: ({ cell }) => (
        <span
          className="cursor-pointer hover:text-gray-900"
          title="Click to Copy"
          onClick={() => {
            const isCopied = copy(cell?.row?.values?.full_trackable_url);
            if (isCopied) {
              showSuccessMsg("Copied to clipboard!");
            }
          }}
        >
          {cell?.row?.values?.full_trackable_url ?? ""}
        </span>
      ),
    },
    {
      Header: "Access Count",
      accessor: "access_count",
    },
    ...(isCompanyActive
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-x-2 ">
                <img
                  alt="archive"
                  src={deleteIcon}
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() => {
                    setModalOpen(true);
                    setSelectedId(cell?.row?.values?.id ?? 0);
                  }}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleReputationList = (search) => {
    const { id: currentLocationId } = getCurrentLocation();
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      location_id: currentLocationId,
    };
    dispatch(getReputationList(sortObj));
  };

  const globalSearch = (search) => {
    handleReputationList(search);
  };

  useEffect(() => {
    handleReputationList(searchInput);
  }, [sortOrder, currentPage, perPage, currentLocationId]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Reputation List",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const handleDeleteReputation = () => {
    dispatch(deleteReputation(selectedId)).then((data) => {
      setModalOpen(false);
      if (data?.payload.code === 200) {
        showSuccessMsg(
          data?.payload.message ?? "Reputation deleted successfully"
        );
        handleReputationList(searchInput);
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <div>
      <div className="flex  justify-end items-center mt-2">
        {isCompanyActive && (
          <button
            type="button"
            onClick={() => navigate("/reputation/add")}
            className="btn-pink"
          >
            New Reputation
          </button>
        )}
        <div className="ml-2 flex shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>

      <div className="mt-4 flex flex-col">
        <CustomTable
          data={getReputationListsData?.data ?? []}
          columns={columns}
          isLoading={reputationListsLoading}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleDeleteReputation}
          isLoading={false}
          message="You want to delete this record?"
        />
      )}
    </div>
  );
}
