import { isValidArray, isValidObject, showErrorMsg } from "module/util";
import * as yup from "yup";
export const showError = (value) => (
  <span className="text-red-500">{value}</span>
);

const phoneFormat = /^(|(\+?1-?)) ?((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
const amountFormat = /^\d+\.\d{2,10}$/;
const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const phoneFormatMsg =
  "Phone number format is invalid. It should be formatted like +1 (xxx) xxx-xxxx.";

const SUPPORTED_FORMATS = ["jpg", "jpeg", "png"];
export const SUPPORTED_FORMATS_IMAGE = ["image/jpg", "image/jpeg", "image/png"];
export const FILE_SIZE = 5000000;

export const loginForm = (data = {}) => {
  return yup.object({
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    password: yup
      .string()
      .required(data?.password?.required ?? "Password is required."),
  });
};

export const resetPasswordForm = yup.object({
  email: yup
    .string()
    .matches(emailFormat, "Invalid email address.")
    .required("Email is required."),
});

export const setNewPasswordForm = (data = {}) => {
  return yup.object({
    password: yup
      .string()
      .required(data?.password?.required ?? "Password is required.")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        data?.password?.regex ??
          "Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .required(
        data?.password_confirmation?.required ?? "Confirm password required"
      )
      .oneOf(
        [yup.ref("password"), null],
        data?.password_confirmation?.same ?? "Passwords must match"
      ),
  });
};

export const registerForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    company: yup
      .string()
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "Company name may not be greater than 100 characters."
      )
      .min(
        3,
        data?.company?.min ??
          "Company Name should be minimum 3 characters long."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    password: yup
      .string()
      .required(data?.password?.required ?? "Password is required.")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .required(
        data?.password_confirmation?.required ?? "Confirm password required"
      )
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    rememberMe: yup.bool().oneOf([true], "Accept the terms and conditions"),
  });
};

export const accountForm = (data = {}) => {
  return yup.object({
    company: yup
      .string()
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "The company name may not be greater than 100 characters."
      ),
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply no required validation
          return true;
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    zip: yup
      .string()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
  });
};

export const companyLocationForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        50,
        data?.name?.max ?? "Name may not be greater than 100 characters."
      )
      .min(2, data?.name?.min ?? "Name must be at least 2 characters."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    address1: yup
      .string()
      .required(data?.address1?.required ?? "Address is required."),
    zip: yup
      .string()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
  });
};

export const accountUserForm = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      email: yup
        .string()
        .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
        .required(data?.email?.required ?? "Email is required."),
      phone: yup
        .string()
        .test("phone-validation", phoneFormatMsg, function (value) {
          if (!value || value === "1" || value === "+1 (   )    -    ") {
            // If phone number is empty or equals "1", apply no required validation
            return true;
          } else {
            // Apply format validation for non-empty phone number
            return yup
              .string()
              .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
              .isValidSync(value);
          }
        }),
      locations: yup
        .array()
        .min(
          1,
          data?.location_ids?.required ?? "Please check at least 1 location."
        )
        .of(
          yup
            .string()
            .required(
              data?.location_ids?.required ??
                "Please check at least 1 location."
            )
        )
        .required(
          data?.location_ids?.required ?? "Please check at least 1 location."
        ),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
        .min(5, "Zip must be 5 or 6 digits long.")
        .max(6, "Zip must be 5 or 6 digits long."),
    },
    [["phone"]]
  );
};

export const customerForm = (data = {}) => {
  return yup.object({
    firstName: yup
      .string()
      .required(data?.first_name?.required ?? "First name is required.")
      .min(
        2,
        data?.first_name?.max ??
          "First name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    lastName: yup
      .string()
      .required(data?.last_name?.required ?? "Last name is required."),
    address1: yup
      .string()
      .required(data?.address1?.required ?? "Address is required."),
    city: yup.string().required(data?.city?.required ?? "City is required"),
    state: yup.string().required(data?.state?.required ?? "State is required"),
    zip: yup
      .string()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long.")
      .required(data?.zip?.required ?? "Zip/Postal code is required."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
  });
};

export const adminUserForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
  });
};

export const companyForm = (data = {}) => {
  return yup.object({
    company: yup
      .string()
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "The company name may not be greater than 100 characters."
      ),
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    address1: yup
      .string()
      .required(data?.address1?.required ?? "Address is required."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    zip: yup
      .string()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
  });
};

export const splashPageForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),

    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
  });
};

export const leadListForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    company: yup.string().required("Company is required."),
    location: yup
      .string()
      .required(data?.location_id?.required ?? "Location is required."),
    source: yup
      .string()
      .required(data?.source?.required ?? "Source is required."),
  });
};

export const directMailForm1 = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      isEdit: "",
      campaign_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
      list_id: yup.string().when("sendTo", {
        is: (sendTo) => ["leads", "both"].includes(sendTo),
        then: () => yup.string().required("List is required."),
      }),
      start_date: yup
        .string()
        .required(data?.start_date?.required ?? "Start date is required."),
      expires: yup.string().required("Expires is required."),
      end_date: yup
        .string()
        .typeError("Invalid date")
        .when("expires", {
          is: "yes",
          then: () =>
            yup
              .string()
              .required(data?.end_date?.required ?? "End date is required."),
        }),

      mailer_upload: yup.lazy(() =>
        yup
          .mixed()
          .when("isEdit", {
            is: false,
            then: () =>
              yup
                .mixed()
                .required(
                  data?.mailer_upload?.required ?? "Please upload image"
                )
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
          .when(["isEdit", "mailer_upload"], {
            is: (isEdit, mailer_upload) => isEdit === true && !!mailer_upload,
            then: () =>
              yup
                .mixed()
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
      ),
      offers: yup.array().of(
        yup.object({
          offer_name: yup
            .string()
            .required(data?.offer_name?.required ?? "Offer name is required.")
            .max(
              255,
              data?.offer_name?.max ??
                "offer name may not be greater than 255 characters."
            ),
          discount_cost: yup
            .string()
            .matches(amountFormat, "Value should be in $00.00 format")
            .required(
              data?.discount_cost?.required ?? "Offer cost is required"
            ),
        })
      ),
    },
    [["isEdit", "mailer_upload"], "isEdit"]
  );
};
export const printAdForm1 = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      isEdit: "",
      campaign_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
      discount_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.discount_cost?.required ?? "Offer cost is required"),
      source: yup
        .string()
        .required(data?.source?.required ?? "Source is required."),
      start_date: yup
        .string()
        .required(data?.start_date?.required ?? "Start date is required."),
      expires: yup.string().required("Expires is required."),
      end_date: yup
        .string()
        .typeError("Invalid date")
        .when("expires", {
          is: "yes",
          then: () =>
            yup
              .string()
              .required(data?.end_date?.required ?? "End date is required."),
        }),
      mailer_upload: yup.lazy(() =>
        yup
          .mixed()
          .when("isEdit", {
            is: false,
            then: () =>
              yup
                .mixed()
                .required(
                  data?.mailer_upload?.required ?? "Please upload image"
                )
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
          .when(["isEdit", "mailer_upload"], {
            is: (isEdit, mailer_upload) => isEdit === true && !!mailer_upload,
            then: () =>
              yup
                .mixed()
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
      ),
      offer_name: yup
        .string()
        .required(data?.offer_name?.required ?? "Offer name is required.")
        .max(
          255,
          data?.offer_name?.max ??
            "Offer name may not be greater than 255 characters."
        ),
    },
    [["isEdit", "mailer_upload"], "isEdit"]
  );
};

export const digitalOfferForm1 = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    campaign_cost: yup
      .string()
      .matches(amountFormat, "Value should be in $00.00 format")
      .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
    discount_cost: yup
      .string()
      .matches(amountFormat, "Value should be in $00.00 format")
      .required(data?.discount_cost?.required ?? "Offer cost is required"),

    offer_valid_days: yup
      .number()
      .min(1, "Must be greater then 0")
      .required("Offer Valid Days is required."),
    start_date: yup
      .string()
      .required(data?.start_date?.required ?? "Start date is required."),
    expires: yup.string().required("Expires is required."),
    end_date: yup
      .string()
      .typeError("Invalid date")
      .when("expires", {
        is: "yes",
        then: () =>
          yup
            .string()
            .required(data?.end_date?.required ?? "End date is required."),
      }),
    budget: yup
      .string()
      .typeError("Invalid date")
      .when("expires", {
        is: "no",
        then: () => yup.string().required("Budget is required."),
      }),
    offer_name: yup
      .string()
      .required(data?.offer_name?.required ?? "Offer name is required.")
      .max(
        255,
        data?.offer_name?.max ??
          "Offer name may not be greater than 255 characters."
      ),
    // expire_time_frame: yup.string().required("Expire Time is required."),
  });
};

export const digitalCouponForm2 = (data = {}) => {
  return yup.object({
    fromName: yup
      .string()
      .required(data?.from_name?.required ?? "From Name is required."),
    replyEmail: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    fromEmail: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    subject: yup.string().required("Subject is required."),
    body: yup
      .string()
      .required(data?.email_body?.required ?? "Email body is required."),
  });
};

export const redemptionForm = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required"),
  address1: yup.string().required("Address1 is required"),
});

export const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

export const settingsForm = yup.object({
  dateFormat: yup.string().required("Please select date format."),
  mapZoom: yup.number().required("Google map zoom level is required"),
  heatMapZoom: yup.number().required("Google heat map zoom level is required"),
  phone: yup
    .string()
    .required("Phone number is required.")
    .matches(phoneFormat, phoneFormatMsg),
  accessKey: yup.string().required("Please enter Access key."),
});

export const marketPlaceForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    comment: yup
      .string()
      .required(data?.comment?.required ?? "Comment is required."),
  });
};

export const contactUsForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    comment: yup
      .string()
      .required(data?.comment?.required ?? "Comment is required."),
  });
};

export const handleServerValidation = (data = {}) => {
  if (data?.payload?.code === 422) {
    if (isValidObject(data?.payload?.exception?.errors)) {
      const errorObj = data?.payload?.exception?.errors;
      if (isValidArray(errorObj?.email)) {
        showErrorMsg(errorObj?.email?.[0]);
      } else if (isValidArray(errorObj?.name)) {
        showErrorMsg(errorObj?.name?.[0]);
      } else if (isValidArray(errorObj?.phone)) {
        showErrorMsg(errorObj?.phone?.[0]);
      } else if (isValidArray(errorObj?.zip)) {
        showErrorMsg(errorObj?.zip?.[0]);
      } else if (isValidArray(errorObj?.list_id)) {
        showErrorMsg(errorObj?.list_id?.[0]);
      } else {
        showErrorMsg("Something went wrong!");
      }
    } else if (data?.payload?.dataObj) {
      showErrorMsg(data?.payload?.dataObj?.message ?? "Something went wrong!");
    } else {
      showErrorMsg(
        data?.payload?.exception?.message ??
          data?.payload?.message ??
          "Something went wrong!"
      );
    }
  } else if (data?.payload?.code === 500 || data?.code === 500) {
    showErrorMsg(
      data?.payload?.exception ?? data?.exception ?? "Something went wrong!"
    );
  } else if (data?.payload?.code === 400) {
    showErrorMsg(
      data?.payload?.dataObj.error ??
        data?.payload?.message ??
        "Something went wrong!"
    );
  } else if (data?.payload?.code === 503) {
    showErrorMsg(data?.payload?.dataObj?.error ?? "Service is not available!");
  } else {
    showErrorMsg(data?.payload?.message ?? "Something went wrong!");
  }
};
