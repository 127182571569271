import GuestWifiForm from "components/front/GuestWifiForm";
import GuestWifiOtpForm from "components/front/GuestWifiOtpForm";
import { useSearchParams } from "react-router-dom";
import guestWifi from "assets/images/guestWifi.svg";

const SplashPreview = ({
  currentLocationId = "",
  locationName = "",
  companyName = "",
  companyId = "",
  isPreview = true,
  isGuestForm = true,
  guestId = "",
  isLoading = false,
  setLoading = () => {},
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentCampaignsType = searchParams.get("");

  const queryParams = {
    cmd: encodeURIComponent(searchParams.get("cmd")),
    mac: encodeURIComponent(searchParams.get("mac")),
    network: encodeURIComponent(searchParams.get("network")),
    ip: encodeURIComponent(searchParams.get("ip")),
    apmac: encodeURIComponent(searchParams.get("apmac")),
    site: encodeURIComponent(searchParams.get("site")),
    post: encodeURIComponent(searchParams.get("post")),
    url: encodeURIComponent(searchParams.get("url")),
  };

  return (
    <div className="flex flex-col justify-center m-auto bg-blue-400 py-12 px-6 sm:px-12 w-full h-screen sm:h-full rounded-sm">
      <div className="w-full flex justify-center min-h-[100px]">
        <img src={guestWifi} alt="guest-wifi-logo" className="w-[150px]" />
      </div>
      <div className="mt-4 w-full  ">
        <p className="font-semibold text-xl text-white text-center">
          Connect to
        </p>
        <p className="font-semibold text-xl text-white text-center">
          {locationName}
        </p>
      </div>
      <div className="mt-8 w-full bg-white px-4 pt-4 mb-4 rounded-sm">
        {isGuestForm ? (
          <GuestWifiForm
            companyId={companyId}
            companyName={companyName}
            locationName={locationName}
            isPreview={isPreview}
            currentLocationId={currentLocationId}
            guestId={guestId}
            queryParams={queryParams}
          />
        ) : (
          <GuestWifiOtpForm
            companyId={companyId}
            companyName={companyName}
            locationName={locationName}
            isPreview={isPreview}
            currentLocationId={currentLocationId}
            guestId={guestId}
            queryParams={queryParams}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  );
};

export default SplashPreview;
