import DraftEditor from "components/campaigns/digitalOffer/DraftEditor";
import SpinnerButton from "components/common/fields/SpinnerButton";
import copy from "copy-to-clipboard";
import { Field, Formik } from "formik";
import { defaultEmailBody } from "module/data";
import { isValidObject, showErrorMsg, showSuccessMsg } from "module/util";
import {
  digitalCouponForm2,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { updateCampaignDetail } from "redux/campaign/updateCampaignDetailSlice";

const DigitalOfferStep2 = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    setSelectStep,
    type,
    isEdit,
    companyId = 0,
    currentLocationId = 0,
    campaignId = 0,
    updateCampaignDetailLoading,
  } = props;

  const navigate = useNavigate();

  const [{ getCampaignDetailData = {}, validationMsgObj = {} }] = useSelector(
    (state) => [
      {
        getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
        validationMsgObj: state?.getMessages?.data ?? {},
      },
    ]
  );

  const emailTemplateData = getCampaignDetailData?.email_template ?? {};

  const initialValues = {
    fromName: isEdit && emailTemplateData ? emailTemplateData.from_name : "",
    replyEmail:
      isEdit && emailTemplateData ? emailTemplateData.reply_email : "",
    fromEmail:
      isEdit && emailTemplateData ? emailTemplateData.from_email_address : "",
    subject: isEdit && emailTemplateData ? emailTemplateData.subject : "",
    body:
      isEdit && isValidObject(emailTemplateData)
        ? emailTemplateData?.email_body
        : defaultEmailBody,
  };
  const handleSubmit = (value) => {
    const formData = {
      campaign_id: getCampaignDetailData?.id ?? 0,
      company_id: companyId,
      location_id: currentLocationId,
      from_name: value?.fromName ?? "",
      reply_email: value?.replyEmail ?? "",
      from_email_address: value?.fromEmail ?? "",
      subject: value?.subject ?? "",
      email_body: value?.body ?? "",
    };

    dispatch(
      updateCampaignDetail({
        id: getCampaignDetailData?.id,
        formData: formData,
      })
    ).then((data) => {
      if (data?.payload?.code === 200) {
        setSelectStep(3);
        navigate(`/campaigns/edit/${campaignId}/${type}?step=3`);
      } else {
        handleServerValidation(data);
        // showErrorMsg("Something went wrong");
      }
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetail(id));
    }
  }, []);

  const emailVariableArray = [
    "Customer_First_Name",
    "OfferName",
    "Location",
    "LocationAddress1",
    "LocationCity",
    "LocationState",
    "LocationZip",
    "LocationPhone",
    "expirationDate",
    "CompanyName",
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          digitalCouponForm2(validationMsgObj?.campaign ?? {})
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
        // validateOnBlur={false}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          setFieldValue,
          values,
          handleSubmit,
          setFieldTouched,
        }) => (
          <div className="flex justify-center gap-x-6">
            <form onSubmit={handleSubmit} className="w-full min-w-xl mt-4">
              <div className="border border-gray-200 p-3 bg-white mt-4">
                <div className="w-full">
                  <div className="flex  flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="fromName" className="input-field-label">
                        From Name
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="fromName"
                          id="fromName"
                          value={values?.fromName ?? ""}
                          onChange={handleChange}
                          placeholder="Enter From Name"
                          className="input-field"
                        />
                      </div>
                      {touched?.fromName && showError(errors?.fromName)}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="replyEmail" className="input-field-label">
                        Reply Email Address
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="replyEmail"
                          id="replyEmail"
                          value={values?.replyEmail ?? ""}
                          onChange={handleChange}
                          placeholder="Enter Reply Email"
                          className="input-field"
                        />
                      </div>
                      {touched?.replyEmail && showError(errors?.replyEmail)}
                    </div>
                  </div>
                  <div className="flex  flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3">
                      <label htmlFor="fromEmail" className="input-field-label">
                        From Email Address
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="fromEmail"
                          id="fromEmail"
                          value={values?.fromEmail ?? ""}
                          onChange={handleChange}
                          placeholder="Enter From Email"
                          className="input-field"
                        />
                      </div>
                      {touched?.fromEmail && showError(errors?.fromEmail)}
                    </div>
                  </div>
                  <div className="flex  flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <label htmlFor="subject" className="input-field-label">
                        Subject
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="subject"
                          id="subject"
                          value={values?.subject ?? ""}
                          onChange={handleChange}
                          placeholder="Enter Subject"
                          className="input-field"
                        />
                      </div>
                      {touched?.subject && showError(errors?.subject)}
                    </div>
                  </div>
                  <div className="flex  flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <label htmlFor="body" className="input-field-label">
                        Body
                      </label>
                      <div
                        htmlFor="body"
                        className="mt-1 flex flex-wrap gap-y-1 justify-center"
                      >
                        {emailVariableArray?.map((item) => {
                          return (
                            <span
                              key={item}
                              onClick={() => {
                                const isCopied = copy(`%%${item}%%`);
                                if (isCopied) {
                                  showSuccessMsg("Copied to clipboard!");
                                }
                              }}
                              className="ml-2 border cursor-pointer hover:bg-gray-100 text-[13px] font-light border-gray-300 p-1"
                            >
                              {`%%${item}%%`}
                            </span>
                          );
                        })}
                      </div>
                      <div className="mt-2">
                        <DraftEditor
                          setFieldValue={setFieldValue}
                          value={values?.body ?? ""}
                          setFieldTouched={setFieldTouched}
                          emailTemplateData={emailTemplateData}
                        />
                      </div>
                      {touched?.body && showError(errors?.body)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between mb-6 mt-4 ">
                <div>
                  {isEdit && (
                    <button
                      onClick={() => {
                        setSelectStep(1);
                        const redirectUrl = isEdit
                          ? `/campaigns/edit/${campaignId}/${type}?step=1`
                          : `/campaigns/add/${type}?step=1`;
                        navigate(redirectUrl);
                      }}
                      className="btn btn-light-gray"
                    >
                      Back
                    </button>
                  )}
                </div>
                <SpinnerButton
                  className="btn-pink"
                  title={"Next"}
                  action={() => {}}
                  type={"submit"}
                  loading={updateCampaignDetailLoading}
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default DigitalOfferStep2;
