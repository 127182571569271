import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import classNames from "classnames";
import {
  MAP_API_KEY,
  MAP_LIBRARIES,
  getHeatMapConfig,
  hexToRGBAWithOpacity,
  isValidArray,
  isValidObject,
} from "module/util";

const GoogleMapComponent = (props) => {
  const {
    latLong = {},
    heatMapData = [],
    isHeatMap = false,
    isDashboard = false,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const mapContainerStyle = {
    height: isHeatMap ? (isDashboard ? "700px" : "500px") : "400px",
    width: "100%",
  };
  const center = isValidObject(latLong)
    ? latLong
    : { lat: 39.5500507, lng: -105.7820674 };

  const { heatMapZoom, mapZoom, heatMapColor1, heatMapColor2, heatMapColor3 } =
    getHeatMapConfig();

  const zoomLevel = isHeatMap
    ? Number(heatMapZoom)
    : latLong
    ? Number(mapZoom)
    : 4;
  const position = latLong;

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          id="marker-example"
          mapContainerStyle={mapContainerStyle}
          zoom={zoomLevel}
          center={center}
        >
          {isValidObject(latLong) && !isHeatMap && (
            <Marker
              position={position}
              animation={google.maps.Animation.DROP}
            />
          )}
          {isValidArray(heatMapData) && isHeatMap && (
            <HeatmapLayer
              data={heatMapData?.map((item) => {
                return new google.maps.LatLng(item?.lat, item?.lng);
              })}
              center={center}
              options={{
                dissipating: true,
                maxIntensity: 3,
                opacity: 1,
                radius: 10,
                gradient: hexToRGBAWithOpacity([
                  heatMapColor1,
                  heatMapColor1,
                  heatMapColor2,
                  heatMapColor3,
                ]),
              }}
            />
          )}
        </GoogleMap>
      ) : (
        <div
          className={classNames("", {
            "w-full h-[700px] flex justify-center items-center": isHeatMap,
          })}
        >
          Google map is Loading....
        </div>
      )}
    </>
  );
};

export default GoogleMapComponent;
