import { ReactComponent as ComplianceIcon } from "assets/images/compliance.svg";
import { ReactComponent as WIFIIcon } from "assets/images/wifi.svg";
import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import MarketPlaceModal from "components/marketPlace/MarketPlaceModal";
import { dismissToast, getCompanyStatus, isCompanyUser } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const MarketPlace = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const companyStatus = getCompanyStatus();
  const handleOpenModal = (value = "") => {
    setSelectedOption(value);
    setModalOpen(true);
    dismissToast();
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Marketplace",
          href: "",
          current: false,
        },
      ])
    );

    return () => {
      dismissToast();
    };
  }, []);

  return (
    <div
      className={classNames(
        "w-full border border-gray-200 p-3 bg-white h-[calc(100vh-150px)] mt-4",
        {
          "pointer-events-none opacity-50":
            isCompanyUser() && companyStatus !== "active",
        }
      )}
    >
      <ShowPageTitle title="marketplace" />
      <div className="w-full flex justify-start items-center">
        <span
          onClick={() => {}}
          className=" hover:cursor-pointer hover:underline text-lg font-light"
        >
          Marketplace
        </span>
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center  gap-6 mt-[10%]">
        <div
          onClick={() => {
            handleOpenModal("ADA Compliance for Website");
          }}
          className={classNames(
            "compliance-ada max-w-[155px] w-full min-h-[100px] flex flex-col gap-y-4 items-center justify-between cursor-pointer text-center p-3",
            {
              active: false,
            }
          )}
        >
          <p className="font-bold">ADA Compliance for Website</p>
          <ComplianceIcon />
          <p></p>
        </div>
        <div
          onClick={() => {
            handleOpenModal("Free Wi-fi for Customers");
          }}
          className={classNames(
            "free-wifi max-w-[155px] w-full min-h-[100px] flex flex-col gap-y-4 items-center justify-between cursor-pointer text-center p-3",
            {
              active: false,
            }
          )}
        >
          <p className="font-bold">Free Wi-fi for Customers</p>
          <WIFIIcon />
          <p></p>
        </div>
      </div>
      {isModalOpen && (
        <MarketPlaceModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={() => {}}
          isLoading={false}
          selectedOption={selectedOption}
        />
      )}
    </div>
  );
};

export default MarketPlace;
