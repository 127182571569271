export const pageTitles = {
  homePage: "Home page",
  dashboard: "dashboard",
  campaings: "Campaings",
  campaingsType: "Campaings-select type",
  campaingsDirectMail: "Campaings - Direct Mail",
  campaingsDigitalCoupon: "Campaings - Digital Coupon",
  campaingsPrintAd: "Campaings - Print Ad",
  redemptions: "Redemptions",
  leadList: "Lead Lists",
  locations: "Locations",
  customers: "Customers",
  account: "Account",
  adminUsers: "Admin Users",
  companies: "Companies",
  login: "Login",
  register: "Sign Up",
  resetPassword: "Reset Password",
  marketplace: "Marketplace",
  reputation: "Reputation",
  settings: "Settings",
  companySettings: "Settings",
  verifyCode: "verify Security Code",
  contactsCustomer: "Contacts - Customers",
  contactsProspect: "Contacts - Prospects",
  contactsLeadList: "Contacts - Lead List",
  contactsImportLog: "Contacts - Import Logs",
  guestWifi: "Guest WIFI",
  customerVisit: "Customer - Visits",
  contactsAll: "Contacts - All",
};

export const companyNameForMsg = {
  active: "active",
  pending: "pending",
  archived: "archive",
  denied: "deny",
  delete: "delete",
};

export const leadsColumnArray = [
  { id: "first_name", name: "First Name" },
  { id: "middle_name", name: "Middle Name" },
  { id: "last_name", name: "Last Name" },
  { id: "full_name", name: "Full Name" },
  { id: "address1", name: "Address 1" },
  { id: "city", name: "City" },
  { id: "state", name: "State" },
  { id: "zip", name: "Zip" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
  { id: "age", name: "Age" },
  { id: "income", name: "Income" },
  { id: "gender", name: "Gender" },
  { id: "marital_status", name: "Marital Status" },
  { id: "length_in_residence", name: "Length In Residence" },
  { id: "children_present", name: "Children Present" },
  { id: "seniors_present", name: "Seniors Present" },
  { id: "owner_renter", name: "Owner Renter" },
  { id: "no_of_people", name: "No. Of People" },
  { id: "home_type", name: "Home Type" },
  { id: "plus4", name: "Plus 4" },
  { id: "crrt", name: "CRRT" },
  { id: "dpb", name: "DPB" },
  { id: "user_id", name: "Customer Id" },
];
export const leadsColumnObj = {
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  full_name: "Full Name",
  address1: "Address 1",
  city: "City",
  state: "State",
  zip: "Zip",
  phone: "Phone",
  email: "Email",
  age: "Age",
  income: "Income",
  gender: "Gender",
  marital_status: "Marital Status",
  length_in_residence: "Length In Residence",
  children_present: "Children Present",
  seniors_present: "Seniors Present",
  owner_renter: "Owner Renter",
  no_of_people: "No. Of People",
  home_type: "Home Type",
  plus4: "Plus 4",
  crrt: "CRRT",
  dpb: "DPB",
  user_id: "Customer Id",
};

export const customersColumnArray = [
  { id: "first_name", name: "First Name" },
  { id: "last_name", name: "Last Name" },
  { id: "address1", name: "Address 1" },
  { id: "address2", name: "Address 2" },
  { id: "city", name: "City" },
  { id: "state", name: "State" },
  { id: "zip", name: "Zip" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
];

export const customersColumnObj = {
  first_name: "First Name",
  last_name: "Last Name",
  address1: "Address 1",
  address2: "Address 2",
  city: "City",
  state: "State",
  zip: "Zip",
  phone: "Phone",
  email: "Email",
};

export const leadsRequiredColumnArray = [
  "first_name",
  "last_name",
  "address1",
  "city",
  "state",
  "zip",
];

export const customersRequiredColumnArray = [
  "first_name",
  "last_name",
  "address1",
  "city",
  "state",
  "zip",
  "phone",
  "email",
];

export const campaignTypeForPageTitle = {
  direct_mail: "campaingsDirectMail",
  digital_offer: "campaingsDigitalCoupon",
  print_ad: "campaingsPrintAd",
};

export const campaignTypeForList = {
  direct_mail: "Direct Mail",
  digital_offer: "Digital Offer",
  print_ad: "Print Ad",
  sms: "SMS",
  email: "Email",
  door_hanger: "Door Hanger",
};

export const directMailSteps = [
  { id: "1", name: "Direct Mail Setup", href: "#", status: "current" },
  {
    id: "2",
    name: "Direct Mail Campaign Start",
    href: "#",
    status: "upcoming",
  },
];

export const printAdSteps = [
  { id: "1", name: "Create Print Ad Tracker", href: "#", status: "current" },
  {
    id: "2",
    name: "Print Ad Tracker LIVE!!",
    href: "#",
    status: "upcoming",
  },
];

export const digitalOffer = [
  { id: "1", name: "Campaign Details", href: "#", status: "current" },
  {
    id: "2",
    name: "Configure Email",
    href: "#",
    status: "upcoming",
  },
  {
    id: "3",
    name: "Image Upload",
    href: "#",
    status: "upcoming",
  },
];

export const statesArray = [
  { label: "AL", value: "AL", value2: "alabama" },
  { label: "AK", value: "AK", value2: "alaska" },
  { label: "AZ", value: "AZ", value2: "arizona" },
  { label: "AR", value: "AR", value2: "arkansas" },
  { label: "CA", value: "CA", value2: "california" },
  { label: "CO", value: "CO", value2: "colorado" },
  { label: "CT", value: "CT", value2: "connecticut" },
  { label: "DE", value: "DE", value2: "delaware" },
  { label: "FL", value: "FL", value2: "florida" },
  { label: "GA", value: "GA", value2: "georgia" },
  { label: "HI", value: "HI", value2: "hawaii" },
  { label: "ID", value: "ID", value2: "idaho" },
  { label: "IL", value: "IL", value2: "illinois" },
  { label: "IN", value: "IN", value2: "indiana" },
  { label: "IA", value: "IA", value2: "iowa" },
  { label: "KS", value: "KS", value2: "kansas" },
  { label: "KY", value: "KY", value2: "kentucky" },
  { label: "LA", value: "LA", value2: "louisiana" },
  { label: "ME", value: "ME", value2: "maine" },
  { label: "MD", value: "MD", value2: "maryland" },
  { label: "MA", value: "MA", value2: "massachusetts" },
  { label: "MI", value: "MI", value2: "michigan" },
  { label: "MN", value: "MN", value2: "minnesota" },
  { label: "MS", value: "MS", value2: "mississippi" },
  { label: "MO", value: "MO", value2: "missouri" },
  { label: "MT", value: "MT", value2: "montana" },
  { label: "NE", value: "NE", value2: "nebraska" },
  { label: "NV", value: "NV", value2: "nevada" },
  { label: "NH", value: "NH", value2: "new hampshire" },
  { label: "NJ", value: "NJ", value2: "new jersey" },
  { label: "NM", value: "NM", value2: "new mexico" },
  { label: "NY", value: "NY", value2: "new york" },
  { label: "NC", value: "NC", value2: "north carolina" },
  { label: "ND", value: "ND", value2: "north dakota" },
  { label: "OH", value: "OH", value2: "ohio" },
  { label: "OK", value: "OK", value2: "oklahoma" },
  { label: "OR", value: "OR", value2: "oregon" },
  { label: "PA", value: "PA", value2: "pennsylvania" },
  { label: "RI", value: "RI", value2: "rhode island" },
  { label: "SC", value: "SC", value2: "south carolina" },
  { label: "SD", value: "SD", value2: "south dakota" },
  { label: "TN", value: "TN", value2: "tennessee" },
  { label: "TX", value: "TX", value2: "texas" },
  { label: "UT", value: "UT", value2: "utah" },
  { label: "VT", value: "VT", value2: "vermont" },
  { label: "VA", value: "VA", value2: "virginia" },
  { label: "WA", value: "WA", value2: "washington" },
  { label: "WV", value: "WV", value2: "west virginia" },
  { label: "WI", value: "WI", value2: "wisconsin" },
  { label: "WY", value: "WY", value2: "wyoming" },
];

export const defaultEmailBody = `<p>%%Customer_First_Name%%<br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">We have received your request for the %%OfferName%% and Your OFFER is attached!</span></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;"><strong>Please print the attached PDF</strong> out and bring it with you on your next visit, a physical copy is REQUIRED. We look forward to seeing you at %%CompanyName%% at %%Location%%<br></span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationAddress1%%</span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationCity%%, %%LocationState%% %%LocationZip%%</span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationPhone%%</span><br></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">The OFFER PDF expires on %%expirationDate%%, so don't delay. The DIGITAL OFFER requires a purchase of $35 or more during your visit and cannot be combined with any other offer or coupon.</span></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">- The Staff @ %%CompanyName%%</span><br></p>
`;

export const validationMsg = {
  company: {
    required: "The company name is required.",
    max: "The company name may not be greater than 100 characters.",
  },
  name: {
    required: "The name is required.",
    max: "The name may not be greater than 100 characters.",
  },
  email: {
    required: "The email is required.",
    email: "The email must be a valid email address.",
    unique: "The email has already been taken by another company.",
  },
  status: {
    sometimes: "The status field is conditionally required.",
  },
  phone: {
    required: "The phone number is required.",
    regex:
      "The phone number format is invalid. It should be formatted like +1 (533) 213-2132.",
    min: "The phone number must be at least 10 characters.",
  },
};
