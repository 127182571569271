import classNames from "classnames";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { API_URL } from "module/api";
import { isValidObject, showSuccessMsg } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";

const DirectMailStep2 = (props) => {
  const { setSelectStep, type } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [{ getCampaignDetailData = {} }] = useSelector((state) => [
    {
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
    },
  ]);

  useEffect(() => {
    if (!!id) {
      dispatch(getCampaignDetail(id));
    }
  }, []);

  const handleDownloadCsvFile = () => {
    setLoading(true);
    var a = document.createElement("a");
    a.setAttribute(
      "href",
      `${API_URL}storage/documents/${getCampaignDetailData?.file_export}`
    );
    a.setAttribute("download", "newFile");
    a.setAttribute("target", "_blank");
    a.click();
    setLoading(false);
  };

  const matrixData = isValidObject(getCampaignDetailData)
    ? JSON.parse(getCampaignDetailData?.campaign_metrics)
    : {};

  return (
    <>
      <div className="flex md:gap-y-2 sm:gap-y-2 mt-4 min-h-[120px] 2xl:justify-start xl:gap-x-4 lg:gap-x-2 md:gap-x-4 sm:gap-x-1">
        <div
          onClick={() => {}}
          className={classNames(
            "w-44 min-w-[200px]  min-h-[100px] bg-gradient-to-b from-[#f35288] to-[#f186ac] text-center border border-[#9CA38F]  p-3 space-y-4 items-center"
          )}
        >
          <p className="text-md font-semibold">List Size</p>
          <p className="font-bold text-lg">{matrixData?.list_size ?? 0}</p>
        </div>
        <div
          onClick={() => {}}
          className={classNames(
            "w-44 min-w-[200px] min-h-[100px] bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-center  p-3 border border-[#9CA38F] space-y-4 items-center"
          )}
        >
          <p className="text-md font-semibold">Potential Leads</p>
          <p className="font-bold text-lg">
            {matrixData?.potential_leads ?? 0}
          </p>
        </div>

        <div
          onClick={() => {}}
          className={classNames(
            "w-44 min-w-[200px] min-h-[100px] bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border border-[#9CA38F] space-y-4 items-center"
          )}
        >
          <p className="text-md font-semibold">Customers</p>
          <p className="font-bold text-lg">{matrixData?.customer_count ?? 0}</p>
        </div>
      </div>
      <div className="mt-4 space-y-6 ml-4">
        <p>
          Your direct mail campaign is READY! Please download CSV file for the
          direct mail company.
        </p>
        <SpinnerButton
          className="btn-pink"
          title={"Download CSV"}
          action={handleDownloadCsvFile}
          type={"button"}
          loading={loading}
        />
        <button
          onClick={() => {
            setSelectStep(1);
            navigate(`/campaigns/edit/${id}/${type}?step=1`);
          }}
          className="btn btn-light-gray ml-2"
        >
          Back
        </button>
        <button
          onClick={() => {
            showSuccessMsg("Request to store campaign successfully.");
            navigate(`/campaigns/list?type=direct_mail`);
          }}
          className="btn btn-gray ml-2"
        >
          Finish
        </button>
      </div>
    </>
  );
};

export default DirectMailStep2;
