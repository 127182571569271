import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const DraftEditor = (props) => {
  const {
    setFieldValue,
    value = "",
    setFieldTouched,
    emailTemplateData,
  } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    convertHtmlToDraft(value);
    setFieldTouched("body", true);
  }, [emailTemplateData]);

  const convertHtmlToDraft = (htmlData = "") => {
    const contentState = htmlToDraft(htmlData);

    const { contentBlocks, entityMap } = contentState;
    const contentStateData = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentStateData);
    setEditorState(editorState);
  };

  const onEditorStateChange = (editorStateData) => {
    setEditorState(editorStateData);
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFieldValue("body", data);
  };

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e) => {
          onEditorStateChange(e);
        }}
      />
    </>
  );
};

export default DraftEditor;
