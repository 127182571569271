import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";
import { setAuthToken, setUserData } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const backToAdmin = createAsyncThunk(
  "backToAdmin",
  async (userCode, { rejectWithValue }) => {
    try {
      const response = await getApi(`api/back-to-super-admin/${userCode}`);

      if (response.data.code === 200) {
        setAuthToken(response.data?.dataObj?.token);
        setUserData(JSON.stringify(response.data?.dataObj));
      }
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const backToAdminSlice = createSlice({
  name: "backToAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(backToAdmin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(backToAdmin.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(backToAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default backToAdminSlice.reducer;
