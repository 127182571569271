import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const setBreadCrumbsSlice = createSlice({
  name: "breadCrumb",
  initialState,
  reducers: {
    setBreadCrumb: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setBreadCrumb } = setBreadCrumbsSlice.actions;

export default setBreadCrumbsSlice.reducer;
