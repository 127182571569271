import {
  AdjustmentsVerticalIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  TagIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import SearchInput from "components/common/fields/SearchInput";
import AllContactsList from "components/contacts/all/AllContactsList";
import CustomersList from "components/contacts/customer/CustomersList";
import ImportLogs from "components/contacts/customer/ImportLogs";
import LeadList from "components/contacts/leadList/LeadList";
import ProspectsList from "components/contacts/prospect/ProspectsList";
import {
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
} from "module/util";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getAllContacts } from "redux/contacts/getAllContactsSlice";

const ContactsMain = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [sortIndex, setSortIndex] = useState(6);
  const [sortOrder, setSortOrder] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [listColumns, setListColumns] = useState([]);
  const [contactList, setContactList] = useState({ totalRecords: 0, data: [] });
  const [exportContactModal, setExportContactModal] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectType, setSelectType] = useState({ type: "NONE", page: [] });
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [unSelectedRowIds, setUnSelectedRowIds] = useState([]);
  const [tagModalOpen, setTagModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [deletedContactsModal, setDeletedContactsModal] = useState(false);
  const [processingModal, setProcessingModal] = useState({
    isProcessingModalOpen: false,
    processType: "",
  });
  const { id: currentLocationId } = getCurrentLocation();
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const currentCompanyId = getUserDataByParam("COMPANY_ID");

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const contactListData = useSelector(
    (state) => state?.getAllContacts?.data ?? [],
    shallowEqual
  );

  const contactListLoading = useSelector(
    (state) => state?.getAllContacts?.loading ?? false,
    shallowEqual
  );

  const urlTab = searchParams.get("tab") ? searchParams.get("tab") : "all";

  const currentActiveTab = [
    "all",
    "customers-list",
    "imported-customers",
    "prospects",
    "lead-list",
  ].includes(urlTab)
    ? urlTab
    : "all";

  const [activeTab, setActiveTab] = useState(currentActiveTab);

  const customerTabsArray = [
    { name: "All", value: "all", href: "#", current: false },
    { name: "Customers", value: "customers-list", href: "#", current: false },
    {
      name: "Prospects",
      value: "prospects",
      href: "#",
      current: false,
    },
    {
      name: "Leads",
      value: "lead-list",
      href: "#",
      current: false,
    },
    {
      name: "Import Logs",
      value: "imported-customers",
      href: "#",
      current: false,
    },
  ];

  const handleContactList = (search) => {
    const currentType =
      activeTab === "customers-list"
        ? "customer"
        : activeTab === "prospects"
        ? "prospect"
        : "";

    const sortObj = {
      sort: listColumns[sortIndex]?.accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      location_id: currentLocationId,
      type: currentType,
    };
    dispatch(getAllContacts(sortObj)).then((data) => {
      if (data?.payload.code === 200) {
        const totalRecords = data?.payload?.dataObj?.total ?? 0;
        const dataList = data?.payload?.dataObj?.data ?? [];
        setContactList({ totalRecords: totalRecords, data: dataList });
        if (isAllChecked && selectType.type == "ALL") {
          const ids = data?.payload?.dataObj?.data?.map((item) => item?.id);
          setSelectedRowIds((prevSelectedRowIds) => {
            const uniqueIds = new Set([...prevSelectedRowIds, ...ids]);
            return [...uniqueIds];
          });
        }
      }
    });
  };
  const globalSearch = (search) => {
    handleContactList(search);
  };

  const selectedContacts =
    selectType?.type === "ALL"
      ? contactList?.totalRecords
      : selectType?.type !== "NONE"
      ? selectedRowIds?.length
      : 0 ?? 0;

  const handleSelectAllContacts = () => {
    if (selectType?.type !== "ALL") {
      setIsAllChecked(true);
      setSelectType({ ...selectType, type: "ALL" });
      const totalData = contactList?.data?.map((item) => item.id);
      setSelectedRowIds((prevSelectedRowIds) => {
        const uniqueIds = new Set([...prevSelectedRowIds, ...totalData]);
        return [...uniqueIds];
      });
      setUnSelectedRowIds([]);
    } else if (selectType?.type === "ALL") {
      setSelectType({ ...selectType, type: "NONE" });
      setIsAllChecked(false);
      setSelectedRowIds([]);
    }
  };

  const breadCrumbsTitle = {
    all: "ALL",
    "customers-list": "Customers",
    "imported-customers": "Import Logs",
    prospects: "Prospects",
    "lead-list": "Leads",
  };

  useEffect(() => {
    setIsAllChecked(false);
    setSelectType({ type: "NONE", page: [] });
    setSelectedRowIds([]);
    setUnSelectedRowIds([]);
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: breadCrumbsTitle[activeTab],
          href: "",
          current: false,
        },
      ])
    );
  }, [activeTab, currentLocationId]);

  return (
    <>
      <div className="flex border-b border-collapse border-gray-300  w-full">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {customerTabsArray.map((tab) => (
            <span
              key={tab.name}
              onClick={() => {
                setActiveTab(tab.value);
                navigate(`/contacts?tab=${tab.value}`);
              }}
              className={classNames(
                " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                {
                  "border-pink-600 hover:border-pink-600 text-gray-800":
                    tab.value === activeTab,
                  "border-transparent": tab.value !== activeTab,
                }
              )}
              aria-current={tab.value === activeTab ? "page" : undefined}
            >
              {tab.name}
            </span>
          ))}
        </nav>
      </div>

      <div className={`flex items-center mt-2 w-full `}>
        {!["lead-list", "imported-customers"].includes(activeTab) && (
          <div className="flex justify-between w-full">
            {isCompanyActive && (
              <div className="flex items-center gap-x-1 text-gray-700 hover:text-gray-800 w-full">
                <button
                  className="icon-btn"
                  disabled={selectedContacts > 0}
                  onClick={() =>
                    navigate("/contacts/add", {
                      state: urlTab,
                    })
                  }
                >
                  <UserPlusIcon title="Add Customer" className="h-5 w-5" />
                </button>

                <button
                  className="icon-btn"
                  disabled={selectedContacts > 0}
                  onClick={() =>
                    navigate("/contacts/imports", {
                      state: urlTab,
                    })
                  }
                >
                  <ArrowDownTrayIcon
                    title="Import Customers"
                    className="h-5 w-5 "
                  />
                </button>

                <button
                  className="icon-btn"
                  disabled={
                    selectedContacts > 0 || contactList?.totalRecords === 0
                  }
                  onClick={() => {
                    setExportContactModal(true);
                  }}
                >
                  <ArrowUpTrayIcon
                    title="Export Customers"
                    className="h-5 w-5"
                  />
                </button>

                <button
                  type="button"
                  className="icon-btn"
                  disabled={selectedContacts <= 0}
                  onClick={() => {
                    setTagModal(true);
                  }}
                >
                  <TagIcon title="Add Tags" className="h-5 w-5" />
                </button>

                <button
                  type="button"
                  className="icon-btn"
                  disabled={selectedContacts <= 0}
                  onClick={() => {
                    setDeletedContactsModal(true);
                  }}
                >
                  <TrashIcon title="Delete Customer" className="h-5 w-5 " />
                </button>
                {selectedContacts !== 0 && (
                  <>
                    <span className="ml-4 font-light text-sm lg:text-base">
                      Selected Rows:{" "}
                      <span className="font-normal">
                        {selectedContacts ?? 0}
                      </span>
                    </span>{" "}
                    <span
                      className="ml-4 text-sm lg:text-base text-blue-500 underline cursor-pointer"
                      onClick={() => {
                        handleSelectAllContacts();
                      }}
                    >
                      {`${
                        selectType?.type === "ALL"
                          ? `Deselect All`
                          : `Select All`
                      } ${contactList?.totalRecords} records ?`}
                    </span>
                  </>
                )}
              </div>
            )}
            <div
              className={`h-full w-full sm:ml-2 flex items-center justify-end gap-x-2 px-2`}
            >
              <div className="flex items-center gap-1 h-full ">
                <SearchInput
                  globalSearch={globalSearch}
                  companyStatus={activeTab}
                  setSearchInput={setSearchInput}
                  searchInput={searchInput}
                />
                {isCompanyActive && (
                  <button
                    className="icon-btn"
                    onClick={() => {}}
                    disabled={true}
                  >
                    <AdjustmentsVerticalIcon
                      title="Filters are coming soon."
                      className="w-6 h-7 text-[#f23574]"
                      onClick={() => {}}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-2">
        <div className={activeTab === "all" ? "block" : "hidden"}>
          {activeTab === "all" && (
            <AllContactsList
              isCompanyActive={isCompanyActive}
              currentCompanyId={currentCompanyId}
              currentLocationId={currentLocationId}
              handleContactList={handleContactList}
              contactListData={contactListData}
              contactListLoading={contactListLoading}
              activeTab={activeTab}
              setSortIndex={setSortIndex}
              sortIndex={sortIndex}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPerPage={setPerPage}
              perPage={perPage}
              setListColumns={setListColumns}
              listColumns={listColumns}
              setExportContactModal={setExportContactModal}
              exportContactModal={exportContactModal}
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              setSelectType={setSelectType}
              selectType={selectType}
              setSelectedRowIds={setSelectedRowIds}
              selectedRowIds={selectedRowIds}
              setUnSelectedRowIds={setUnSelectedRowIds}
              unSelectedRowIds={unSelectedRowIds}
              setTagModal={setTagModal}
              tagModalOpen={tagModalOpen}
              setTags={setTags}
              tags={tags}
              setDeletedContactsModal={setDeletedContactsModal}
              deletedContactsModal={deletedContactsModal}
              setProcessingModal={setProcessingModal}
              processingModal={processingModal}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
              setContactList={setContactList}
              contactList={contactList}
              selectedContacts={selectedContacts}
            />
          )}
        </div>

        <div className={activeTab === "customers-list" ? "block" : "hidden"}>
          {activeTab === "customers-list" && (
            <CustomersList
              isCompanyActive={isCompanyActive}
              currentCompanyId={currentCompanyId}
              currentLocationId={currentLocationId}
              handleContactList={handleContactList}
              contactListData={contactListData}
              contactListLoading={contactListLoading}
              activeTab={activeTab}
              setSortIndex={setSortIndex}
              sortIndex={sortIndex}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPerPage={setPerPage}
              perPage={perPage}
              setListColumns={setListColumns}
              listColumns={listColumns}
              setExportContactModal={setExportContactModal}
              exportContactModal={exportContactModal}
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              setSelectType={setSelectType}
              selectType={selectType}
              setSelectedRowIds={setSelectedRowIds}
              selectedRowIds={selectedRowIds}
              setUnSelectedRowIds={setUnSelectedRowIds}
              unSelectedRowIds={unSelectedRowIds}
              setTagModal={setTagModal}
              tagModalOpen={tagModalOpen}
              setTags={setTags}
              tags={tags}
              setDeletedContactsModal={setDeletedContactsModal}
              deletedContactsModal={deletedContactsModal}
              setProcessingModal={setProcessingModal}
              processingModal={processingModal}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
              setContactList={setContactList}
              contactList={contactList}
              selectedContacts={selectedContacts}
            />
          )}
        </div>

        <div className={activeTab === "prospects" ? "block" : "hidden"}>
          {activeTab === "prospects" && (
            <ProspectsList
              isCompanyActive={isCompanyActive}
              currentCompanyId={currentCompanyId}
              currentLocationId={currentLocationId}
              handleContactList={handleContactList}
              contactListData={contactListData}
              contactListLoading={contactListLoading}
              activeTab={activeTab}
              setSortIndex={setSortIndex}
              sortIndex={sortIndex}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPerPage={setPerPage}
              perPage={perPage}
              setListColumns={setListColumns}
              listColumns={listColumns}
              setExportContactModal={setExportContactModal}
              exportContactModal={exportContactModal}
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              setSelectType={setSelectType}
              selectType={selectType}
              setSelectedRowIds={setSelectedRowIds}
              selectedRowIds={selectedRowIds}
              setUnSelectedRowIds={setUnSelectedRowIds}
              unSelectedRowIds={unSelectedRowIds}
              setTagModal={setTagModal}
              tagModalOpen={tagModalOpen}
              setTags={setTags}
              tags={tags}
              setDeletedContactsModal={setDeletedContactsModal}
              deletedContactsModal={deletedContactsModal}
              setProcessingModal={setProcessingModal}
              processingModal={processingModal}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
              setContactList={setContactList}
              contactList={contactList}
              selectedContacts={selectedContacts}
            />
          )}
        </div>

        <div className={activeTab === "lead-list" ? "block" : "hidden"}>
          {activeTab === "lead-list" && <LeadList />}
        </div>

        <div
          className={activeTab === "imported-customers" ? "block" : "hidden"}
        >
          {activeTab === "imported-customers" && <ImportLogs />}
        </div>
      </div>
    </>
  );
};

export default ContactsMain;
