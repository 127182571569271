import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import LocationsDropdown from "components/common/LocationsDropdown";
import MaintenanceModal from "components/common/MaintenanceModal";
import {
  createFirstLetterCapital,
  getCompanyStatus,
  isCompanyUser,
  removeUserSuffix,
} from "module/util";
import { Fragment, useState } from "react";

const Header = (props) => {
  const { currentUserData, setSidebarOpen } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  // const getTimeFromDownFile = async () => {
  //   await fetch(`${API_URL}down`, {
  //     mode: "cors",
  //     method: "GET",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/octet-stream",
  //       "Content-Disposition": "attachment; filename='picture.png'",
  //     },
  //   })
  //     .then((data) => {
  //       return data.text();
  //     })
  //     .then((data) => {
  //       setModalOpen(true);
  //     });
  // };

  return (
    <div className="sticky top-1 z-[999] flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex items-center w-3/4 xl:w-1/2">
          {isCompanyUser() && <LocationsDropdown />}
        </div>
        <div
          className={`flex  w-1/2 ${
            isCompanyUser() &&
            ["pending", "archived", "denied"].includes(getCompanyStatus())
              ? `justify-between`
              : ` justify-end`
          }`}
        >
          {isCompanyUser() &&
            ["pending", "archived", "denied"].includes(getCompanyStatus()) && (
              <div className="relative flex items-center">
                <span className="text-red-600 font-bold text-sm md:text-base xl:text-lg">
                  Your Account is {createFirstLetterCapital(getCompanyStatus())}{" "}
                </span>
              </div>
            )}
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <Menu
              as="div"
              className="flex xl:hidden relative -m-1.5  items-center p-1.5"
            >
              <Menu.Button>
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-3 top-11 mt-3 w-56 origin-top-right divide-y divide-gray-100 ring-black/5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={
                            "ml-4 text-[12px] md:text-sm  font-semibold leading-6 text-gray-90 p-2 w-full bg-white rounded-sm shadow-md"
                          }
                        >
                          {removeUserSuffix(currentUserData?.user_name ?? "")}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {/* //////////////////////////////////////////////////////////////////// */}
            <Menu
              as="div"
              className="hidden xl:flex relative -m-1.5  items-center p-1.5"
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <span className="flex lg:items-center">
                <span
                  className="ml-4 text-[12px] md:text-sm  font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  {removeUserSuffix(currentUserData?.user_name ?? "")}
                </span>
              </span>
            </Menu>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <MaintenanceModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={() => setModalOpen(false)}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default Header;
