import classNames from "classnames";
import AgeGraph from "components/campaigns/campaignsDetails/AgeGraph";
import CampaignsSummary from "components/campaigns/campaignsDetails/CampaignsSummary";
import ChildrenPresentGraph from "components/campaigns/campaignsDetails/ChildrenPresentGraph";
import DateRedemptionGraph from "components/campaigns/campaignsDetails/DateRedemptionGraph";
import GenderGraph from "components/campaigns/campaignsDetails/GenderGraph";
import GoogleHeatMap from "components/campaigns/campaignsDetails/GoogleHeatMap";
import HeatMapComponent from "components/campaigns/campaignsDetails/HeatMapComponent";
import HeatMapMaritalStatus from "components/campaigns/campaignsDetails/HeatMapMaritalStatus";
import HomeOwnerRenterGraph from "components/campaigns/campaignsDetails/HomeOwnerRenterGraph";
import HomeTypeGraph from "components/campaigns/campaignsDetails/HomeTypeGraph";
import IncomeGraph from "components/campaigns/campaignsDetails/IncomeGraph";
import MaritalStatusGraph from "components/campaigns/campaignsDetails/MaritalStatusGraph";
import SeniorPresentGraph from "components/campaigns/campaignsDetails/SeniorPresentGraph";
import ShowPageTitle from "components/common/ShowPageTitle";
import LoaderCommon from "components/common/fields/LoaderCommon";
import { API_URL } from "module/api";
import { calculateDensity, isValidObject } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { getCampaignReport } from "redux/campaign/getCampaignReportSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const CampaignsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [maxDensityPoint, setMaxDensityPoint] = useState(null);

  const [
    {
      getCampaignReportData = {},
      getCampaignDetailData = {},
      getCampaignDetailLoading = false,
      getCampaignReportLoading = false,
    },
  ] = useSelector((state) => [
    {
      getCampaignReportData: state?.getCampaignReport?.data ?? {},
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
      getCampaignReportLoading: state?.getCampaignReport?.loading ?? false,
      getCampaignDetailLoading: state?.getCampaignDetail?.loading ?? false,
    },
  ]);

  const barCodeImgUrl = `${API_URL}storage/campaigns-barcode/${getCampaignDetailData?.barcode_file}`;
  const campaignType = getCampaignDetailData?.type ?? "";

  const campaignMatrixData = isValidObject(getCampaignDetailData)
    ? JSON.parse(getCampaignDetailData?.campaign_metrics)
    : {};
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Campaign List",
          href: "/campaigns/list",
          current: true,
        },

        {
          name: "Campaign Details",
          href: "",
          current: false,
        },
      ])
    );

    if (id) {
      dispatch(getCampaignReport(id)).then((data) => {
        const coordinates = data?.payload?.lat_long ?? [];
        const radiusKm = 1.0;
        let currentMaxDensity = 0;
        let currentMaxDensityPoint = null;

        for (const point of coordinates) {
          const density = calculateDensity(point, coordinates, radiusKm);
          if (density > currentMaxDensity) {
            currentMaxDensity = density;
            currentMaxDensityPoint = point;
          }
        }
        setMaxDensityPoint(currentMaxDensityPoint);
      });
      dispatch(getCampaignDetail(id));
    }
  }, []);

  return (
    <div className="w-full py-2">
      <ShowPageTitle title="campaigns" />
      <CampaignsSummary
        getCampaignDetailData={getCampaignDetailData}
        loading={getCampaignReportLoading}
        campaignType={campaignType}
        barCodeImgUrl={barCodeImgUrl}
      />
      <div
        className={classNames("flex   mt-8 min-h-[112px]", {
          "justify-center gap-x-10": !["direct_mail"].includes(campaignType),
          "justify-between gap-x-2": ["direct_mail"].includes(campaignType),
        })}
      >
        {["direct_mail"].includes(campaignType) && (
          <>
            <div className="max-w-[145px] w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-center p-3 border-2 border-gray-400">
              <p>CUSTOMERS</p>
              <div className="text-center flex justify-center w-full">
                {getCampaignDetailLoading ? (
                  <LoaderCommon />
                ) : (
                  <div>
                    <p className="font-bold text-lg mb-2 mt-2 text-center">
                      {campaignMatrixData?.customer_count ?? 0}
                    </p>
                    <p className="text-green-700"></p>
                  </div>
                )}
              </div>
            </div>
            <div className="max-w-[145px] w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
              <p>LEAD LISTS</p>
              <div className="text-center flex justify-center w-full">
                {getCampaignDetailLoading ? (
                  <LoaderCommon />
                ) : (
                  <div>
                    <p className="font-bold text-lg mb-2 mt-2">
                      {campaignMatrixData?.potential_leads ?? 0}
                    </p>
                    <p className="text-green-700"></p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {["direct_mail", "digital_offer"].includes(campaignType) && (
          <div className="max-w-[145px] w-full bg-gradient-to-b from-[#ffd25f] to-[#fdf186]  text-center p-3 border-2 border-gray-400">
            <p>TOTAL SENT</p>
            <div className="text-center flex justify-center w-full">
              {getCampaignReportLoading ? (
                <LoaderCommon />
              ) : (
                <div>
                  <p className="font-bold text-lg mb-2 mt-2">
                    {campaignMatrixData?.list_size ?? 0}
                  </p>
                  <p className="text-green-700"></p>
                </div>
              )}
            </div>
          </div>
        )}

        {["direct_mail"].includes(campaignType) && (
          <div className="max-w-[145px] w-full bg-gradient-to-b from-[#f35288] to-[#f186ac]  text-center p-3 border-2 border-gray-400">
            <p>NEW CUSTOMERS</p>
            <div className="text-center flex justify-center w-full">
              {getCampaignReportLoading ? (
                <LoaderCommon />
              ) : (
                <div>
                  <p className="font-bold text-lg mb-2 mt-2">
                    {getCampaignReportData?.converted ?? 0}
                  </p>
                  <p className="text-green-700"></p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="max-w-[145px] w-full bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-center  p-3 border-2 border-gray-400">
          <p>REDEEMED</p>
          <div className="text-center flex justify-center w-full">
            {getCampaignReportLoading ? (
              <LoaderCommon />
            ) : (
              <div>
                <p className="font-bold text-lg mb-2 mt-2">
                  {getCampaignReportData?.redeemed ?? 0}
                </p>
                <p className="text-green-700"></p>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-[145px] w-full bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-center  p-3 border-2 border-gray-400">
          <p>REVENUE</p>
          <div className="text-center flex justify-center w-full">
            {getCampaignReportLoading ? (
              <LoaderCommon />
            ) : (
              <div>
                <p className="font-bold text-lg mb-2 mt-2 break-words">
                  {getCampaignReportData?.revenue ?? 0}
                </p>
                <p className="text-green-700"></p>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-[145px] w-full bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-center  p-3 border-2 border-gray-400">
          <p>COST</p>
          <div className="text-center flex justify-center w-full">
            {getCampaignReportLoading ? (
              <LoaderCommon />
            ) : (
              <div>
                <p className="font-bold text-lg mb-2 mt-2 break-words">
                  {getCampaignReportData?.totalCost ?? 0}
                </p>
                <p className="text-green-700"></p>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-[145px] w-full bg-gradient-to-b from-[#f35288] to-[#f186ac] text-center p-3 border-2 border-gray-400">
          <p>ROI</p>
          <div className="text-center flex justify-center w-full">
            {getCampaignReportLoading ? (
              <LoaderCommon />
            ) : (
              <div>
                <p className="font-bold text-lg mb-2 mt-2">
                  {getCampaignReportData?.roi ?? 0}
                </p>
                <p className="text-green-700"></p>
              </div>
            )}
          </div>
        </div>
      </div>

      {["direct_mail", "digital_offer"].includes(campaignType) && (
        <>
          <div
            className={classNames("mt-10", {
              "pointer-events-none blur-[2px] grayscale":
                getCampaignReportLoading,
            })}
          >
            {isValidObject(getCampaignReportData) &&
            !getCampaignReportLoading ? (
              <GoogleHeatMap
                getCampaignReportData={getCampaignReportData}
                maxDensityPoint={maxDensityPoint}
              />
            ) : (
              <div className="w-full h-[500px] flex justify-center items-center">
                Google Map is loading...
              </div>
            )}
          </div>
          <div className="mt-10">
            <HeatMapComponent
              getCampaignReportData={getCampaignReportData}
              loading={getCampaignReportLoading}
            />
          </div>
          <div className="mt-10">
            <HeatMapMaritalStatus
              getCampaignReportData={getCampaignReportData}
            />
          </div>
          <div className="mt-10">
            {isValidObject(getCampaignReportData) ? (
              <DateRedemptionGraph
                campaignId={id}
                getCampaignReportData={getCampaignReportData}
              />
            ) : (
              <div className="h-[350px]">
                <div className="text-[14px] text-gray-700 font-semibold">
                  Date Vs Redemption
                </div>
                <div className="text-[14px] flex justify-center items-center mt-10">
                  No Data present in the graph!
                </div>
              </div>
            )}
          </div>

          <div className="mt-10">
            <MaritalStatusGraph getCampaignReportData={getCampaignReportData} />
          </div>
          <div className="mt-10">
            <IncomeGraph getCampaignReportData={getCampaignReportData} />
          </div>

          <div className="mt-10">
            <AgeGraph getCampaignReportData={getCampaignReportData} />
          </div>
          <div className="mt-10">
            <GenderGraph getCampaignReportData={getCampaignReportData} />
          </div>
          <div className="mt-10">
            <HomeOwnerRenterGraph
              getCampaignReportData={getCampaignReportData}
            />
          </div>
          <div className="mt-10">
            <HomeTypeGraph getCampaignReportData={getCampaignReportData} />
          </div>
          <div className="mt-10">
            <ChildrenPresentGraph
              getCampaignReportData={getCampaignReportData}
            />
          </div>
          <div className="mt-10">
            <SeniorPresentGraph getCampaignReportData={getCampaignReportData} />
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignsDetails;
