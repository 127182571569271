import { getCurrentLocation, isValidArray } from "module/util";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getTagsList } from "redux/companySetting/getTagsListSlice";

export default function TagDropDownOptions() {
  const dispatch = useDispatch();
  const { id: currentLocationId } = getCurrentLocation();
  const tagListData = useSelector(
    (state) => state?.getTagsList?.data ?? [],
    shallowEqual
  );

  useEffect(() => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getTagsList(formData));
  }, [currentLocationId]);

  if (isValidArray(tagListData?.dataObj?.data)) {
    const options = tagListData?.dataObj?.data?.map((item) => {
      return {
        label: item?.tag,
        value: item?.id,
        hex: item?.hex,
      };
    });
    return options;
  }
  return [];
}
