import {
  ClipboardDocumentIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import RatingField from "components/contacts/customer/RatingField";
import copy from "copy-to-clipboard";
import {
  getCompanyStatus,
  getCurrentLocation,
  getUserData,
  showErrorMsg,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStarTemplate } from "redux/reputation/addStarTemplateSlice";
import { deleteStarTemplate } from "redux/reputation/deleteStarTemplateSlice";
import { getStarReview } from "redux/reputation/getStarReviewSlice";

export default function ReputationSetting() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState();
  const [sortIndex, setSortIndex] = useState(12);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const totalRecords = 100;
  const [userId, setUserId] = useState({ editUserId: "", deleteUserId: "" });
  const [templateData, setTemplatedData] = useState({
    location_id: "",
    company_id: "",
    rating: "",
    rating_template: "",
  });
  const currentPerPage = showPerPage;
  const selectStar = [
    { name: "5 Star", value: "5" },
    { name: "4 Star", value: "4" },
    { name: "3 Star", value: "3" },
    { name: "2 Star", value: "2" },
    { name: "1 Star", value: "1" },
  ];
  const companyId = getUserData() ? JSON.parse(getUserData()) : "";
  const currentLocation = getCurrentLocation();
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const first_name = "First_Name";

  const getStarReviewData = useSelector(
    (state) => state?.getStarReview?.data ?? []
  );
  const getStarReviewLoading = useSelector(
    (state) => state?.getStarReview?.loading ?? false
  );
  const addStarLoading = useSelector(
    (state) => state?.addStarTemplate?.loading ?? false
  );
  const deleteStarLoading = useSelector(
    (state) => state?.deleteStarTemplate?.loading ?? false
  );

  const starTable = getStarReviewData?.dataObj
    ?.map((e) => {
      return { ...e, rating: Number(e?.rating) };
    })
    .sort((a, b) => b?.rating - a?.rating);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (templateData?.rating && templateData?.rating_template) {
      let match = getStarReviewData?.dataObj?.filter(
        (template) => template?.rating === templateData?.rating
      );
      let data = {
        location_id: currentLocation?.id,
        company_id: companyId?.company_id,
        rating: templateData?.rating,
        rating_template: templateData?.rating_template,
      };
      if (userId?.editUserId) {
        // call same add api for update data
        dispatch(addStarTemplate(data));
      } else {
        if (match?.length > 0) {
          showErrorMsg(`${templateData?.rating} star template already exist`);
        } else {
          dispatch(addStarTemplate(data)).then((data) => {
            if (data?.payload?.code === 201) {
              showSuccessMsg(
                data?.payload?.message ?? "Review Template stored successfully."
              );
            } else {
              showErrorMsg("Error");
            }
          });
        }
      }
      setTemplatedData({ rating: "", rating_template: "" });
      setUserId({ editUserId: "" });
    }
  };
  const handleEdit = (data) => {
    setTemplatedData(data);
  };

  const handleDelete = () => {
    dispatch(deleteStarTemplate(userId?.deleteUserId));
    setIsModalOpen(false);
  };
  useEffect(() => {
    let data = {
      location_id: currentLocation?.id,
      company_id: companyId?.company_id,
    };
    dispatch(getStarReview(data));
  }, [addStarLoading, deleteStarLoading]);

  const action = [
    {
      name: <PencilSquareIcon title={"Edit"} className="w-[1.3rem]" />,
      event: (cell) => {
        handleEdit(cell.row.values);
        setUserId({ editUserId: cell.row.values?.id });
      },
    },
    {
      name: <TrashIcon title={"Delete"} className="w-[1.3rem]" />,
      event: (cell) => {
        setIsModalOpen(true);
        setUserId({ ...userId, deleteUserId: cell.row.values?.id });
      },
    },
  ];

  const columns = [
    {
      Header: "Star",
      accessor: "rating",
      disableSortBy: true,
      Cell: ({ cell }) => {
        return (
          <div
            className={` ${
              userId?.editUserId === cell.row.values?.id && `opacity-50`
            } `}
          >
            <span className="my-1 list-none flex justify-start  p-0">
              <RatingField rating={cell?.row?.values?.rating ?? 0} />
            </span>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "rating_template",
      disableSortBy: true,
      Cell: ({ cell }) => {
        return (
          <div
            className={` ${
              userId?.editUserId === cell.row.values?.id && `opacity-50`
            }`}
          >
            {cell?.row?.values?.rating_template}
          </div>
        );
      },
    },
    ...(isCompanyActive
      ? [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className={`flex  items-center gap-x-[4px]`}>
                {action?.map((action, actionIndex) => (
                  <div key={actionIndex}>
                    <button
                      onClick={() => {
                        action.event(cell);
                      }}
                      className={`hover:text-gray-900 hover:underline ${
                        userId?.editUserId === cell.row.values?.id &&
                        "opacity-50 cursor-not-allowed"
                      }`}
                      disabled={userId?.editUserId === cell.row.values?.id}
                    >
                      {action.name}
                    </button>
                  </div>
                ))}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="mt-4 border gap-x-4 border-gray-200 p-4 bg-white">
      <h2 className="font-normal text-gray-900 text-lg">Review Template </h2>
      <div className="md:flex md:justify-between gap-4 my-8 w-full  ">
        <div className="w-full md:w-1/2">
          <form onSubmit={handleSubmit}>
            <div className="mb-9">
              <select
                value={templateData?.rating}
                disabled={userId?.editUserId || !isCompanyActive}
                onChange={(event) =>
                  setTemplatedData({
                    ...templateData,
                    rating: event?.target?.value,
                  })
                }
                className="input-field"
              >
                <option value="">Select Star</option>
                {selectStar?.map((starOption, index) => (
                  <option key={index} value={starOption?.value}>
                    {starOption?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className=" relative top-2 transition-all duration-600 ">
              {isCompanyActive && (
                <div
                  className="absolute top-[-2.5rem] flex gap-2 border cursor-pointer hover:bg-gray-200 text-[13px] font-light border-gray-300 p-1"
                  title="Click to copy"
                  id="copyName"
                  onClick={() => {
                    const isCopied = copy(`%%${first_name}%%`);
                    if (isCopied) {
                      showSuccessMsg("Copied to clipboard!");
                    }
                  }}
                >
                  <p className=" ">{`%%${first_name}%%`}</p>
                  <ClipboardDocumentIcon className="h-6 w-6" />
                </div>
              )}
              <textarea
                rows="5"
                id="editMessage"
                placeholder="Enter Review"
                className="input-field mt-2 "
                value={templateData?.rating_template}
                onChange={(event) => {
                  setTemplatedData({
                    ...templateData,
                    rating_template: event?.target?.value,
                  });
                }}
                readOnly={!isCompanyActive}
              />
              {isCompanyActive && (
                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="btn-pink w-20"
                    disabled={
                      !templateData?.rating || !templateData?.rating_template
                    }
                  >
                    {userId?.editUserId ? "Update" : "Save"}
                  </button>
                  {userId?.editUserId && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-light-gray"
                        onClick={() => {
                          setUserId({ editUserId: "" });
                          setTemplatedData({
                            location_id: "",
                            company_id: "",
                            rating: "",
                            rating_template: "",
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="w-full md:w-1/2 mt-5 md:mt-0 ">
          <div className="flex flex-col ">
            <CustomTable
              data={starTable ?? []}
              isLoading={getStarReviewLoading ?? false}
              columns={columns}
              defaultSortIndexColumn={sortIndex}
              defaultOrder={sortOrder}
              setSortIndex={setSortIndex}
              setSortOrder={setSortOrder}
              totalRecords={totalRecords}
              perPage={perPage ? perPage : currentPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPerPage={setPerPage}
              isPaginationHide={true}
              colSpan={12}
            />
          </div>
          {isModalOpen && (
            <ConfirmationModal
              isModalOpen={isModalOpen}
              setModalOpen={setIsModalOpen}
              handleAction={handleDelete}
              message={"You want to delete this review template ?"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
