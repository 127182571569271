import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getReviewTemplate = createAsyncThunk(
  "reputation/get-account-locations",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getApi(
        `api/gmb/get_account_locations_reviews?company_location_id=${data?.location_id}& company_id=${data?.company_id}
       `
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getReviewTemplateSlice = createSlice({
  name: "getReviewTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewTemplate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReviewTemplate.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getReviewTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getReviewTemplateSlice.reducer;
