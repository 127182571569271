import { HomeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isAdmin, isSuperAdmin, isValidObject } from "module/util";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BreadCrumb = () => {
  const breadCrumbData = useSelector(
    (state) => state?.getBreadCrumbs?.value ?? []
  );

  return (
    <nav className="flex h-8 bg-gray-100" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1 ml-2">
        {(isSuperAdmin() || isAdmin()) && (
          <li className="ml-2 mr-2">
            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-500 hover:text-gray-700 mr-1">
                Admin
              </span>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M11.25 2.75v18.5a.75.75 0 1 0 1.5 0V2.75a.75.75 0 1 0-1.5 0Z" />
              </svg>
            </div>
          </li>
        )}
        <li className="mr-1 ml-2">
          <div>
            <Link to="/dashboard" className="text-gray-600 hover:text-gray-700">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>

        {breadCrumbData.map(
          (page) =>
            isValidObject(page) && (
              <li key={page.name}>
                <div className="flex items-center">
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  {page.current ? (
                    <Link
                      to={page.href}
                      className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {page.name}
                    </Link>
                  ) : (
                    <span
                      className={classNames("ml-1 text-sm font-medium ", {
                        "text-gray-500 hover:text-gray-700": page?.isDisable,
                        "text-gray-600 hover:text-gray-800": !page?.isDisable,
                      })}
                    >
                      {page.name}
                    </span>
                  )}
                </div>
              </li>
            )
        )}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
