import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "",
};

export const selectedLocationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    changeLocation: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeLocation } = selectedLocationSlice.actions;

export default selectedLocationSlice.reducer;
