import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";
import { setAuthToken, setUserData } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const loginUser = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postApi("api/auth/login", data);
      if (response.data.code === 200) {
        setAuthToken(response.data?.dataObj?.token);
        setUserData(JSON.stringify(response.data?.dataObj));
      }
      return response?.data;
    } catch (error) {
      if (error.response && error.response?.data) {
        return rejectWithValue(error.response?.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default loginSlice.reducer;
