import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getOrdersList = createAsyncThunk(
  "customer/orders/list",
  async (data = {}, { rejectWithValue }) => {
    // const { search, sort, order, page, perPage, location_id, customer_id } =
    //   data;

    // const queryString = createQueryParams({
    //   search,
    //   sort,
    //   order,
    //   page,
    //   perPage,
    //   location_id,
    //   customer_id,
    // });
    try {
      const response = await postApi(`api/square/show-customer-orders`, data);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOrdersListSlice = createSlice({
  name: "getOrdersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrdersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrdersList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getOrdersListSlice.reducer;
