import { Dialog, Transition } from "@headlessui/react";
import AlertBox from "components/common/AlertBox";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Formik } from "formik";
import { getUserDataByParam, showErrorMsg } from "module/util";
import { marketPlaceForm, showError } from "module/validation";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitQuery } from "redux/marketplace/submitQuerySlice";

const MarketPlaceModal = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    handleAction,
    isLoading,
    message = "You want to delete this record?",
    selectedOption = "",
  } = props;

  const [isShowAlert, setAlert] = useState({ show: false, message: "Success" });
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const addMarketPlaceLoading = useSelector(
    (state) => state?.submitQuery?.loading ?? false
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const initialValues = {
    name: getUserDataByParam("USER_NAME") ?? "",
    email: getUserDataByParam("USER_EMAIL") ?? "",
    comment: "",
  };

  const types = {
    "ADA Compliance for Website": "ada",
    "Free Wi-fi for Customers": "wifi",
  };

  const handleSubmit = (values) => {
    const formData = {
      name: values?.name,
      email: values?.email,
      comment: values?.comment,
      type: types[selectedOption],
    };
    dispatch(submitQuery(formData)).then((data) => {
      if (data.payload.code === 200) {
        const alertMessage = `Dear ${
          values?.name ?? ""
        }, Thanks for showing interest in ${selectedOption}. Our team will get in touch with you soon!`;
        setAlert({ show: true, message: alertMessage });
      } else {
        showErrorMsg("Something went wrong");
      }
    });
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex justify-between">
                  <p className="text-lg font-bold">
                    {isShowAlert?.show ? "Submitted !!" : "Submit a query"}
                  </p>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 outline-0 ml-auto inline-flex items-center"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="mt-4 mx-3">
                  <div className="flex flex-wrap  -mx-3">
                    {isShowAlert?.show ? (
                      <AlertBox
                        isShowAlert={isShowAlert}
                        isCloseButtonRequired={false}
                      />
                    ) : (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={() =>
                          marketPlaceForm(validationMsgObj?.marketplace ?? {})
                        }
                        onSubmit={handleSubmit}
                        enableReinitialize
                      >
                        {({
                          errors,
                          touched,
                          validateField,
                          validateForm,
                          handleChange,
                          values,
                          handleSubmit,
                          handleBlur,
                        }) => (
                          <form
                            className="w-full min-w-xl bg-white"
                            onSubmit={handleSubmit}
                          >
                            <div className="flex flex-wrap -mx-3 mb-3">
                              <div className="w-full px-3">
                                <label
                                  htmlFor="name"
                                  className="input-field-label"
                                >
                                  Name
                                </label>
                                <div className="mt-2">
                                  <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={values?.name ?? ""}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                    className="input-field"
                                  />
                                </div>
                                {touched?.name && showError(errors?.name)}
                              </div>
                            </div>

                            <div className="flex flex-wrap -mx-3 mb-3">
                              <div className="w-full px-3">
                                <label
                                  htmlFor="email"
                                  className="input-field-label"
                                >
                                  Email
                                </label>
                                <div className="mt-2">
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={values?.email ?? ""}
                                    onChange={handleChange}
                                    autoComplete="email"
                                    placeholder="Enter Email"
                                    className="input-field"
                                  />
                                </div>
                                {touched?.email && showError(errors?.email)}
                              </div>
                            </div>

                            <div className="flex flex-wrap -mx-3 mb-6">
                              <div className="w-full px-3">
                                <label
                                  htmlFor="Comment"
                                  className="input-field-label"
                                >
                                  Comment
                                </label>
                                <div className="mt-2">
                                  <Field
                                    type="text"
                                    as="textarea"
                                    name="comment"
                                    id="comment"
                                    value={values?.comment ?? ""}
                                    onChange={handleChange}
                                    placeholder="Enter comment"
                                    className="input-field"
                                  />
                                </div>
                                {touched?.comment && showError(errors?.comment)}
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <SpinnerButton
                                className="btn-pink"
                                type="Submit"
                                action={() => {}}
                                title="Send"
                                loading={addMarketPlaceLoading}
                              />
                            </div>
                          </form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MarketPlaceModal;
