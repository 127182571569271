import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getCompanyAccountDetail = createAsyncThunk(
  "company/list",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await getApi(`api/companies/${companyId}`);
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCompanyAccountDetailSlice = createSlice({
  name: "getCompanyAccountDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyAccountDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompanyAccountDetail.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getCompanyAccountDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getCompanyAccountDetailSlice.reducer;
