import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getAiSuggestion = createAsyncThunk(
  "reputation/associate-locations",
  async (data, { rejectWithValue }) => {
    const { review_text, star, company_location_id, review_id } = data;
    try {
      const response = await postApi(
        `api/gmb/reply_suggestion?review_text=${review_text}&star_rating=${star}&company_location_id=${company_location_id}&review_id=${review_id}`
      );

      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAiSuggestionSlice = createSlice({
  name: "getAiSuggestion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAiSuggestion.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAiSuggestion.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getAiSuggestion.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getAiSuggestionSlice.reducer;
