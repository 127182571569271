import { statesArray } from "module/data";
import React from "react";
import Select from "react-select";

const DropdownField = (props) => {
  const {
    values = {},
    setFieldValue = () => {},
    name = "",
    isDisabled = false,
    isReadOnly = false,
    setFieldTouched = () => {},
  } = props;
  return (
    <Select
      name={name}
      value={statesArray.filter(function (option) {
        return (
          option.value === values.state ||
          option.value2 === values?.state?.toLowerCase()
        );
      })}
      onChange={(e) => setFieldValue(name, e.value)}
      onBlur={(e) => {
        setFieldTouched(name, true);
      }}
      options={statesArray}
      styles={{
        control: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          borderRadius: 0,
          pointerEvents: isDisabled || isReadOnly ? "none" : "all",
          cursor: isDisabled || isReadOnly ? "text" : "pointer",
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? "#abadb180" : "#fff",
          color: "black",
          ":hover": {
            backgroundColor: "#abadb180",
          },
        }),
        input: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          ":hover": {
            borderColor: "#64656680",
            border: "1px",
          },
        }),
      }}
    />
  );
};

export default DropdownField;
