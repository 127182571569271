import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { isValidArray } from "module/util";
import { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomDropDown = ({
  filterDropDown = [],
  selectedLocation = {},
  setSelectedLocation = () => {},
  createLoactionStatusBadge = () => {},
  setButtonDisable = () => {},
  setSelectLoader = () => {},
  locationListData = {},
}) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left   w-full">
        <Menu.Button
          className={classNames(
            "inline-flex w-full  justify-between gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
            {
              "shadow-lg hover:cursor-pointer":
                isValidArray(filterDropDown) && filterDropDown?.length > 1,
              "hover:cursor-default":
                !isValidArray(filterDropDown) || filterDropDown?.length <= 1,
            }
          )}
        >
          {createLoactionStatusBadge(selectedLocation?.name)
            ? createLoactionStatusBadge(selectedLocation?.name)
            : selectedLocation?.name ?? ""}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-700"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10  mt-1 max-h-[300px] w-full flex flex-col overflow-auto  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
            <div className="py-1">
              {isValidArray(filterDropDown) &&
                filterDropDown?.map((selectData, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <span
                        onClick={() => {
                          const data = {
                            name: selectData?.name,
                            id: selectData?.id,
                            category: selectData?.category,
                          };
                          setSelectedLocation(data);
                          if (
                            selectData?.name == locationListData?.location?.name
                          ) {
                            setButtonDisable(true);
                          } else {
                            setButtonDisable(false);
                          }
                          setSelectLoader && setSelectLoader(true);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm hover:cursor-pointer"
                        )}
                      >
                        {createLoactionStatusBadge(selectData?.name)
                          ? createLoactionStatusBadge(selectData?.name)
                          : selectData?.name}
                      </span>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default CustomDropDown;
