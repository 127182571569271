import { addDecimalToNumber, isValidArray } from "module/util";
import ReactApexChart from "react-apexcharts";

const LengthInResidenceGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.lengthInResidence ?? {};

  const keysArray = Object.keys(graphDetails);
  const seriesData = keysArray.map((key) => graphDetails?.[key]?.percent);
  const config = {
    series: [
      {
        name: "",
        type: "bar",
        data: isValidArray(seriesData) ? seriesData : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      dataLabels: {
        enabled: false,
        formatter: function (val, opt) {
          return `${addDecimalToNumber(val)}%`;
        },
      },
      labels: keysArray,
      xaxis: {
        type: "category",
        labels: {
          show: true,
          rotateAlways: false,
          minHeight: 100,
          trim: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: [{ show: false }],
      tooltip: {
        enabled: true,
        y: {
          formatter: (
            seriesName,
            { series, dataPointIndex, seriesIndex, w }
          ) => {
            const finalValue = series[seriesIndex][dataPointIndex];
            return `${addDecimalToNumber(finalValue)}%`;
          },
        },
      },
      legend: {
        show: false,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LengthInResidenceGraph;
