import datePicker from "assets/images/datePicker.svg";
import classNames from "classnames";
import DigitalOfferStep2 from "components/campaigns/digitalOffer/DigitalOfferStep2";
import DigitalOfferStep3 from "components/campaigns/digitalOffer/DigitalOfferStep3";
import CustomCostInput from "components/common/fields/CustomCostInput";
import SpinnerButton from "components/common/fields/SpinnerButton";
import CustomerFetchingLoader from "components/leadList/CustomerFetchingLoader";
import { Field, Formik } from "formik";
import {
  convertDate,
  getCompanyStatus,
  getCurrentLocation,
  getDateFormatLocal,
  getUserDataByParam,
  isValidArray,
  isValidObject,
} from "module/util";
import {
  digitalOfferForm1,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addCampaign } from "redux/campaign/addCampaignSlice";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { updateCampaignDetail } from "redux/campaign/updateCampaignDetailSlice";

const DigitalOfferStep1 = (props) => {
  const { setSearchParams, setSelectStep, type, selectedStep } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();
  const initFormRef = formRef?.current?.values ?? {};
  const defaultDateFormat = getDateFormatLocal();
  const isCompanyActive = getCompanyStatus() === "active";

  const isEdit = !!id;
  const { id: currentLocationId, location: currentLocationName } =
    getCurrentLocation();
  const companyId = getUserDataByParam("COMPANY_ID");

  const handleEndDate = (days = 0, startDate) => {
    if (!!startDate) {
      var daysToAdd = days;
      var endDate = new Date(
        startDate?.getTime() + daysToAdd * 24 * 60 * 60 * 1000
      );
      return endDate;
    }
  };

  const [
    {
      addCampaignLoading = false,
      updateCampaignDetailLoading = false,
      getCampaignDetailData = {},
      validationMsgObj = {},
    },
  ] = useSelector((state) => [
    {
      addCampaignLoading: state?.addCampaign?.loading,
      updateCampaignDetailLoading: state?.updateCampaignDetail?.loading,
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
      validationMsgObj: state?.getMessages?.data ?? {},
    },
  ]);

  const offersObj = isValidArray(getCampaignDetailData?.offers)
    ? getCampaignDetailData.offers[0]
    : {};

  const isDisableOnActiveCamp =
    isEdit && getCampaignDetailData?.status === "active";

  const initialValues = {
    name: isEdit ? getCampaignDetailData?.name : initFormRef?.name,
    isEdit: isEdit,
    campaign_cost: isEdit
      ? getCampaignDetailData?.campaign_cost
      : initFormRef?.campaign_cost,
    offer_valid_days: isEdit
      ? getCampaignDetailData?.offer_valid_days ?? ""
      : initFormRef?.offer_valid_days,
    start_date: isEdit
      ? convertDate(getCampaignDetailData?.start_date)
      : new Date(),
    end_date: isEdit
      ? convertDate(getCampaignDetailData?.end_date)
      : new Date(),
    expires: isEdit
      ? getCampaignDetailData?.expires
      : initFormRef?.expires ?? "yes",
    crossAccept: isEdit
      ? getCampaignDetailData?.cross_accept
      : initFormRef?.crossAccept ?? "no",
    mailer_upload: isEdit ? "" : initFormRef?.mailer_upload,
    offer_name: isEdit ? offersObj?.offer_name : initFormRef?.offer_name,
    discount_cost: isEdit
      ? offersObj?.discount_cost
      : initFormRef?.discount_cost,
    budget: isEdit ? getCampaignDetailData?.budget ?? "" : initFormRef?.budget,
    location: currentLocationName,
  };

  const handleSubmit = (value) => {
    const { id: currentLocationId } = getCurrentLocation();
    const expiration = value?.expires === "yes" ? true : false;

    const formData = {
      name: value?.name ?? "",
      campaign_cost: value?.campaign_cost ?? "",
      offer_valid_days: value?.offer_valid_days ?? "",
      start_date: value?.start_date ?? "",
      end_date: expiration ? value?.end_date : "" ?? "",
      expires: value?.expires ?? "no",
      cross_accept: value?.crossAccept ?? "no",
      mailer_upload: value?.mailer_upload ?? "",
      type: "digital_offer",
      location_id: currentLocationId,
      budget: value?.budget,
      offers: [
        {
          ...(isValidObject(offersObj) && isEdit
            ? { id: offersObj?.id ?? 0 }
            : {}),
          offer_name: value?.offer_name ?? "",
          discount_cost: value?.discount_cost,
        },
      ],
    };

    if (isEdit) {
      dispatch(
        updateCampaignDetail({
          id: id,
          formData: formData,
        })
      ).then((data) => {
        if (data?.payload?.code === 200) {
          dispatch(getCampaignDetail(id));
          setSelectStep(2);
          navigate(`/campaigns/edit/${data?.payload?.dataObj}/${type}?step=2`);
        } else {
          handleServerValidation(data);
          // showErrorMsg("Something went wrong");
        }
      });
    } else {
      dispatch(
        addCampaign({
          formData: formData,
        })
      ).then((data) => {
        if (data?.payload.code === 200) {
          setSelectStep(2);
          navigate(`/campaigns/edit/${data?.payload?.dataObj}/${type}?step=2`);
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetail(id));
    }
  }, []);

  return (
    <>
      {selectedStep == 1 ? (
        <div className="flex justify-center gap-x-6">
          <Formik
            initialValues={initialValues}
            validationSchema={() =>
              digitalOfferForm1(validationMsgObj?.campaign ?? {})
            }
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formRef}
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <form className="w-full min-w-xl mt-4" onSubmit={handleSubmit}>
                  <div className="w-full border border-gray-200 p-3 bg-white mt-4">
                    <div className="flex  flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="name" className="input-field-label">
                          Campaign Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            value={values?.name ?? ""}
                            onChange={handleChange}
                            placeholder="Enter Name"
                            className="input-field"
                          />
                        </div>
                        {touched?.name && showError(errors?.name)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          htmlFor="campaign_cost"
                          className="input-field-label"
                        >
                          Ad Cost
                        </label>
                        <CustomCostInput
                          values={values?.campaign_cost ?? ""}
                          setFieldValue={setFieldValue}
                          name={"campaign_cost"}
                          title="Ad Cost"
                          onBlur={handleBlur}
                        />
                        {touched?.campaign_cost &&
                          showError(errors?.campaign_cost)}
                      </div>
                    </div>

                    <div className="flex  flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="location" className="input-field-label">
                          Location
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="location"
                            id="location"
                            value={values?.location ?? ""}
                            onChange={handleChange}
                            autoComplete="location"
                            placeholder="Enter location"
                            readOnly={true}
                            disabled={true}
                            className="input-field"
                          />
                        </div>
                        {touched?.location && showError(errors?.location)}
                      </div>

                      <div className="w-full md:w-1/2 px-3">
                        <label
                          htmlFor="offer_name"
                          className="input-field-label"
                        >
                          Offer Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="offer_name"
                            id="offer_name"
                            value={values?.offer_name ?? ""}
                            onChange={handleChange}
                            autoComplete="offer_name"
                            placeholder="Enter Offer Name"
                            className="input-field"
                          />
                        </div>
                        {touched?.offer_name && showError(errors?.offer_name)}
                      </div>
                    </div>

                    <div className="flex   flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="expires" className="input-field-label">
                          Code Expires
                        </label>
                        <div
                          className={classNames("mt-2 flex items-center", {
                            "opacity-50": isDisableOnActiveCamp,
                          })}
                        >
                          <label>
                            <Field
                              type="radio"
                              name="expires"
                              value="yes"
                              disabled={isDisableOnActiveCamp}
                              className="remove-focus-ring"
                              onChange={(e) => {
                                setFieldValue("expires", e.target.value);
                              }}
                            />{" "}
                            Yes
                          </label>
                          <label className="ml-2">
                            <Field
                              type="radio"
                              name="expires"
                              value="no"
                              disabled={isDisableOnActiveCamp}
                              className="remove-focus-ring"
                              onChange={(e) => {
                                setFieldValue("expires", e.target.value);
                              }}
                            />{" "}
                            No
                          </label>
                        </div>
                        {touched?.expires && showError(errors?.expires)}
                      </div>
                      <div className="w-full md:w-1/4 px-3">
                        <label
                          htmlFor="crossAccept"
                          className="input-field-label"
                        >
                          Accept in other locations ?
                        </label>
                        <div
                          className={classNames("mt-2 flex items-center", {
                            "opacity-50": isDisableOnActiveCamp,
                          })}
                        >
                          <label>
                            <Field
                              type="radio"
                              name="crossAccept"
                              value="yes"
                              disabled={isDisableOnActiveCamp}
                              className="remove-focus-ring"
                              onChange={(e) =>
                                setFieldValue("crossAccept", e.target.value)
                              }
                            />{" "}
                            Yes
                          </label>
                          <label className="ml-2">
                            <Field
                              type="radio"
                              name="crossAccept"
                              value="no"
                              disabled={isDisableOnActiveCamp}
                              className="remove-focus-ring"
                              onChange={(e) =>
                                setFieldValue("crossAccept", e.target.value)
                              }
                            />{" "}
                            No
                          </label>
                        </div>
                        {touched?.crossAccept && showError(errors?.crossAccept)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          htmlFor="discount_cost"
                          className="input-field-label"
                        >
                          Offer Cost
                        </label>
                        <CustomCostInput
                          values={values?.discount_cost ?? ""}
                          setFieldValue={setFieldValue}
                          name={"discount_cost"}
                          title="Offer Cost"
                          onBlur={handleBlur}
                        />
                        {touched?.discount_cost &&
                          showError(errors?.discount_cost)}
                      </div>
                    </div>

                    <div className="flex   flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          htmlFor="start_date"
                          className="input-field-label"
                        >
                          Target Start date
                        </label>
                        <div className="mt-2 ">
                          <div
                            className={classNames("relative w-full z-9", {
                              "opacity-50": isDisableOnActiveCamp,
                            })}
                          >
                            <ReactDatePicker
                              className="input-field"
                              disabled={isDisableOnActiveCamp}
                              selected={values?.start_date ?? ""}
                              dateFormat={defaultDateFormat}
                              onChange={(date) => {
                                setFieldValue("start_date", date);
                              }}
                              minDate={new Date()}
                              placeholderText="Select Start Date"
                            />
                            <img
                              src={datePicker}
                              className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                              alt="Date picker"
                            />
                          </div>
                        </div>
                        {touched?.start_date && showError(errors?.start_date)}
                      </div>
                      {values?.expires === "yes" ? (
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            htmlFor="end_date"
                            className="input-field-label"
                          >
                            End Date
                          </label>
                          <div className="mt-2">
                            <div
                              className={classNames("relative w-full", {
                                "opacity-50": isDisableOnActiveCamp,
                              })}
                            >
                              <ReactDatePicker
                                className="input-field"
                                selected={values?.end_date ?? ""}
                                disabled={isDisableOnActiveCamp}
                                dateFormat={defaultDateFormat}
                                onChange={(date) => {
                                  setFieldValue("end_date", date);
                                }}
                                minDate={new Date()}
                                placeholderText="Select End Date"
                              />
                              <img
                                src={datePicker}
                                className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"
                                alt="Date picker"
                              />
                            </div>
                          </div>
                          {touched?.end_date && showError(errors?.end_date)}
                        </div>
                      ) : (
                        <div className="w-full md:w-1/2 px-3">
                          <label htmlFor="budget" className="input-field-label">
                            Campaign Budget/Month
                          </label>
                          <div className="mt-2">
                            <CustomCostInput
                              values={values?.budget ?? ""}
                              setFieldValue={setFieldValue}
                              name={"budget"}
                              title="Budget"
                              onBlur={handleBlur}
                            />
                            {touched?.budget && showError(errors?.budget)}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex   flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          htmlFor="offer_valid_days"
                          className="input-field-label"
                        >
                          Offer Valid Days
                        </label>
                        <div
                          className={classNames("mt-2", {
                            "opacity-50": isDisableOnActiveCamp,
                          })}
                        >
                          <Field
                            type="number"
                            name="offer_valid_days"
                            id="offer_valid_days"
                            min={1}
                            disabled={isDisableOnActiveCamp}
                            value={values?.offer_valid_days ?? ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            autoComplete="offer_valid_days"
                            placeholder="Enter Offer Valid Days"
                            className="input-field"
                          />
                          {touched?.offer_valid_days &&
                            showError(errors?.offer_valid_days)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between mb-6 mt-4">
                    <div>
                      <button
                        onClick={() => {
                          navigate(`/campaigns/list?type=${type}`);
                        }}
                        className="btn btn-light-gray"
                      >
                        Cancel
                      </button>
                    </div>
                    {isCompanyActive && (
                      <SpinnerButton
                        className="btn-pink"
                        title={"Next"}
                        action={() => {}}
                        type={"submit"}
                        loading={
                          addCampaignLoading || updateCampaignDetailLoading
                        }
                      />
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <>
          {selectedStep == 2 ? (
            <>
              <DigitalOfferStep2
                setSelectStep={setSelectStep}
                type={type}
                isEdit={isEdit}
                companyId={companyId}
                currentLocationId={currentLocationId}
                campaignId={id}
                updateCampaignDetailLoading={updateCampaignDetailLoading}
              />
            </>
          ) : (
            <>
              <DigitalOfferStep3
                setSelectStep={setSelectStep}
                type={type}
                companyId={companyId}
                currentLocationId={currentLocationId}
                campaignId={id}
                updateCampaignDetailLoading={updateCampaignDetailLoading}
              />
            </>
          )}
        </>
      )}
      {(addCampaignLoading || updateCampaignDetailLoading) && (
        <CustomerFetchingLoader
          isModalOpen={true}
          message="Please wait a while, Processing....."
        />
      )}
    </>
  );
};

export default DigitalOfferStep1;
