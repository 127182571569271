import { getAuthToken, removeAuthToken } from "module/util";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "redux/front/logoutSlice";

const Logout = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!getAuthToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(logoutUser()).then(() => {
        removeAuthToken();
        navigate("/");
      });
    }
  }, []);

  return <div></div>;
};

export default Logout;
