import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import { campaignTypeForList } from "module/data";
import { convertTimeStampToDate, showPerPage } from "module/util";
import { useState } from "react";

const ExpiredList = (props) => {
  const { data } = props;
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const totalRecords = 100;
  const currentPerPage = showPerPage;

  const columns = [
    {
      Header: "Registration Date",
      accessor: "created_date",
      Cell: ({ cell }) => (
        <span>
          {convertTimeStampToDate(cell.row.values?.created_date ?? "")}
        </span>
      ),
    },
    {
      Header: "Campaign",
      accessor: "campaign_name",
    },
    {
      Header: "Campaign Type",
      accessor: "campaign_type",
      Cell: ({ cell }) => (
        <span className="">
          {campaignTypeForList[cell?.row?.values?.campaign_type]}
        </span>
      ),
    },
    {
      Header: "Expired On",
      accessor: "end_date",
      Cell: ({ cell }) => (
        <span>{convertTimeStampToDate(cell.row.values?.end_date ?? "")}</span>
      ),
    },
  ];

  return (
    <div className="">
      <ShowPageTitle title="customers" />

      <div className="mt-4 flex flex-col">
        <CustomTable
          data={data?.expired ?? []}
          isLoading={false}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          isPaginationHide={true}
        />
      </div>
    </div>
  );
};

export default ExpiredList;
