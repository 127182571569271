import { addDecimalToNumber } from "module/util";
import ReactApexChart from "react-apexcharts";

const OwnerRenterGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.owner_renter ?? {};

  const createArrayForSeries = () => {
    const offerData1 = [
      graphDetails?.["Owner"]?.[0]?.percent ?? 0,
      graphDetails?.["Renter"]?.[0]?.percent ?? 0,
    ];

    const seriesArray = [
      {
        name: "",
        type: "bar",
        data: offerData1,
      },
    ];

    return seriesArray;
  };

  const config = {
    series: createArrayForSeries(),
    options: {
      chart: {
        height: 300,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      dataLabels: {
        enabled: false,
        formatter: function (val, opt) {
          return `${addDecimalToNumber(val)}%`;
        },
      },
      labels: ["Owner", "Renter"],
      xaxis: {
        type: "category",
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: [{ show: false }],
      tooltip: {
        enabled: true,
        y: {
          formatter: (
            seriesName,
            { series, dataPointIndex, seriesIndex, w }
          ) => {
            const finalValue = series[seriesIndex][dataPointIndex];
            return `${addDecimalToNumber(finalValue)}%`;
          },
        },
      },
      legend: {
        show: false,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default OwnerRenterGraph;
