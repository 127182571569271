import { isValidArray } from "module/util";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

const TypeAheadSource = (props) => {
  const {
    setFieldValue,
    data = [],
    values = "",
    setFieldTouched = () => {},
  } = props;
  const [results, setResults] = useState([]);

  useEffect(() => {
    const finalOptions = data?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    setResults(finalOptions);
  }, [data]);
  return (
    <CreatableSelect
      isClearable={true}
      name="source"
      value={
        isValidArray(results)
          ? results?.filter(function (option) {
              return option?.value === values;
            })
          : { label: values, value: values }
      }
      onChange={(e) => {
        const isExist = results?.find((item) => item.value === e?.value);
        !isExist &&
          setResults([...results, { label: e?.value, value: e?.value }]);
        setFieldValue("source", e?.value);
      }}
      onBlur={(e) => {
        setFieldTouched("source", true);
      }}
      options={results}
      styles={{
        control: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          borderRadius: 0,
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? "#abadb180" : "#fff",
          color: "black",
          ":hover": {
            backgroundColor: "#abadb180",
          },
        }),
        input: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          ":hover": {
            borderColor: "#64656680",
            border: "1px",
          },
        }),
      }}
    />
  );

  // const { setFieldValue, data = [], values = "" } = props;
  // const [searchTerm, setSearchTerm] = useState(values);
  // const [results, setResults] = useState([]);

  // useEffect(() => {
  //   setSearchTerm(values);
  // }, [values]);

  // const handleInputChange = (e) => {
  //   const { value } = e.target;
  //   setSearchTerm(value);
  //   setFieldValue("source", value);
  //   const filteredResults = data.filter((result) =>
  //     result.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setResults(filteredResults);
  // };

  // return (
  //   <div className="container w-full ">
  //     <input
  //       type="text"
  //       placeholder="Enter Source"
  //       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
  //       value={searchTerm}
  //       onChange={handleInputChange}
  //     />
  //     {isValidArray(results) && (
  //       <div className="rounded border max-w-[50%] min-w-[39%] absolute opacity-1 shadow-lg bg-white z-99 overflow-auto max-h-[20%] ">
  //         <ul>
  //           {results.map((result, index) => (
  //             <li
  //               key={index}
  //               className="hover:cursor-pointer hover:bg-gray-300 leading-8"
  //               onClick={() => {
  //                 setSearchTerm(result);
  //                 setFieldValue("source", result);
  //                 setResults([]);
  //               }}
  //             >
  //               <span className="mx-2"> {result}</span>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     )}
  //   </div>
  // );
};

export default TypeAheadSource;
