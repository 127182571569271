import classNames from "classnames";
import CouponsList from "components/redemption/coupons/CouponsList";
import OrdersList from "components/redemption/orders/OrdersList";
import Redemption from "components/redemption/redemption/Redemption";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const RedemptionMain = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const urlTab = searchParams.get("tab");

  const currentActiveTab = ["redemptions", "orders", "coupons"].includes(urlTab)
    ? urlTab
    : "redemptions";

  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const customerTabsArray = [
    { name: "Redemptions", value: "redemptions", href: "#", current: false },
    { name: "Orders", value: "orders", href: "#", current: false },
    {
      name: "Coupons",
      value: "coupons",
      href: "#",
      current: false,
    },
  ];

  const breadCrumbsTitle = {
    redemptions: "Redeem Details",
    orders: "Orders",
    coupons: "Coupons",
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Redemptions",
          href: "/redemptions",
          current: false,
        },
        {
          name: breadCrumbsTitle[activeTab],
          href: "",
          current: false,
        },
      ])
    );
  }, [activeTab]);

  return (
    <>
      <div className="flex border-b border-collapse border-gray-300  w-full ">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {customerTabsArray.map((tab) => (
            <span
              key={tab.name}
              onClick={() => {
                setActiveTab(tab.value);
                navigate(`/redemptions?tab=${tab.value}`);
              }}
              className={classNames(
                " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                {
                  "border-pink-600 hover:border-pink-600 text-gray-800":
                    tab.value === activeTab,
                  "border-transparent": tab.value !== activeTab,
                }
              )}
              aria-current={tab.value === activeTab ? "page" : undefined}
            >
              {tab.name}
            </span>
          ))}
        </nav>
      </div>
      <div className="mt-4 w-full">
        <div className={activeTab === "redemptions" ? "block" : "hidden"}>
          {activeTab === "redemptions" && <Redemption />}
        </div>
        <div className={activeTab === "orders" ? "block" : "hidden"}>
          {activeTab === "orders" && <OrdersList />}
        </div>
        <div className={activeTab === "coupons" ? "block" : "hidden"}>
          {activeTab === "coupons" && <CouponsList />}
        </div>
      </div>
    </>
  );
};

export default RedemptionMain;
