import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getStarReview = createAsyncThunk(
  "reputation/get-star-review",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getApi(
        `api/review-templates/${data?.company_id}/${data?.location_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getStarReviewSlice = createSlice({
  name: "getStarReview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStarReview.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStarReview.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getStarReview.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getStarReviewSlice.reducer;
