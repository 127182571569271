import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import DropdownField from "components/common/fields/DropdownField";
import GoogleAddressField from "components/common/fields/GoogleAddressField";
import GoogleMapComponent from "components/common/fields/GoogleMapComponent";
import SpinnerButton from "components/common/fields/SpinnerButton";
import CustomerSearchForm from "components/contacts/customer/CustomerSearchForm";
import RatingField from "components/contacts/customer/RatingField";
import { Field, Formik } from "formik";
import TagDropDownOptions from "components/contacts/tags/TagDropDownOptions";
import TagsSelect from "components/contacts/tags/TagsSelect";
import {
  formatPhoneNumberAlways,
  getCurrentLocation,
  getDateFormatLocal,
  getUserData,
  getUserDataByParam,
  isCompanyUser,
  isValidArray,
  showSuccessMsg,
} from "module/util";
import {
  customerForm,
  handleServerValidation,
  showError,
} from "module/validation";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addCustomer } from "redux/customer/addCustomerSlice";
import { updateContactDetail } from "redux/customer/updateContactDetailSlice";
import { locationList } from "redux/location/locationListSlice";

const CustomerForm = ({
  contactType = "customer",
  setSearchFormOpen = () => {},
  isSearchFormOpen = true,
  handleContactDetails = () => {},
  getCustomerDetailData = {},
  setLatLong = () => {},
  latLong = {},
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [addTags, setAddTags] = useState({ isAdding: false, tag: "" });
  const { id } = useParams();
  const formRef = useRef();

  const defaultDateFormat = getDateFormatLocal();
  const { id: currentLocationId } = getCurrentLocation();
  const { company_id: currentCompanyId } = JSON.parse(getUserData());

  const isEdit = location.pathname.includes("/contacts/edit") && id;

  const [
    {
      addCustomerLoading = false,
      updateCustomerLoading = false,
      validationMsgObj = {},
    },
  ] = useSelector((state) => [
    {
      addCustomerLoading: state?.addCustomer?.loading,
      updateCustomerLoading: state?.updateContactDetail?.loading,
      validationMsgObj: state?.getMessages?.data ?? {},
    },
  ]);

  useEffect(() => {
    dispatch(locationList());
  }, []);

  const tagsOptions = TagDropDownOptions();

  const tags = !!getCustomerDetailData?.tags
    ? JSON.parse(getCustomerDetailData?.tags)
    : [];

  const selectedOptions =
    isValidArray(tagsOptions) &&
    isValidArray(tags) &&
    tagsOptions?.filter((item) => {
      return tags?.includes(item?.value) ?? false;
    });

  let customerDOB = getCustomerDetailData?.dob
    ? moment.utc(getCustomerDetailData?.dob).toDate()
    : "";

  const initialValues = {
    firstName: isEdit
      ? getCustomerDetailData?.first_name
      : formRef?.current?.values?.firstName,
    lastName: isEdit
      ? getCustomerDetailData?.last_name
      : formRef?.current?.values?.lastName,
    birthday: isEdit ? customerDOB : "",
    address1: isEdit
      ? getCustomerDetailData?.address1
      : formRef?.current?.values?.address1,
    address2: isEdit
      ? getCustomerDetailData?.address2
      : formRef?.current?.values?.address2,
    city: isEdit ? getCustomerDetailData?.city : formRef?.current?.values?.city,
    state: isEdit
      ? getCustomerDetailData?.state
      : formRef?.current?.values?.state,
    zip: isEdit ? getCustomerDetailData?.zip : formRef?.current?.values?.zip,
    phone: isEdit
      ? formatPhoneNumberAlways(getCustomerDetailData?.phone)
      : formRef?.current?.values?.phone,
    email: isEdit
      ? getCustomerDetailData?.email
      : formRef?.current?.values?.email,
    status: isEdit ? getCustomerDetailData?.status : "active",
    lat_long: isEdit
      ? getCustomerDetailData?.lat_long
      : formRef?.current?.values?.lat_long,
    rating: isEdit
      ? getCustomerDetailData?.score
      : formRef?.current?.values?.score,
    tags:
      isEdit && isValidArray(tagsOptions)
        ? selectedOptions
        : formRef?.current?.values?.tags,
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const companyId = getUserDataByParam("COMPANY_ID");
    const finalTags = isValidArray(values?.tags)
      ? values?.tags?.map((item) => item.value)
      : [];

    if (isEdit) {
      const formData = {
        company_id: companyId,
        location_id: currentLocationId,
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        address1: values?.address1 ?? "",
        city: values?.city ?? "",
        state: values?.state ?? "",
        zip: values?.zip ?? "",
        phone: values?.phone ?? "",
        email: values?.email ?? "",
        dob: values?.birthday ?? "",
        status: values?.status ?? "",
        lat_long: values?.lat_long ?? "",
        tags: JSON.stringify(finalTags),
      };
      dispatch(updateContactDetail({ id, contactType, formData })).then(
        (data) => {
          if (data?.payload?.code == 200) {
            showSuccessMsg(data?.payload?.message);
            handleContactDetails();
          } else {
            handleServerValidation(data);
          }
        }
      );
    } else {
      const formData = {
        company_id: companyId,
        location_id: currentLocationId,
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        address1: values?.address1 ?? "",
        address2: values?.address2 ?? "",
        city: values?.city ?? "",
        state: values?.state ?? "",
        zip: values?.zip ?? "",
        phone: values?.phone ?? "",
        email: values?.email ?? "",
        dob: values?.birthday ?? "",
        status: values?.status ?? "",
        lat_long: values?.lat_long ?? "",
        tags: JSON.stringify(finalTags),
      };
      dispatch(addCustomer(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          navigate(`/contacts?tab=${location?.state}`);
        } else {
          handleServerValidation(data);
        }
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (isCompanyUser() && isEdit) {
      setSearchFormOpen(false);
    }
  }, []);

  return (
    <div className="">
      {isSearchFormOpen ? (
        <CustomerSearchForm
          setSearchFormOpen={setSearchFormOpen}
          currentCompanyId={currentCompanyId}
        />
      ) : (
        <div className="border border-gray-200 p-3 bg-white">
          <Formik
            initialValues={initialValues}
            validationSchema={customerForm(validationMsgObj?.customers ?? {})}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            // validateOnBlur={false}
            innerRef={formRef}
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              setTouched,
              values,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap gap-x-4">
                  <div className="flex-1">
                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          htmlFor="firstName"
                          className="input-field-label"
                        >
                          First Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={values?.firstName ?? ""}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            className="input-field"
                          />
                        </div>
                        {touched?.firstName && showError(errors?.firstName)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="lastName" className="input-field-label">
                          Last Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={values?.lastName ?? ""}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            className="input-field"
                          />
                        </div>
                        {touched?.lastName && showError(errors?.lastName)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label htmlFor="address" className="input-field-label">
                          Address
                        </label>
                        <div className="mt-2">
                          <GoogleAddressField
                            name="address1"
                            setFieldValue={setFieldValue}
                            value={values?.address1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Enter Address"}
                            setLatLong={setLatLong}
                          />
                        </div>
                        {touched?.address1 && showError(errors?.address1)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="Address2" className="input-field-label">
                          Address2
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="address2"
                            id="Address2"
                            value={values?.address2 ?? ""}
                            onChange={handleChange}
                            autoComplete="Address2"
                            placeholder="Enter Address2"
                            className="input-field"
                          />
                        </div>
                        {touched?.address2 && showError(errors?.address2)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label htmlFor="city" className="input-field-label">
                          City
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            value={values?.city ?? ""}
                            onChange={handleChange}
                            autoComplete="address-level2"
                            placeholder="Enter City"
                            className="input-field"
                          />
                        </div>
                        {touched?.city && showError(errors?.city)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="state" className="input-field-label">
                          State
                        </label>
                        <div className={`mt-2`}>
                          <DropdownField
                            setFieldValue={setFieldValue}
                            values={values}
                            name="state"
                            setFieldTouched={setFieldTouched}
                          />
                        </div>
                        {touched?.state && showError(errors?.state)}
                      </div>
                    </div>

                    <div className="flex flex-wrap  -mx-3 mb-3">
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="zip" className="input-field-label">
                          ZIP / Postal code
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            value={values?.zip ?? ""}
                            onChange={handleChange}
                            autoComplete="zip"
                            placeholder="Enter Zip/Postal Code"
                            className="input-field"
                          />
                        </div>
                        {touched?.zip && showError(errors?.zip)}
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label htmlFor="phone" className="input-field-label">
                          Phone
                        </label>
                        <CustomPhoneInput
                          values={values}
                          name="phone"
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                        />
                        {touched?.phone && showError(errors?.phone)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label htmlFor="email" className="input-field-label">
                          Email
                        </label>
                        <div className="mt-2">
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            value={values?.email ?? ""}
                            onChange={handleChange}
                            autoComplete="email"
                            placeholder="Enter Email"
                            className="input-field"
                          />
                        </div>
                        {touched?.email && showError(errors?.email)}
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="birthday" className="input-field-label">
                          Birthday
                        </label>
                        <div className="mt-2">
                          <ReactDatePicker
                            className="input-field"
                            selected={values?.birthday}
                            dateFormat={defaultDateFormat}
                            onChange={(date) => {
                              setFieldValue("birthday", date);
                            }}
                            maxDate={new Date()}
                            placeholderText="Select Birthday"
                          />
                        </div>
                        {touched?.birthday && showError(errors?.birthday)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className=" w-full md:w-1/2 px-3">
                        <TagsSelect
                          currentCompanyId={currentCompanyId}
                          currentLocationId={currentLocationId}
                          value={values?.tags ?? []}
                          setFieldValue={setFieldValue}
                          tagsOptions={tagsOptions}
                          addTags={addTags}
                          setAddTags={setAddTags}
                        />
                      </div>

                      {isEdit && (
                        <div className="w-full md:w-1/2 px-3 flex">
                          <div className="w-full">
                            <label
                              htmlFor="Rating"
                              className="input-field-label"
                            >
                              Rating
                            </label>
                            <div className="mt-3">
                              <RatingField rating={values?.rating ?? 0} />
                            </div>
                          </div>

                          <div className="w-full ">
                            <label htmlFor="Tupe" className="input-field-label">
                              Type
                            </label>
                            <div className="mt-2 text-gray-800 uppercase">
                              {`${contactType}`}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between mt-6">
                      <div className="flex gap-x-2">
                        {!isEdit && (
                          <button
                            type="button"
                            onClick={() => setSearchFormOpen(true)}
                            className="btn btn-gray"
                          >
                            Back
                          </button>
                        )}
                        <SpinnerButton
                          className="btn-pink"
                          title={"Save"}
                          action={() => {}}
                          type={"submit"}
                          loading={addCustomerLoading || updateCustomerLoading}
                        />
                      </div>

                      <button
                        type="button"
                        onClick={() =>
                          navigate(`/contacts?tab=${location?.state}`)
                        }
                        className="btn btn-gray ml-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <div className="flex-2 w-[50%]">
                    <GoogleMapComponent latLong={latLong} />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default CustomerForm;
