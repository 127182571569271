import { Dialog, Transition } from "@headlessui/react";
import { isValidArray, showSuccessMsg } from "module/util";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";
import { deleteContacts } from "redux/contacts/deleteContactsSlice";
import { addTagToContact } from "redux/customer/addTagToContactSlice";

export default function ProcessingModal({
  selectedRowIds,
  setSelectedRowIds,
  currentLocationId,
  searchInput,
  tags,
  handleCustomerList,
  setProcessingModal,
  processingModal,
  setSelectType,
  selectType,
  setIsAllChecked,
  contactType,
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (processingModal?.processType === "DELETE") {
      const formData = {
        contact_ids: selectedRowIds ?? [],
        location_id: currentLocationId,
        all_checked: selectType?.type === `ALL` ? true : false,
        search: searchInput,
        type: contactType,
      };

      if (isValidArray(selectedRowIds)) {
        dispatch(deleteContacts(formData)).then((data) => {
          if (data?.payload?.code == 200) {
            showSuccessMsg(
              data?.payload?.message ?? "Contact(s) deleted successfully."
            );
            setProcessingModal({
              isProcessingModalOpen: false,
              processType: "",
            });
            setSelectType({ type: "NONE", page: [] });
            setIsAllChecked(false);
            setSelectedRowIds([]);
            handleCustomerList(searchInput);
          } else {
            handleServerValidation(data);
          }
        });
      }
    } else if (processingModal?.processType === "ADD_TAGS") {
      const finalTags = tags?.map((item) => item.value);
      const formData = {
        location_id: currentLocationId,
        action: "add",
        tags: finalTags,
        type: contactType,
        contacts: selectedRowIds,
        all_checked: selectType?.type == "ALL" ? true : false,
        search: searchInput,
      };

      if (isValidArray(finalTags)) {
        dispatch(addTagToContact(formData)).then((data) => {
          if (data?.payload?.code === 200) {
            showSuccessMsg(
              data?.payload?.message ?? "Tags updated successfully"
            );
            setProcessingModal({
              isProcessingModalOpen: false,
              processType: "",
            });
            setSelectType({ type: "NONE", page: [] });
            setIsAllChecked(false);
            setSelectedRowIds([]);
            handleCustomerList(searchInput);
          } else {
            handleServerValidation(data);
          }
        });
      }
    }
  }, []);

  return (
    <Transition.Root
      show={processingModal?.isProcessingModalOpen}
      as={Fragment}
    >
      <Dialog
        static
        as="div"
        className="relative z-[1111]"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              static
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[20rem] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:ml-[15rem] 2xl:ml-0 sm:p-6">
                <div className="flex justify-center items-center mt-4 h-[145px]">
                  <MoonLoader
                    size={85}
                    className=""
                    color="gray"
                    speedMultiplier={0.4}
                  />
                </div>
                <div className="w-full mt-8 flex flex-col gap-y-4 justify-center items-center">
                  <div className=" rounded-md  animate-pulse text-gray-600">
                    Processing...
                  </div>
                  <span className="text-red-500">
                    {"Please! Do not leave or refresh the page."}
                  </span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
