import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { saveSquareImport } from "redux/companySetting/saveSquareImportSlice";

export default function SquareImportModal({
  currentCompanyId,
  currentLocationId,
  setIsImportingCompleted,
  isModalOpen,
  setIsModalOpen,
}) {
  const dispatch = useDispatch();

  const [isImported, setIsImported] = useState();
  const [progress, setProgress] = useState();
  const [importingType, setImportingType] = useState("Importing Customers...");
  const [isError, setIsError] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [moveStep, setMoveStep] = useState(0);
  const cancelButtonRef = useRef(null);
  const customersRef = useRef(null);
  const couponsRef = useRef(null);
  const ordersRef = useRef(null);
  const responseRef = useRef(null);

  const move = (i, currentRef) => {
    const customerImport = currentRef.current;
    let width = 1;
    const frame = () => {
      if (width >= 100) {
        clearInterval(id);
        setTimeout(() => {
          if (i == 0) {
            setImportingType("Importing Coupons...");
            move(1, couponsRef);
          } else if (i == 1) {
            setImportingType("Importing Orders...");
            move(2, ordersRef);
          } else if (i == 2) {
            setImportingType("Finalizing Details... Please wait");
            setMoveStep(2);
          } else if (i === 3) {
            setIsImported(true);
            setImportingType("Successfully Imported!");
            setTimeout(() => {
              setIsModalOpen(false);
              setIsImportingCompleted(true);
            }, [2000]);
          }
        }, [2000]);
      } else {
        width++;
        customerImport.style.width = width + "%";
        setProgress(width);
      }
    };
    const id = setInterval(frame, 30);
    return () => {
      clearInterval(id);
    };
  };

  const handleImportModal = () => {
    if (apiResponse === "success") {
      move(3, responseRef);
    } else if (apiResponse === "error") {
      setIsError(true);
      const timeoutId = setTimeout(() => {
        setIsModalOpen(false);
        setIsImportingCompleted(true);
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  };

  useEffect(() => {
    move(0, customersRef);
    const formData = {
      // types: ["locations", "orders", "coupons", "customers"],
      company_id: currentCompanyId,
      location_id: currentLocationId,
    };

    dispatch(saveSquareImport(formData)).then((data) => {
      if (data?.payload.code === 200) {
        setApiResponse("success");
      } else {
        setApiResponse("error");
      }
    });
  }, []);

  useEffect(() => {
    if (moveStep === 2) {
      handleImportModal();
    }
  }, [apiResponse, moveStep]);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        static
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              static
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[20rem] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:ml-[15rem] 2xl:ml-0 sm:p-6">
                <div className="flex justify-center">
                  {isImported ? (
                    <CheckCircleIcon className="w-20 h-[130px] text-green-500" />
                  ) : !isError ? (
                    <div className="w-48 flex flex-col items-center">
                      <div
                        className={`
                        flex justify-center`}
                      >
                        <ArrowDownIcon
                          className={`${`import w-20 text-gray-500`}`}
                        />
                      </div>
                      <div
                        className={`w-[70%] mt-[25px] h-[25px] border-b-4 rounded-full border-gray-500`}
                      ></div>
                    </div>
                  ) : (
                    <XCircleIcon className="w-20 h-[130px] text-red-500" />
                  )}
                </div>
                <div className="my-4 ml-3 flex justify-center">
                  {!isError ? (
                    <div className="flex flex-wrap  -mx-3 mb-3 text-md font-semibold text-gray-700">{`${importingType} ${
                      importingType !== "Finalizing Details... Please wait" &&
                      importingType !== "Successfully Imported!"
                        ? `${progress}%`
                        : ``
                    }`}</div>
                  ) : (
                    <div className="text-md font-semibold text-red-500 animate-pulse">
                      Something went wrong! Closing Modal.
                    </div>
                  )}
                </div>
                <div className="relative h-[10px] pt-1 mx-5 flex">
                  <div className="overflow-hidden h-full mb-4 text-xs flex  bg-gray-200 w-full">
                    <div
                      ref={customersRef}
                      id="customers"
                      className="customers shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-[#ffd25f] to-[#fdf186]"
                    ></div>
                  </div>
                  <div className="overflow-hidden h-full mb-4 text-xs flex  bg-gray-200 w-full">
                    <div
                      ref={couponsRef}
                      id="coupons"
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-[#f35288] to-[#f186ac]"
                    ></div>
                  </div>
                  <div className="overflow-hidden h-full mb-4 text-xs flex  bg-gray-200 w-full">
                    <div
                      ref={ordersRef}
                      id="orders"
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-[#37d6eb] to-[#B5E4FA]"
                    ></div>
                  </div>

                  <div className="overflow-hidden h-full mb-4 text-xs flex  bg-gray-200 w-full">
                    <div
                      ref={responseRef}
                      id="coupons"
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-[#a5d583] to-[#dcf1af]"
                    ></div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
