import SpinnerButton from "components/common/fields/SpinnerButton";

import { API_URL } from "module/api";
import {
  getCurrentMonthYear,
  isValidObject,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";

const PrintAdStep2 = (props) => {
  const { setSelectStep, type } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [{ getCampaignDetailData = {} }] = useSelector((state) => [
    {
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
    },
  ]);

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetail(id));
    }
  }, []);

  const barCodeImgUrl = `${API_URL}storage/campaigns-barcode/${getCampaignDetailData?.barcode_file}`;

  const handleDownloadFile = () => {
    setLoading(true);

    var a = document.createElement("a");
    a.setAttribute(
      "href",
      `${API_URL}download-barcode/${getCampaignDetailData?.barcode_file}`
    );
    a.setAttribute("download", getCampaignDetailData?.barcode_file);
    a.setAttribute("target", "_blank");
    a.click();

    setLoading(false);
  };

  return (
    <>
      <div className="mt-4 space-y-6 ml-4">
        <p>
          Your Barcode Tracker for {getCurrentMonthYear()} Get 1 Free Ad Tacker
          Campaign is READY!! Make Sure to Download and send to your PRINT and
          DESIGN Team to Implement on your AD.
        </p>

        {isValidObject(getCampaignDetailData) && (
          <img
            alt="barcode"
            src={barCodeImgUrl}
            className="qvt-sheet-container border-white border-2 w-[480px] h-[80px]"
          />
        )}

        <SpinnerButton
          className="btn-pink"
          title={"Download"}
          action={() => handleDownloadFile()}
          type={"button"}
          loading={loading}
        />
        <button
          onClick={() => {
            setSelectStep(1);
            navigate(`/campaigns/edit/${id}/${type}?step=1`);
          }}
          className="btn btn-light-gray ml-2"
        >
          Back
        </button>
        <button
          onClick={() => {
            showSuccessMsg("Request to store campaign successfully.");
            navigate(`/campaigns/list?type=${type}`);
          }}
          className="btn btn-gray ml-2"
        >
          Finish
        </button>
      </div>
    </>
  );
};

export default PrintAdStep2;
