import { Dialog, Transition } from "@headlessui/react";
import copy from "copy-to-clipboard";
import { API_URL } from "module/api";
import { Fragment, useEffect, useRef, useState } from "react";

const ZapCodeModal = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    handleAction,
    isLoading,
    zapCode = "",
  } = props;

  const [isCopied, setCopied] = useState(false);
  const [isCopiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    setCopied(false);
    setCopiedURL(false);
  }, [isModalOpen]);

  const cancelButtonRef = useRef(null);
  useEffect(() => {
    if (isCopied || isCopiedURL) {
      setTimeout(() => {
        setCopied(false);
        setCopiedURL(false);
      }, 2000);
    }
  }, [isCopied, isCopiedURL]);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999]"
        initialFocus={cancelButtonRef}
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto outline-0 inline-flex items-center"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="my-2 ml-3">
                  <div className=" -mx-3 mb-3 mt-3">
                    <p className="flex justify-between">
                      <span className="underline">Zap Code :</span>

                      <span
                        className="px-3 py-[2px] shadow hover:bg-slate-50 cursor-pointer border bg-gary-50 text-gray-500"
                        onClick={() => {
                          const isCopied = copy(zapCode);
                          if (isCopied) {
                            setCopied(true);
                          }
                        }}
                      >
                        {isCopied ? "Copied!" : "Copy"}
                      </span>
                    </p>
                    <p
                      className="cursor-pointer select-none text-gray-800 font-light hover:text-black"
                      title="Click To Copy"
                      onClick={() => {
                        const isCopied = copy(zapCode);
                        if (isCopied) {
                          setCopied(true);
                        }
                      }}
                    >
                      {zapCode}
                    </p>
                    <p className="flex justify-between mt-4">
                      <span className="underline">Callback URL :</span>
                      <span
                        className="px-3 py-[2px] shadow hover:bg-slate-50 cursor-pointer border bg-gary-50 text-gray-500"
                        onClick={() => {
                          const isCopied = copy(
                            `${API_URL}zapier/digitaloffers`
                          );
                          if (isCopied) {
                            setCopiedURL(true);
                          }
                        }}
                      >
                        {isCopiedURL ? "Copied!" : "Copy URL"}
                      </span>
                    </p>
                    <p
                      className="cursor-pointer select-none text-gray-800 font-light hover:text-black"
                      title="Click To Copy"
                      onClick={() => {
                        const isCopied = copy(`${API_URL}zapier/digitaloffers`);
                        if (isCopied) {
                          setCopiedURL(true);
                        }
                      }}
                    >
                      {`${API_URL}zapier/digitaloffers`}
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ZapCodeModal;
