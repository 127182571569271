import { addDecimalToNumber } from "module/util";
import ReactApexChart from "react-apexcharts";

const IncomeGraph = ({ getCampaignReportData = {} }) => {
  const graphDetails = getCampaignReportData?.incomeRedemptionGraph ?? {};

  const createArrayForSeries = () => {
    const offerCount = graphDetails?.["No Data"]?.length ?? 0;

    const offerData1 = [
      graphDetails?.["No Data"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["Less than $15,000"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$15,000 - $19,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$20,000 - $29,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$30,000 - $39,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$40,000 - $49,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$50,000 - $59,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$60,000 - $69,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$70,000 - $79,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$80,000 - $89,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$90,000 - $99,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$100,000 - $124,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["$125,000 - $149,999"]?.[0]?.redemption_amount ?? 0,
      graphDetails?.["Greater than $149,999"]?.[0]?.redemption_amount ?? 0,
    ];

    const offerData2 = [
      graphDetails?.["No Data"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["Less than $15,000"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$15,000 - $19,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$20,000 - $29,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$30,000 - $39,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$40,000 - $49,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$50,000 - $59,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$60,000 - $69,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$70,000 - $79,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$80,000 - $89,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$90,000 - $99,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$100,000 - $124,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["$125,000 - $149,999"]?.[1]?.redemption_amount ?? 0,
      graphDetails?.["Greater than $149,999"]?.[1]?.redemption_amount ?? 0,
    ];

    const seriesArray = [
      {
        name: graphDetails?.["No Data"]?.[0]?.offer_name ?? "",
        type: "bar",
        data: offerData1,
      },
      ...(offerCount > 1
        ? [
            {
              name: graphDetails?.["No Data"]?.[1]?.offer_name ?? "",
              type: "bar",
              data: offerData2,
            },
          ]
        : []),
    ];

    return seriesArray;
  };

  const config = {
    series: createArrayForSeries(),
    options: {
      chart: {
        height: 450,
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "top",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      title: {
        text: "Income",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return `$${addDecimalToNumber(val)}`;
        },
      },
      labels: [
        "No Data",
        "Less than $15,000",
        "$15,000 - $19,999",
        "$20,000 - $29,999",
        "$30,000 - $39,999",
        "$40,000 - $49,999",
        "$50,000 - $59,999",
        "$60,000 - $69,999",
        "$70,000 - $79,999",
        "$80,000 - $89,999",
        "$90,000 - $99,999",
        "$100,000 - $124,999",
        "$125,000 - $149,999",
        "$149,999+",
      ],
      xaxis: {
        type: "category",
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          minHeight: 120,
          trim: true,
          style: {
            fontSize: "11px",
          },
        },
      },
      yaxis: [],
      tooltip: {
        enabled: true,
        y: {
          formatter: (
            seriesName,
            { series, dataPointIndex, seriesIndex, w }
          ) => {
            const finalValue = series[seriesIndex][dataPointIndex];
            return `$${addDecimalToNumber(finalValue)} `;
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default IncomeGraph;
