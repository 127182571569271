import ShowPageTitle from "components/common/ShowPageTitle";
import LeadListTable from "components/leadList/LeadListTable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const LeadList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Lead List",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <>
      <ShowPageTitle title="leadList" />
      <LeadListTable />
    </>
  );
};

export default LeadList;
